import { Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import dayjs from "dayjs";

const DashboardFilter = ({ handleFilter }) => {
    const { control, handleSubmit, reset, getValues } = useForm({
        reValidateMode: "onBlur"
    });
    const { dashboardAction } = useParams();
    const { getDashboardStatus } = useUser();

    const errorHelper = {
        startDate: {
            required: "Start date is required",
            validateDate: "Start date is not a valid date",
            validateMinimumDate: "Start date must not be before 01/07/2018",
            validateMaximumDate: "Start date must not be in the future",
            validateExistsIfEndDate: "The start date must be entered where an end date is entered",
            validateBeforeEndDate: "The start date must be prior to the end date.",
        },
        endDate: {
            required: "End date is required",
            validateDate: "End date is not a valid date",
            validateMaximumDate: "End date must not be in the future",
            validateExistsIfStartDate: "The end date must be entered where start date is entered",
        },
    };

    const handleOnSubmit = ({ startDate, endDate, keyword }) => {
        const status = getDashboardStatus(dashboardAction);
        const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;

        handleFilter({ ...status, startDate: formattedStartDate, endDate: formattedEndDate, keyword });
    };

    const handleOnClear = () => {
        reset({
            startDate: null,
            endDate: null,
            keyword: undefined,
        });
        const status = getDashboardStatus(dashboardAction);
        handleFilter(status);
    };
    
    return (
        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate sx={{ mt: 1 }}>
            <Controller
                control={control}
                name="startDate"
                defaultValue=""
                rules={{
                    validate: {
                        validateDate: (value) => !value || dayjs(value).isValid(),
                        validateMinimumDate: (value) => !dayjs(value).isBefore(dayjs('2018-07-01')),
                        validateMaximumDate: (value) => !dayjs(value).isAfter(dayjs()),
                        validateExistsIfEndDate: (value) => value || !getValues('endDate'),
                        validateBeforeEndDate: (value) => !value || !getValues('endDate') || dayjs(value).isBefore(getValues('endDate'))
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        {...field}
                        label="Start date"
                        name="startDate"
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                sx={{ mr: 2 }}
                                error={error !== undefined} 
                                helperText={error ? errorHelper.startDate[error.type] : ""}
                            />
                        }
                    />
                )}
            />
            <Controller
                control={control}
                name="endDate"
                defaultValue=""
                rules={{
                    validate: {
                        validateDate: (value) => !value || dayjs(value).isValid(),
                        validateMaximumDate: (value) => !dayjs(value).isAfter(dayjs()),
                        validateExistsIfStartDate: (value) => value || !getValues('startDate'),
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        {...field}
                        label="End date"
                        name="endDate"
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                sx={{ mr: 4 }}
                                error={error !== undefined} 
                                helperText={error ? errorHelper.endDate[error.type] : ""}
                            />
                        }
                    />
                )}
            />
            <Controller
                control={control}
                name="keyword"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Enter the surname or AGS number of the injured worker"
                        id="keyword"
                        sx={{ width: '75ch' }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.keyword[error.type] : ""}
                    />
                )}
            />
            <br/>
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 1 }}
                >
                Search
            </Button>
            <Button
                type="reset"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleOnClear}
            >
                Clear Search
            </Button>
        </Box>
    );
};

export default DashboardFilter;