import React, { useEffect, useState } from "react";
import { Box, Button, Container,FormControl, FormControlLabel, FormHelperText, Grid, Link, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import * as priceHistoryApi from "../api/priceHistory";
import useAuth from "../hooks/useAuth";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import Loading from "../components/Loading/Loading";

const ProviderList = ({ categories, handleProviderSelect }) => {

    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h2" sx={{ mt: 2,mb:2 }}>
                Select a provider to check pricing
            </Typography>
            {categories &&
                <Grid container >
                    {categories.map((category) =>
                        <Grid item xs={12} sm={5} key={category.name}>
                            <Typography variant="h3">{category.name}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                }}
                            >
                                {category.providers.map((supplier) =>
                                    <Box
                                        key={supplier.code}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'left',
                                        }}
                                    >
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                handleProviderSelect(supplier.code);
                                            }}
                                        >
                                            {supplier.name}
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            }
        </Box>
        </Container>
    );
};

const PriceHistoryForm = ({ supplierCode, panel, handleBack }) => {
    const { loading, call } = useAuth();
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });
    const [ supplier, setSupplier ] = useState();
    const [ prices, setPrices ] = useState();

    useEffect(() => {
        call(priceHistoryApi.supplier, { supplierCode, panel }, setSupplier);
    },
    // eslint-disable-next-line
    [supplierCode]);

    const errorHelper = {
        service: {
            required: "You must select a service type.",
        },
        date: {
            required: "Date is required",
            validateDate: "Date is not a valid date",
            validateMaximumDate: "Date must not be in the future"
        },
    };

    function handleOnSubmit({ service, date }) {
        const splitCodes = service.split('-');
        const serviceId = splitCodes[0];
        const categoryId = splitCodes[1];

        call(priceHistoryApi.prices, { supplierCode, serviceId, categoryId, date: dayjs(date).format('YYYY-MM-DD'), panel }, setPrices );
        window.scrollTo(0, 0);
    };

    if (!supplier) {
        return (
            <Loading />
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {supplier &&
                <React.Fragment>
                    <Container maxWidth="xl">
                    <Typography variant="h2" sx={{ mt: 2 }}>
                        Check pricing for {supplier.name}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'left',
                        }}
                    >
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                handleBack();
                            }}
                        >
                            Back to select a provider
                        </Link>
                    </Box>
                    </Container>
                    <Grid container sx={{ gap: 1 }}>
                        <Grid item xs={12} sm={3}>
                            <Box
                                component="form"
                                onSubmit={handleSubmit(handleOnSubmit)}
                                noValidate
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                }}
                            >
                                <Typography variant="h3" sx={{ mt: 2 }}>
                                    1. Select the service
                                </Typography>

                                <Controller
                                    render={({ field, fieldState: { error } }) => (
                                        <FormControl sx={{ m: 3 }} error={error !== undefined} variant="standard">
                                            <RadioGroup
                                                {...field}
                                            >
                                                {supplier.services.map((service) =>
                                                    <React.Fragment key={service.id}>
                                                        {service.subTypes.map((subCategory) =>
                                                            <FormControlLabel
                                                                value={`${service.id + '-' + subCategory.id}`}
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                    />
                                                                }
                                                                label={`${service.name} ${subCategory.name ? '- ' + subCategory.name : ''}`}
                                                                key={`${service.id + '-' + subCategory.id}`}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </RadioGroup>
                                            {error !== undefined &&
                                                <FormHelperText>{error ? errorHelper.service[error.type] : ""}</FormHelperText>
                                            }   
                                        </FormControl>
                                    )}
                                    name="service"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                    }}
                                />

                                <Typography variant="h3" sx={{ mt: 2 }}>
                                    2. Enter the date of service
                                </Typography>

                                <Controller
                                    control={control}
                                    name="date"
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        validate: {
                                            validateDate: (value) => !value || dayjs(value).isValid(),
                                            validateMaximumDate: (value) => !dayjs(value).isAfter(dayjs()),
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <DatePicker
                                            {...field}
                                            label='date'
                                            name="date"
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    required
                                                    error={error !== undefined} 
                                                    helperText={error ? errorHelper.date[error.type] : ""}
                                                />
                                            }
                                        />
                                    )}
                                />

                                <Button type="submit" variant="contained" sx={{ mt: 2, }}>Filter date</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {loading && <Loading />}
                            {!loading && prices &&
                                <TableContainer component={Paper} sx={{ mt: 2 }}>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Region</TableCell>
                                            <TableCell>Price inc GST</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Start date</TableCell>
                                            <TableCell>End date</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {prices.map((price) => (
                                            <TableRow
                                            key={price.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Typography component="p" variant="body2">{price.regionState + ' ' + price.regionName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="p" variant="body2">{'$' + price.price}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="p" variant="body2">{price.feeType}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="p" variant="body2">{dayjs(price.dateFrom).format('DD/MM/YYYY')}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="p" variant="body2">{dayjs(price.dateTo).format('DD/MM/YYYY')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </Box>
        
    );
};

const PriceHistoryPage = ({ panel }) => {
    const { loading, call } = useAuth();
    const [ supplierCategories, setSupplierCategories ] = useState([]);
    const [ step, setStep ] = useState(1);
    const [ selectedSupplier, setSelectedSupplier ] = useState();

    useEffect(() => {
        setStep(1);
        call(priceHistoryApi.suppliers, {panel}, setSupplierCategories);
    },
    // eslint-disable-next-line
    [panel]);

    function handleProviderSelect(supplierCode) {
        setSelectedSupplier(supplierCode);
        setStep(2);
        window.scrollTo(0, 0);
    };

    function handleBack() {
        setSelectedSupplier(undefined);
        setStep(1);
    };
    
    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    margin: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                }}
            >
                <Typography variant="h1">
                    Invoice price check
                </Typography>
                <Typography variant="body" sx={{ mb: 2 }}>{`Step ${step} of 2`}</Typography>
                {step === 1 &&
                    (loading 
                        ? <Loading />
                        : <ProviderList categories={supplierCategories} handleProviderSelect={handleProviderSelect} />)
                }
                {step === 2 && <PriceHistoryForm supplierCode={selectedSupplier} panel={panel} handleBack={handleBack} />}
            </Box>
        </Container>
    );
}
export default PriceHistoryPage;
