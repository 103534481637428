import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { VALID_EMAIL_ADDRESS_PATTERN } from "../constants/constants";
import useAuth from "../hooks/useAuth";

export default function RequestResetPasswordPage() {
    const { error, success, setSuccess, requestResetPassword } = useAuth();
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const errorHelper = {
        emailAddress: {
            required: "Email is required",
            pattern: "Invalid email address"
        },
    }

    function handleOnSubmit({ emailAddress }) {
        setSuccess(undefined);
        requestResetPassword(emailAddress, (response) => {
            setSuccess("Reset requested");
        });
    }

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {success &&
                <Alert severity="success">
                    If the email address you&#39;ve entered belongs to an ORAMS account, we&#39;ll send a link to reset
                    the password. Check your spam folder if it does not arrive.
                </Alert>
            }
            {error && <Alert severity="error">There was a problem sending your reset email</Alert>}
            <Typography variant="h2">Reset password</Typography>
            <Typography variant="body">
                Enter your email address and we&#39;ll send you a link to reset your password. Password reset links are
                valid for 24 hours.
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(handleOnSubmit)}
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    width: '75ch',
                    gap: 1,
                    mt: 2,
                }}
            >
                <Controller
                    control={control}
                    name="emailAddress"
                    defaultValue=""
                    rules={{
                        required: true,
                        pattern: VALID_EMAIL_ADDRESS_PATTERN,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            required
                            label="Enter your email address registered to the ORAMS Portal"
                            id="emailAddress"
                            fullWidth 
                            error={error !== undefined}
                            helperText={error ? errorHelper.emailAddress[error.type] : ""}
                        />
                    )}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                        gap: 1,
                        mt: 2,
                    }}
                >
                    <Button type="submit" variant="contained">Send reset password</Button>
                </Box>
            </Box>
        </Box>
    );
}