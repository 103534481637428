import { Alert, Box, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { examineeServiceTypeIds, REFERRAL_STATUS_DISPLAYS } from "../../constants/referralConstants";
import useUser from "../../hooks/useUser";
import { Link as RouterLink } from "react-router-dom";
import DeterminationDetails from "./DeterminationDetails";
import InjuredEmployeeDetails from "./InjuredEmployeeDetails";
import WorkplaceDetails from "./WorkplaceDetails";
import ComcareClaimDetails from "./ComcareClaimDetails";
import RehabAssessmentDetails from "./RehabAssessmentDetails";
import ManagerDetails from "./ManagerDetails";
import TreatingDoctorDetails from "./TreatingDoctorDetails";

const CompensationDetails = ({ referral }) => {
    const { userTypeNavLinks } = useUser();
    const dashboardLink = userTypeNavLinks.filter((link) => link.key === 'dashboard')[0];
    const dashboardUrl = dashboardLink ? dashboardLink.url : undefined;
    const { serviceTypeId } = referral;

    return (
        <Box
            sx={{
                margin: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h2">Rehabilitation assessment/examination</Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>Under section 36 of the <i>Safety, Rehabilitation and Compensation Act 1988</i></Typography>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Referral ID</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{referral.id}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Date created</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{dayjs(referral.created).format('DD MMM YYYY')}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Status</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{REFERRAL_STATUS_DISPLAYS[referral.status]}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography><RouterLink to={dashboardUrl}>Back to dashboard</RouterLink></Typography>
                </Grid>
            </Grid>
            <Divider />
            <DeterminationDetails referral={referral} />
            <Divider />
            <InjuredEmployeeDetails referral={referral} />
            <Divider />
            <WorkplaceDetails referral={referral} />
            <Divider />
            {(!serviceTypeId || !examineeServiceTypeIds.includes(serviceTypeId)) &&
                <React.Fragment>
                    <ManagerDetails referral={referral}/>
                    <Divider />
                    <TreatingDoctorDetails referral={referral}/>
                    <Divider />
                </React.Fragment>
            }
            <ComcareClaimDetails referral={referral} />
            <Divider />
            <RehabAssessmentDetails referral={referral} />
            <Divider />
            <Alert severity="info" sx={{ mt: 2 }}>
                <Typography variant="h3">
                    Notes for Rehabilitation Case Managers (RCM)
                </Typography>
                <Typography component="div" variant="body2">
                    <ul>
                        <li>
                            Please ensure a copy of this form is distributed to Injured Employee; Comcare and Employee's manager.
                        </li>
                        <li>
                            Please forward the Medical Release Authority form and Existing Medical Information to the engaged Provider.
                        </li>
                    </ul>
                </Typography>
            </Alert>
        </Box>
    );
}

export default CompensationDetails;
