import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Checkbox, FormControlLabel, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import * as editPriceApi from "../api/editPrice";
import useAuth from "../hooks/useAuth";
import { Controller, useForm } from "react-hook-form";
import ServiceEditList from "../components/Pricing/ServiceEditList";
import PricingList from "../components/Pricing/PricingList";
import { Link as RouterLink } from "react-router-dom";
import { parseCurrency, validateCurrency, VALID_CURRENCY_PATTERN, VALID_PERCENTAGE_PATTERN, validatePercentage, parsePercentage } from "../constants/constants";
import Loading from "../components/Loading/Loading";

const EditCeilingPriceForm = ({ price, handleOnSubmit }) => {
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const errorHelper = {
        ceilingPrice: {
            required: "Ceiling price is required",
            pattern: "Ceiling price is invalid",
            validateValidPrice: "Ceiling price is still invalid",
            validateMinPrice: `Ceiling price must be valid and more than $${price.price}`,
        },
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 4,
            }}
        >

            <Controller
                control={control}
                name="ceilingPrice"
                defaultValue={price.capPrice}
                rules={{
                    required: true,
                    pattern: VALID_CURRENCY_PATTERN,
                    validate: {
                        validateValidPrice: validateCurrency,
                        validateMinPrice: (value) => !value || parseCurrency(value) >= price.price,
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Enter a new ceiling price including GST"
                        id="ceilingPrice"
                        error={error !== undefined}
                        helperText={error ? errorHelper.ceilingPrice[error.type] : `The new ceiling price must not be less than $${price.price}`}
                    />
                )}
            />

            <Typography variant="body2" sx={{ mt: 3 }}>Check box if you want to set current price to match</Typography>
            <Controller
                control={control}
                name="setCurrentPriceToCeiling"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Set current price to match"
                    />
                )}
            />


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    mt: 2,
                }}
            >
                <Button type="submit" variant="contained">Save and return to region list</Button>
            </Box>
        </Box>
    );
};

const EditCeilingPriceByCpiForm = ({ handleOnSubmit }) => {
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const errorHelper = {
        cpiPercentage: {
            required: "Indexation percentage is required",
            pattern: "Indexation percentage is invalid, must be between 0 and 100, up to 2 decimal places % is optional",
            validateValid: "Indexation percentage is invalid, must be between 0 and 100, up to 2 decimal places % is optional",
        },
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 4,
            }}
        >

            <Controller
                control={control}
                name="cpiPercentage"
                defaultValue={""}
                rules={{
                    required: true,
                    pattern: VALID_PERCENTAGE_PATTERN,
                    validate: {
                        validateValid: validatePercentage,
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Enter an indexation percentage"
                        id="cpiPercentage"
                        error={error !== undefined}
                        helperText={error ? errorHelper.cpiPercentage[error.type] : ""}
                    />
                )}
            />

            <Typography variant="body2" sx={{ mt: 3 }}>Check box if you want to set current price to match</Typography>
            <Controller
                control={control}
                name="setCurrentPriceToCeiling"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Set current price to match"
                    />
                )}
            />


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    mt: 2,
                }}
            >
                <Button type="submit" variant="contained">Next</Button>
            </Box>
        </Box>
    );
};

const ConfirmCeilingPriceByCpiForm = ({ cpiUpdate, priceChanges, handleOnSubmit, handleOnCancel }) => {
    const { handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 4,
            }}
        >
            <Typography variant="body" sx={{ mb: 2 }}>{`You are updating the ceiling price for all services by ${cpiUpdate.data.cpiPercentage}%`}</Typography>

            {priceChanges &&
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Region</TableCell>
                            <TableCell>Service</TableCell>
                            <TableCell>Price cap (before)</TableCell>
                            <TableCell>Price cap (after)</TableCell>
                            <TableCell />
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {priceChanges.map((priceChange) => (
                            <TableRow
                            key={priceChange.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography component="p" variant="body2">{priceChange.regionName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{priceChange.serviceName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{'$' + priceChange.capPrice}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{'$' + priceChange.newCapPrice}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    gap: 1,
                    mt: 2,
                }}
            >
                <Button type="submit" variant="contained">Save and return to services</Button>
                <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                        handleOnCancel();
                    }}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    );
};

export default function EditCeilingPricePage() {
    const { loading, call, contextMap, error } = useAuth();
    const [ prices, setPrices ] = useState([]);
    const [ step, setStep ] = useState(1);
    const [ supplier, setSupplier ] = useState();
    const [ selectedService, setSelectedService ] = useState();
    const [ selectedPrice, setSelectedPrice ] = useState();
    const [ success, setSuccess ] = useState();
    const [ cpiUpdate, setCpiUpdate ] = useState();
    const [ priceChanges, setPriceChanges ] = useState([]);

    const STEP_NAMES = [
        "select a service",
        "select a region",
        "edit ceiling price",
        "edit by CPI",
        "confim CPI update",
    ]

    useEffect(() => {
        if (contextMap && contextMap.get('supplierCode')) {
            call(editPriceApi.supplier, { supplierCode: contextMap.get('supplierCode') }, (response) => {
                setSupplier(response);
            });
        }
    },
    // eslint-disable-next-line
    [contextMap]);

    function handleServiceSelect(serviceId, subCategoryId, serviceName) {
        setSelectedService({ serviceId, subCategoryId, serviceName });
        call(editPriceApi.prices, { supplierCode: contextMap.get('supplierCode'), serviceId, subCategoryId }, (response) => {
            setPrices(response);
        });
        setStep(2);
        window.scrollTo(0, 0);
    };

    function handlePriceSelect(price) {
        setSelectedPrice(price);
        setStep(3);
        window.scrollTo(0, 0);
    };

    function handlePriceSubmit({ ceilingPrice, setCurrentPriceToCeiling }) {
        setSuccess(undefined);
        call(editPriceApi.update, { id: selectedPrice.capPriceId, data: { price: parseCurrency(ceilingPrice), setCurrentPriceToCeiling }}, (response) => {
            setSuccess('Ceiling price updated');
            call(editPriceApi.prices, { supplierCode: contextMap.get('supplierCode'), serviceId: selectedService.serviceId, subCategoryId: selectedService.subCategoryId }, (response) => {
                setPrices(response);
            });
            setStep(2);
            window.scrollTo(0, 0);
        })
    };

    function handleUpdateByCpiSelect() {
        setStep(4);
        window.scrollTo(0, 0);
    };

    function handleCpiConfim({ cpiPercentage, setCurrentPriceToCeiling }) {
        setSuccess(undefined);
        const newCpiUpdate = { supplierCode: contextMap.get('supplierCode'), data: { cpiPercentage: parsePercentage(cpiPercentage), setCurrentPriceToCeiling } };
        setCpiUpdate(newCpiUpdate);
        call(editPriceApi.confimByCpi, newCpiUpdate, (response) => {
            setPriceChanges(response);
            setStep(5);
            window.scrollTo(0, 0);
        })
    };

    function handleCpiCancel() {
        setStep(1);
        window.scrollTo(0, 0);
    };

    function handleCpiSubmit() {
        setSuccess(undefined);
        call(editPriceApi.updateByCpi, cpiUpdate, (response) => {
            setSuccess('Ceiling prices updated');
            setCpiUpdate(undefined);
            setPriceChanges([]);
            setStep(1);
            window.scrollTo(0, 0);
        })
    };

    if (loading) {
        return (
            <Loading />
        );
    }
    
    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {success && <Alert severity="success">{success}</Alert>}
            {error && <Alert severity="error">{error}</Alert>}
            {supplier &&
                <React.Fragment>
                    {step === 3 ? <Typography variant="h1">{`${supplier.name} - ${selectedPrice.regionState} ${selectedPrice.regionName} - ${selectedService.serviceName}`}</Typography>
                        : <Typography variant="h1">{supplier.name}</Typography>
                    }
                    {step === 4 || step === 5 ? <Typography variant="h2">{`Apply CPI indexation to ceiling prices`}</Typography>
                        : <Typography variant="h2">{`Update ceiling prices - ${STEP_NAMES[step - 1]}`}</Typography>
                    }
                    {step === 4 ? <Typography variant="body" sx={{ mb: 2 }}>{`Step 2 of 3`}</Typography>
                        : step === 5 ? <Typography variant="body" sx={{ mb: 2 }}>{`Step 3 of 3`}</Typography>
                            : <Typography variant="body" sx={{ mb: 2 }}>{`Step ${step} of 3`}</Typography>
                    }
                    {step === 1 &&
                        <React.Fragment>
                            <RouterLink
                                to="/admin"
                                variant="body2"
                            >
                                Back to providers
                            </RouterLink>
                            <ServiceEditList services={supplier.services} handleServiceSelect={handleServiceSelect} handleUpdateByCpiSelect={handleUpdateByCpiSelect} />
                        </React.Fragment>
                    }
                    {step === 2 &&
                        <React.Fragment>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setStep(1);
                                    }}
                                >
                                    Back to services
                                </Link>
                            </Box>
                            <PricingList prices={prices} handlePriceSelect={handlePriceSelect} />
                        </React.Fragment>
                     }
                    {step === 3 &&
                        <React.Fragment>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setStep(2);
                                    }}
                                >
                                    Back to pricing information
                                </Link>
                            </Box>
                            <EditCeilingPriceForm price={selectedPrice} handleOnSubmit={handlePriceSubmit} />
                        </React.Fragment>
                    }
                    {step === 4 &&
                        <React.Fragment>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setStep(1);
                                    }}
                                >
                                    Back to services
                                </Link>
                            </Box>
                            <EditCeilingPriceByCpiForm handleOnSubmit={handleCpiConfim} />
                        </React.Fragment>
                    }
                    {step === 5 &&
                        <React.Fragment>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setStep(4);
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    Back
                                </Link>
                            </Box>
                            <ConfirmCeilingPriceByCpiForm cpiUpdate={cpiUpdate} priceChanges={priceChanges} handleOnSubmit={handleCpiSubmit} handleOnCancel={handleCpiCancel} />
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </Box>
    );
}
