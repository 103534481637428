import { Box, Link, Typography,Container } from "@mui/material";
import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { HashLink } from 'react-router-hash-link';

export default function TermsOfUsePage() {
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
                ORAMS Terms of Use
            </Typography>
            <Typography component="h2" variant="h2" sx={{ mb: 0 }}>
                On this page
            </Typography>
            <List sx = {{listStyleType: 'disc', pl: 4, '& .MuiListItem-root': {display: 'list-item',},}}>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#security">Security</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#registration">Registration</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#termination">Termination</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#notifications">Notifications</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#code-of-conduct">Code of Conduct</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#licence-usage">Licence and usage</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#disclaimer">Disclaimer</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#indemnity">Indemnity</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#external-links">External Links</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#linking-syndication">Linking and syndication</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#jurisdiction">Jurisdiction</HashLink></ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem><HashLink to="#definitions">Definitions</HashLink></ListItem>
                </Typography>
            </List>
            <Typography component="h2" variant="h2" id="introduction" sx={{ mb: 0 , mt: 2}}>
                Introduction
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                These Terms of Use govern all access to and use of the <Link href="http://orams.comcare.gov.au" target="_blank" rel="noreferrer">Occupational Rehabilitation and
                Associated Medical Services (ORAMS) Portal</Link> and constitute a binding legal agreement between
                you, your entity and Comcare. By accessing the ORAMS Portal, you agree to be bound by these
                Terms of Use and comply with the included Code of Conduct, whether or not you become a
                registered buyer or seller. If you do not agree to be bound by all these Terms of Use, do not
                access or use the ORAMS Portal.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Comcare may change these Terms of Use from time to time and will advise registered buyers
                and sellers of any changes by email or other means.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                If you have any questions or feedback about these Terms of Use, please email us at
                {' '}<Link href="mailto:orams@comcare.gov.au">orams@comcare.gov.au</Link>.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                The meaning of words can be found in the <HashLink to="#definitions">definitions section</HashLink>.
            </Typography>
            <Typography component="h2"  variant="h2" id="security" sx={{ mb: 0 , mt: 2 }}>
                Security
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                We apply a range of security controls to protect the ORAMS Portal from unauthorised access,
                as described in our <Link href="https://orams.comcare.gov.au/security" target="_blank" rel="noreferrer">
                security statement 
                </Link>. However, you agree that:
            </Typography>
            <List sx = {{listStyleType: 'disc', pl: 4, '& .MuiListItem-root': {display: 'list-item',},}}>
                <Typography component="p" variant="body2">
                    <ListItem>The World Wide Web is an unsecure public network.</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>There is a risk that your transactions may be seen, intercepted or modified by third parties.</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>Downloadable files may contain computer viruses, spyware, malware, disabling codes, worms or
                        other devices or defects.</ListItem>
                </Typography>
            </List>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                We accept no liability for any interference with, or damage to, your computer system, software
                or data or any loss occurring in connection with, or relating to, this website or its use. You
                should take appropriate and adequate precautions to prevent damage to your computer
                system, software or data. For more information we recommend reading <Link href="http://www.ag.gov.au/RightsAndProtections/CyberSecurity/Documents/PDF%20-%20Protecting%20Yourself%20Online%20-%20Second%20Edition%20-%20Booklet.pdf" target="_blank" rel="noreferrer">
                Protecting Yourself
                Online (PDF 2.7MB) 
                </Link>.
            </Typography>
            <Typography component="h2" variant="h2" id="registration" sx={{ mb: 0 , mt: 2 }}>
                Registration
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="h3" variant="h3" sx={{ mb: 2 }}>
                Participating Agencies
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                At the current time, only government entities, or other entities expressly authorised by
                Comcare, can be a user in the ORAMS Portal. To create an account you must be a government
                employee, or a contractor engaged to act on behalf of a local, state, territory or
                Commonwealth government entity, and require access to the ORAMS Portal to perform your
                role.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                By accessing the ORAMS Portal you are representing to Comcare that you are authorised to act
                on the behalf of your entity.
            </Typography>
            <Typography component="h3" variant="h3" sx={{ mb: 2 }}>
                Panel Providers
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                All panel providers must be part of{' '}
                <Link rel="noreferrer" target="_blank" href={process.env.PUBLIC_URL + '/media/documents/Head Agreement for the provision of ORAMS.pdf'}>
                    Occupational Rehabilitation and Associated Medical Services panel
                </Link>. In order to join this panel, please contact panel management at{' '}
                <a href="mailto:orams@comcare.gov.au">orams@comcare.gov.au</a>.
            </Typography>
            <Typography component="h2" variant="h2" id="termination" sx={{ mb: 0 , mt: 2 }}>
                Termination
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Comcare may terminate your access to the ORAMS Portal at any time for any reason. Comcare
                will not be liable for any loss you have.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Upon termination, all licenses and other rights granted to you in these Terms of Use will
                immediately cease.
            </Typography>
            <Typography component="h2" variant="h2" id="notifications" sx={{ mb: 0 , mt: 2 }}>
                Notifications
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                If you register as a Panel Provider or Participating Agency on the ORAMS Portal you may
                receive contact from Comcare by email, or other means.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Email notifications are the primary form of contact between Comcare and registered users. The
                email address you have provided when registering will be used for this correspondence.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Comcare does not accept any responsibility or liability for any loss to you caused by the delay
                or failure of delivery of emails from the ORAMS Portal for any reason.
            </Typography>
            <Typography component="h2" variant="h2" id="code-of-conduct" sx={{ mb: 0 , mt: 2 }}>
                Code of Conduct
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                ORAMS Portal users must act ethically, reasonably, with integrity, and in accordance with
                applicable laws at all times they use the ORAMS Portal. Any violation of this code of conduct
                may result in the suspension or deletion of your account at the absolute discretion of Comcare.
                Comcare will not be liable for any loss you have.
            </Typography>
            <Typography component="h3" variant="h3" sx={{ mb: 2 }}>
                Participating Agencies
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                As a user, after signing in, you will have access to a variety of confidential information relating
                to staff and panel providers.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                You agree to comply with the  <Link href="http://www.apsc.gov.au/publications-and-media/current-publications/aps-values-and-code-of-conduct-in-practice" target="_blank" rel="noreferrer">
                APS Values and Code of Conduct</Link> (or the relevant code of 
                conduct for your local, state or territory workplace) in all your activities relating to the ORAMS
                Portal.
            </Typography>
            <Typography component="h3" variant="h3" sx={{ mb: 2 }}>
                Panel Providers
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Comcare reserves the right to verify any information you have provided, at any time.
            </Typography>
            <Typography component="h2" variant="h2" id="licence-usage" sx={{ mb: 0 , mt: 2 }}>
                Licence and usage
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                You must make sure any information you provide is true, to the best of your knowledge and
                belief. If you provide third-party information, you must make sure it does not breach the third-
                party's intellectual property rights.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Any personal information you provide when using the ORAMS Portal will be handled in
                accordance with our <Link href="https://orams.comcare.gov.au/privacy-policy" target="_blank" rel="noreferrer">
                Privacy Statement</Link>.
            </Typography>
            <Typography component="h2" variant="h2" id="disclaimer" sx={{ mb: 0 , mt: 2 }}>
                Disclaimer
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                To the maximum extent permitted by law, Comcare disclaims all and any guarantees,
                undertakings and warranties, express or implied, and will not be liable for any loss or damage
                whatsoever arising out of or in connection with, any use of or reliance on the material on the
                ORAMS Portal.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Inquiries regarding the licence or any use of the ORAMS Portal materials should be directed to{' '}
                <a href="mailto:orams@comcare.gov.au">orams@comcare.gov.au</a>.
            </Typography>
            <Typography component="h2" variant="h2" id="indemnity" sx={{ mb: 0 , mt: 2 }}>
                Indemnity
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                You must at all times indemnify and hold harmless Comcare and our personnel and keep them
                indemnified from and against all loss (including reasonable legal costs and expenses) or
                liability incurred by those indemnified arising from any claim, demand, suit, action or
                proceeding by any person against any of those indemnified where that loss or liability arose
                out of, in relation to:
            </Typography>
            <List sx = {{listStyleType: 'disc', pl: 4, '& .MuiListItem-root': {display: 'list-item',},}}>
                <Typography component="p" variant="body2">
                    <ListItem>Any breach of these Terms of Use by you.</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>Publication of or distribution of the material or information supplied by you.</ListItem>
                </Typography>
            </List>
            <Typography component="h2" variant="h2" id="external-links" sx={{ mb: 0 , mt: 2 }}>
                External links
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                A link to another website from the ORAMS Portal does not mean that Comcare is endorsing or
                recommending any information, products or services offered on or through the other website,
                or that the material on any linked website does not infringe the intellectual property rights of
                any person. Comcare does not authorise the reproduction of material on linked websites.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                You must make your own enquiries and decisions about the accuracy and reliability of any
                information, and the suitability of products or services, on the linked website.
            </Typography>
            <Typography component="h2" variant="h2" id="linking-syndication" sx={{ mb: 0 , mt: 2 }}>
                Linking and syndication
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="h2" variant="h2" id="jurisdiction" sx={{ mb: 0 , mt: 2 }}>
                Jurisdiction
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                These Terms of Use are governed by the laws of the Australian Capital Territory (ACT), Australia.
                Any dispute must be heard in the courts of the ACT.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                If the ACT court finds any of these Terms of Use invalid, unenforceable or illegal for any reason,
                the remaining Terms of Use will continue to apply in full.
            </Typography>
            <Typography component="h2" variant="h2" id="definitions" sx={{ mb: 0 , mt: 2 }}>
                Definitions
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                confidential information means information that is by its nature regarded in law as confidential,
                and which:
            </Typography>
            <List sx = {{listStyleType: 'disc', pl: 4, '& .MuiListItem-root': {display: 'list-item',},}}>
                <Typography component="p" variant="body2">
                    <ListItem>Is designated by a party as confidential</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>Is described in the work order as confidential</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>Is agreed in writing by the parties as confidential</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>A party knows or ought to know is confidential</ListItem>
                </Typography>
            </List>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                It does not include information that is, or becomes, public knowledge other than by breach of
                this agreement or a work order or any other confidentiality obligation.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                Commonwealth means the Commonwealth of Australia.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                consequential loss means any loss recoverable at law (other than arising in the usual course of
                things) including:
            </Typography>
            <List sx = {{listStyleType: 'disc', pl: 1, 'MuiListItem-root': {display: 'list-item',},}}>
                <Typography component="p" variant="body2">
                    <ListItem>1. a loss of income or revenue</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>2. a loss of opportunity or goodwill</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>3. a loss of profits</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>4. a loss of anticipated savings or business</ListItem>
                </Typography>
                <Typography component="p" variant="body2">
                    <ListItem>5. a loss of value of any equipment</ListItem>
                </Typography>
            </List>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                content includes information, data, documents, pages and images.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                ORAMS is the online Occupational Rehabilitation and Associated Medical Services platform
                located at  <Link href="https://orams.comcare.gov.au/" target="_blank" rel="noreferrer">
                    https://orams.comcare.gov.au
                </Link> managed by Comcare.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                Comcare means the body corporate called Comcare established by <i>Safety, Rehabilitation and 
                Compensation Act 1988</i> (Cth).
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                entity means a person, partnership, organisation, or business that has a legal and separately 
                identifiable existence.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                government employee includes a person engaged under the <i>Public Service Act 1999</i> (or
                equivalent state or territory legislation).
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                loss means loss, damage, cost or expense (to any person or property) including consequential
                loss or indirect loss or any loss of profits, data or revenue.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                our, us and we means Comcare.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                personnel means, in relation to a party, any natural persons who are employees, officers,
                agents, contractors, subcontractors or professional advisers of that party.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                Panel Provider means a business who offers their products or services on the ORAMS panel.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                Rehabilitation Case Manager means a participating agency employee registered as a
                Rehabilitation Case Manager on the ORAMS Portal.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                Participating Agency means a Commonwealth agency employee who participates on behalf of
                the participating Commonwealth Agency in the ORAMS panel arrangements and Portal.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                user means any ORAMS Portal visitor or person (including an individual or a body corporate)
                that represents a Participating Agency or Panel Provider.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                you and your means the user and the entity which the user represents on ORAMS.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mt: 2 }}>
                Last updated: 14/01/2021
            </Typography>
        </Box>
        </Container>
    );
}