import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

const PricingList = ({ prices, handlePriceSelect }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 2,
            }}
        >
            {prices &&
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Region</TableCell>
                            <TableCell>Price cap</TableCell>
                            <TableCell>Price inc GST</TableCell>
                            <TableCell>Start date</TableCell>
                            <TableCell>End date</TableCell>
                            <TableCell />
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {prices.map((price) => (
                            <TableRow
                            key={price.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography component="p" variant="body2">{price.regionState + ' ' + price.regionName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{'$' + price.capPrice}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{'$' + price.price}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{dayjs(price.dateFrom).format('DD/MM/YYYY')}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{dayjs(price.dateTo).format('DD/MM/YYYY')}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            handlePriceSelect(price);
                                        }}
                                    >
                                        Edit
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    );
}

export default PricingList;