import { Box, Typography,Container, ListItem, List, Link } from "@mui/material";
import useDocument from "../hooks/useDocument";
import React from "react";

export default function DocumentsPage() {
  const { userDocuments, getDocumentUrl } = useDocument();

  return (
      <Container maxWidth="xl">
        <Box
            sx={{
                margin: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
          {userDocuments && userDocuments.map((userDocumentGroup) => {
            return (
              <React.Fragment>
                <Typography component="p" variant="h6">{userDocumentGroup.groupName}</Typography>
                  {userDocumentGroup.subGroups && userDocumentGroup.subGroups.map((userDocumentSubGroup) => {
                    return (
                      <React.Fragment>
                        <Typography component="p" variant="h7">{userDocumentSubGroup.subGroupName}</Typography>
                        <List>
                        {userDocumentSubGroup.documents && userDocumentSubGroup.documents.map((userDocument) => {
                          return (
                            <ListItem>
                              <Typography component="p" variant="body">
                                <Link rel="noreferrer" target="_blank" href={getDocumentUrl(userDocument.name)}>
                                  {userDocument.text}
                                </Link>
                              </Typography>
                            </ListItem>
                          );
                        })}
                        </List>
                      </React.Fragment>
                    );
                  })}
                  {userDocumentGroup.documents &&
                    <List>
                    {userDocumentGroup.documents && userDocumentGroup.documents.map((userDocument) => {
                      return (
                        <ListItem>
                          <Typography component="p" variant="body">
                            <Link rel="noreferrer" target="_blank" href={getDocumentUrl(userDocument.name)}>
                              {userDocument.text}
                            </Link>
                          </Typography>
                        </ListItem>
                      );
                    })}
                    </List>
                  }
            </React.Fragment>
            );
          })}
        </Box>
      </Container>
  );
}