import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as supplierApi from "../api/supplier";
import { Box, Button, Container, Divider, Grid, Link, Typography } from "@mui/material";
import useAuth from "../hooks/useAuth";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Loading from "../components/Loading/Loading";

function SellerProfilePage() {
    const { call } = useAuth();
    const { id } = useParams();
    const [ supplierDetails, setSupplierDetails ] = useState();

    useEffect(() => {
        call(supplierApi.get, { id }, (response) => {
            if (response) {
                setSupplierDetails({
                    supplierAbn: response.abn,
                    supplierBusinessName: response.name,
                    supplierPrimaryAddress: response.address ? response.address.addressLine : undefined,
                    supplierPrimaryAddressSuburb: response.address ? response.address.suburb : undefined,
                    supplierPrimaryAddressState: response.address ? response.address.state : undefined,
                    supplierPrimaryAddressPostcode: response.address ? response.address.postCode : undefined,
                    supplierUrl: response.website,
                    supplierCategory : response.categoryName,
                    supplierRegions: response.regions,
                    supplierContactName: response.contactName,
                    supplierContactEmail: response.contactEmail,
                    supplierContactPhone:response.contactPhone,
                    supplierRepresentativeName: response.representative,
                    supplierRepresentativeEmail: response.representativeEmail,
                    supplierRepresentativePhone: response.representativePhone,
                });
            }
        });
    },
    // eslint-disable-next-line
    [id]);

    return (
        <Container maxWidth="xl">
        {supplierDetails
            ? 
                <Box
                    sx={{
                        margin: 8,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                        gap: 4 }}
                >
                    <Grid container spacing={1}  sx={{ mb: 2 }} >
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                                {supplierDetails.supplierBusinessName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}>Website</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Link href={`${supplierDetails.supplierUrl}`} target="_blank" rel="noreferrer">
                                {supplierDetails.supplierUrl} <OpenInNewIcon fontSize="small"/>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider sx={{ mb: 2, mt: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}>Services</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography sx={{ border: 1, width: 100 }}>{supplierDetails.supplierCategory}</Typography>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}><br/>Transacts on</Typography>
                            <Typography component="p" variant="body">Occupational rehabilitation and associated medical services panel</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider sx={{ mb: 2, mt: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}>Operates in</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            {supplierDetails.supplierRegions.map((row) => (
                                <Typography component="p" variant="body2" key={row.state + row.name}>{row.state} {row.name}</Typography>
                            ))}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider sx={{ mb: 2, mt: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}>Company Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}>Authorised Representative</Typography>
                            <Typography component="p" variant="body2">{supplierDetails.supplierRepresentativeName}</Typography>
                            <Typography component="p" variant="body2">{supplierDetails.supplierRepresentativePhone}</Typography>
                            <Typography component="p" variant="body2">
                                <Link href={`mailto:${supplierDetails.supplierRepresentativeEmail}`} variant="body">
                                    {supplierDetails.supplierRepresentativeEmail}
                                </Link><br/><br/>
                            </Typography>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}>Headquarter address</Typography>
                            <Typography component="p" variant="body2">{supplierDetails.supplierPrimaryAddress}</Typography>
                            <Typography component="p" variant="body2">
                                {supplierDetails.supplierPrimaryAddressSuburb} {" "}
                                {supplierDetails.supplierPrimaryAddressState} {" "}
                                {supplierDetails.supplierPrimaryAddressPostcode}<br/><br/>
                            </Typography>
                            <Typography variant="subtitle1" sx={{fontWeight:700}}>ABN</Typography>
                            <Typography component="p" variant="body2">
                            <Link href={`https://abr.business.gov.au/SearchByAbn.aspx?SearchText=${supplierDetails.supplierAbn}`} target="_blank" rel="noreferrer">
                                    {supplierDetails.supplierAbn} <OpenInNewIcon fontSize="small"/>
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            border: 1,
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: 'start',
                            gap: 1,
                            padding: 1,
                            boxShadow: 1,
                        }}
                    >
                        <Typography variant="body">Business Contact</Typography>
                        <Typography variant="h2" sx={{ textAlign: 'center' }}>{supplierDetails.supplierContactName}</Typography>
                        <Typography variant="body2">{supplierDetails.supplierContactPhone}</Typography>
                        <Button
                            variant="contained"
                            component={Link}
                            href={`mailto:${supplierDetails.supplierContactEmail}`} target="_blank" rel="noreferrer"
                        >
                            Email Seller
                        </Button>
                    </Box>
                </Box>
            : <Loading />
        }
        </Container>
    );
}

export default SellerProfilePage;