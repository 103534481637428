import redaxios from "redaxios";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function signup(email, name, primaryUserEmail, headers) {
    const response = await redaxios.post(`${API}/auth/signup`, { email, name, primaryUserEmail }, {
        headers: headers,
    });
    return response.data;
}

export async function invite(token, emailAddress, headers) {
    const params = { e: encodeURIComponent(emailAddress) };

    const response = await redaxios.post(`${API}/users/send-invite/${token}`, {}, {
        headers: headers,
        params,
    });
    return response.data;
}

export async function create(token, emailAddress, password, headers) {
    const params = { e: encodeURIComponent(emailAddress) };

    const response = await redaxios.post(`${API}/auth/create-user/${token}`, { password }, {
        headers: headers,
        params,
    });
    return response.data;
}

export async function requestPasswordReset({ emailAddress }, headers) {
    const response = await redaxios.post(`${API}/auth/reset-password`, { email: emailAddress }, {
        headers: headers,
    });
    return response.data;
}

export async function resetPassword(token, emailAddress, data, headers) {
    const params = { e: encodeURIComponent(emailAddress) };

    const response = await redaxios.post(`${API}/auth/reset-password/${token}`, {
        ...data,
    }, {
        headers: headers,
        params,
    });
    return response.data;
}
