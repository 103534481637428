import React, { useEffect, useState } from 'react';
import CircularProgress  from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function Delayed(props) {
    const [ show, setShow ] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            setShow(true)
        }, 300)
      }, []);

    return show === true ? props.children : null;
}

export default function Loading() {
  return (
    <Delayed>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 4,
            }}
        >
            <CircularProgress />
            <Typography variant='h2' sx={{ mt: 4 }}>Loading...</Typography>
        </Box>
    </Delayed>
  );
}
