import { Alert, Box, Button, Container,Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import * as adminApi from "../api/admin";
import { Link as RouterLink } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import { ROLE_ADMIN, ROLE_PRIMARY_USER } from "../constants/userConstants";

const AdminReferralsForm = ({ handleOnSubmit }) => {
    const { control, handleSubmit, reset, getValues } = useForm({
        reValidateMode: "onBlur"
    });

    const errorHelper = {
        startDate: {
            required: "Start date is required",
            validateDate: "Start date is not a valid date",
            validateMinimumDate: "Start date must not be before 01/07/2018",
            validateMaximumDate: "Start date must not be in the future",
            validateExistsIfEndDate: "The start date must be entered where an end date is entered",
            validateBeforeEndDate: "The start date must be prior to the end date.",
        },
        endDate: {
            required: "End date is required",
            validateDate: "End date is not a valid date",
            validateMaximumDate: "End date must not be in the future",
            validateExistsIfStartDate: "The end date must be entered where start date is entered",
        },
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                gap: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    gap: 1,
                }}
            >
                <Controller
                    control={control}
                    name="startDate"
                    defaultValue=""
                    rules={{
                        validate: {
                            validateDate: (value) => !value || dayjs(value).isValid(),
                            validateMinimumDate: (value) => !dayjs(value).isBefore(dayjs('2018-07-01')),
                            validateMaximumDate: (value) => !dayjs(value).isAfter(dayjs()),
                            validateExistsIfEndDate: (value) => value || !getValues('endDate'),
                            validateBeforeEndDate: (value) => !value || !getValues('endDate') || dayjs(value).isBefore(getValues('endDate'))
                            },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <DatePicker
                            {...field}
                            label="Start date"
                            name="startDate"
                            onChange={(newValue) => {
                                field.onChange(newValue);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    sx={{ mr: 2 }}
                                    error={error !== undefined} 
                                    helperText={error ? errorHelper.startDate[error.type] : ""}
                                />
                            }
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="endDate"
                    defaultValue=""
                    rules={{
                        validate: {
                            validateDate: (value) => !value || dayjs(value).isValid(),
                            validateMaximumDate: (value) => !dayjs(value).isAfter(dayjs()),
                            validateExistsIfStartDate: (value) => value || !getValues('startDate'),
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <DatePicker
                            {...field}
                            label="End date"
                            name="endDate"
                            onChange={(newValue) => {
                                field.onChange(newValue);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    sx={{ mr: 4 }}
                                    error={error !== undefined} 
                                    helperText={error ? errorHelper.endDate[error.type] : ""}
                                />
                            }
                        />
                    )}
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    gap: 1,
                }}
            >
                <Button type="submit" variant="contained">Download Referrals</Button>
                <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                        reset({ startDate: null, endDate: null });
                    }}
                >
                    Reset
                </Button>
            </Box>
        </Box>

    );
}

const SupplierSearchForm = ({ handleOnSubmit }) => {
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                gap: 2,
            }}
        >

            <Controller
                control={control}
                name="supplierSearch"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Find providers by name prefix or email"
                        id="supplierSearch"
                        fullWidth 
                        helperText={error ? "" : "e.g. searching for c would give all providers starting with c"}
                    />
                )}
            />

            <Button type="submit" variant="contained">Search</Button>
        </Box>

    );
}

const UserSearchForm = ({ handleOnSubmit, handleOnReport }) => {
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                gap: 2,
                mt: 3,
            }}
        >

            <Controller
                control={control}
                name="userSearch"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Find user by partial email"
                        id="userSearch"
                        fullWidth 
                        helperText={error ? "" : "e.g. searching for ca would give all users that email contains ca"}
                    />
                )}
            />

            <Button type="submit" variant="contained">Search</Button>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    gap: 1,
                }}
            >
                <Button
                    type="button"
                    variant="contained"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOnReport();
                    }}
                >
                    Download all users
                </Button>
            </Box>
        </Box>

    );
}

export default function AdminPage() {
    const { user, setSuccess, loading, call, navigate, contextMap, setContextMap } = useAuth();
    const [ suppliers, setSuppliers ] = useState([]);
    const [ users, setUsers ] = useState([]);
    const [ searchType, setSearchType ] = useState();

    function downloadReferralsReport({ startDate, endDate }) {
        call(adminApi.referralsReport, { startDate, endDate }, (response) => {
            let filename = dayjs().format('YYYY-MM-DD') + "-referrals.csv";
            const { 'content-disposition': content_disposition } = response.headers;
            if (content_disposition) {
                filename = content_disposition.split(' ')[1].split('=')[1];
            }
            const blob = new Blob([response.data], { type: 'text/csv' }) // eslint-disable-line no-undef
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // for IE
              window.navigator.msSaveOrOpenBlob(blob, filename)
            } else {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = filename
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
        });
    }

    function downloadUsersReport() {
        call(adminApi.usersReport, {}, (response) => {
            let filename = dayjs().format('YYYY-MM-DD') + "-users.csv";
            const { 'content-disposition': content_disposition } = response.headers;
            if (content_disposition) {
                filename = content_disposition.split(' ')[1].split('=')[1];
            }
            const blob = new Blob([response.data], { type: 'text/csv' }) // eslint-disable-line no-undef
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // for IE
              window.navigator.msSaveOrOpenBlob(blob, filename)
            } else {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = filename
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
        });
    }

    function downloadCeilingPricesReport(providerCode) {
        call(adminApi.ceilingPricesReport, {providerCode, noloading: true}, (response) => {
            let filename = dayjs().format('YYYY-MM-DD') + "-ceiling-prices.csv";
            const { 'content-disposition': content_disposition } = response.headers;
            if (content_disposition) {
                filename = content_disposition.split(' ')[1].split('=')[1];
            }
            const blob = new Blob([response.data], { type: 'text/csv' }) // eslint-disable-line no-undef
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // for IE
              window.navigator.msSaveOrOpenBlob(blob, filename)
            } else {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = filename
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
        });
    }

    function handleSupplierSearch({ supplierSearch }) {
        call(adminApi.supplierSearch, { supplierSearch }, setSuppliers);
        setSearchType(1);
        setUsers([]);
    }

    function handleUserSearch({ userSearch }) {
        call(adminApi.userSearch, { userSearch }, setUsers);
        setSearchType(2);
        setSuppliers([]);
    }

    function handleUpdateCeilingPrice(supplierCode) {
        setSuccess(undefined);
        setContextMap(new Map(contextMap.set('supplierCode', supplierCode)));
        navigate("/edit-supplier/edit-ceiling-pricing");
    }

    const USER_ROLE_MASKS = {
        buyer: 'RCM',
        'primary-user': 'Primary User',
        admin: 'Admin',
        supplier: 'Provider'
    };
      
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h1" sx={{ mb: 2 }}>
                Welcome
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Tasks you can do here are shown below
            </Typography>
            <Grid container sx={{ gap: 2 }}>
                {user && user.role === ROLE_ADMIN &&
                    <React.Fragment>
                        <Grid item xs={12} sm={12}>
                            <AdminReferralsForm handleOnSubmit={downloadReferralsReport}/>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                            <SupplierSearchForm handleOnSubmit={handleSupplierSearch} />
                            <UserSearchForm handleOnSubmit={handleUserSearch} handleOnReport={downloadUsersReport}/>
                        </Grid>
                    </React.Fragment>
                }
                {user && user.role === ROLE_PRIMARY_USER &&
                    <Grid item xs={12} sm={3}>
                        <UserSearchForm handleOnSubmit={handleUserSearch} handleOnReport={downloadUsersReport}/>
                    </Grid>
                }
                <Grid item xs={12} sm={8}>
                    {loading &&
                        <Loading />
                    }
                    {!loading && searchType && searchType === 1 ?
                        suppliers && suppliers.length > 0 ?
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Update ceiling prices</TableCell>
                                            <TableCell>Download ceiling prices</TableCell>
                                            <TableCell>Edit regions/service</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {suppliers.map((supplier) => (
                                        <TableRow
                                        key={supplier.code}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <Typography component="p" variant="body2">{supplier.name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Button component={Link}
                                                    onClick={() => {
                                                        handleUpdateCeilingPrice(supplier.code);
                                                    }}
                                                >
                                                    Update prices
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        downloadCeilingPricesReport(supplier.code);
                                                    }}
                                                >
                                                    Download prices
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="p" variant="body2">Edit services and regions</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        : <Alert severity="info" sx={{ mt: 2 }}>There were no providers found for your search</Alert>
                    : !loading && searchType && searchType === 2 ?
                        users && users.length > 0 ?
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Deactivated</TableCell>
                                        <TableCell>Email</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {users.map((user) => (
                                        <TableRow
                                        key={user.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <Button component={RouterLink} to={`/user-profile/${user.id}`}>
                                                    {user.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="p" variant="body2">{user.role instanceof Array ? USER_ROLE_MASKS[user.role[0]] : user.role}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="p" variant="body2">{user.active ? 'No' : 'Yes'}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="p" variant="body2">{user.email}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        : <Alert severity="info" sx={{ mt: 2 }}>There were no users found for your search</Alert>
                    : null
                    }
                </Grid>
            </Grid>
        </Box>
        </Container>
    );
}