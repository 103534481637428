import { Divider, Grid, Link, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import RCMDetails from "./RCMDetails";

const renderEmployeeInfoDisclaimer = () => {
    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">Information For Employees</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>About the assessment</b></Typography>
                <Typography component="p" variant="body2">
                    The rehabilitation assessment takes place with your involvement and usually in consultation with your treating
                    medical practitioner and supervisor. It may include a review of your workplace to identify any reasonable
                    adjustments that need to be made, or work practices which need to be altered to help you to get back to work
                    quickly and safely.
                </Typography>
                <Typography component="p" variant="body2">
                    Any reasonable costs you may incur in attending this assessment examination will be paid by Comcare, by the
                    forwarding to Comcare of any tax invoice receipts for consideration.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>What happens after the assessment</b></Typography>
                <Typography component="p" variant="body2">
                    After the assessment, a rehabilitation program may be required. If a rehabilitation program is required it will
                    outline the actions you will be required to undertake to assist your recovery and return to suitable employment.
                    It will also state who is responsible for the various steps in the program and include a timetable for
                    completion.
                </Typography>
                <Typography component="p" variant="body2">
                    If a rehabilitation program is required under s. 37 of the <i>SRC Act 1988</i> your Case Manager should develop
                    this program in consultation with you, and this may involve discussion with your treating medical practitioner
                    and supervisor. Where required by the Guidelines for Rehabilitation Authorities 2019, an approved Workplace
                    Rehabilitation Provider (WRP) may be involved in developing and monitoring your program.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>Privacy information</b></Typography>
                <Typography component="p" variant="body2">
                    Your privacy is important to us. For information about how we handle your personal information, please visit{' '}
                    <Link href="//www.comcare.gov.au/privacy" target="_blank" rel="noreferrer">www.comcare.gov.au/privacy</Link>
                    {' '}or contact us on 1300 366 979 and request copy of our Privacy Policy
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>What if I want copies of documents held on my files?</b></Typography>
                <Typography component="p" variant="body2">
                    You can write to Comcare requesting the documents you need held by Comcare.
                </Typography>
                <Typography component="p" variant="body2">
                    Requests for information held by your employer or WRP should be directed to them.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">Notice of Rights and Responsibilities</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>What is a determination</b></Typography>
                <Typography component="p" variant="body2">
                    A determination is a decision. In this case, it is a decision made under s. 36 of the <i>SRC Act 1988</i>, that
                    you attend an examination as part of your assessment for rehabilitation.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>What if I don't agree with a determination made by the delegate</b></Typography>
                <Typography component="p" variant="body2">
                    If you do not agree with the determination made by the delegate (usually the Case Manager) you may ask Comcare
                    to reconsider the determination that you undertake a rehabilitation assessment examination.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>To request a reconsideration of your referral for rehabilitation assessment examination</b></Typography>
                <Typography component="div" variant="body2">
                    You must provide the following information to Comcare within 30 days of receiving the determination:
                    <ul>
                        <li>a copy of the Rehabilitation assessment examination determination and referral form</li>
                        <li>a written request for a reconsideration explaining why you don't agree with the determination</li>
                        <li>
                            any new information that supports your request, such as medical reports that have not previously been
                            considered.
                        </li>
                    </ul>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body2">Send the information to:</Typography>
                <Typography component="p" variant="body2">Disputed Claims</Typography>
                <Typography component="p" variant="body2">Comcare</Typography>
                <Typography component="p" variant="body2">GPO Box 9905</Typography>
                <Typography component="p" variant="body2">Canberra ACT 2601</Typography>
                <Typography component="p" variant="body2">Team.Reconsiderations@comcare.gov.au</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>30 day time limit</b></Typography>
                <Typography component="p" variant="body2">
                    If you are unable to put your request to Comcare within 30 days, you may apply for an extension of time.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>What happens next?</b></Typography>
                <Typography component="p" variant="body2">
                    Your employer will receive a copy of your request for reconsideration and may provide a response to Comcare.
                    Comcare will consider the available information and will decide to affirm, revoke or vary the determination.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>What if I do not undergo the assessment examination</b></Typography>
                <Typography component="p" variant="body2">
                    If you refuse or fail, without reasonable excuse, to undergo or in any way obstruct an assessment examination,
                    your rights to compensation entitlements under the <i>SRC Act 1988</i> (excluding medical treatment costs), and
                    your right to institute or continue any proceedings under the <i>SRC Act 1988</i> will be suspended until the
                    assessment examination takes place. This means you cannot claim compensation entitlements (excluding medical
                    treatment costs) for the period of that suspension. Your compensation entitlements for this period will not be
                    reimbursed even if the suspension is lifted and your rights to full compensation entitlements are reinstated
                    (see ss. 36(4) of the <i>SRC Act 1988</i>).
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>Legal costs</b></Typography>
                <Typography component="p" variant="body2">
                    If you decide to engage a solicitor to assist you with this process, any legal costs will be your responsibility
                    regardless of the outcome of Comcare's decision.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>More information</b></Typography>
                <Typography component="p" variant="body2">
                    If you need any further information about your rights or other specific issues, please contact Comcare on 1300
                    366 979. You can also make an online enquiry or access resources via the Comcare website{' '}
                    <Link href="//www.comcare.gov.au" target="_blank" rel="noreferrer">www.comcare.gov.au</Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

const DeterminationDetails = ({ referral }) => {
    const {
        agency,
        created,
        createdBy,
        detail,
        employeeGivenName = "",
        employeeLastName = "",
        providerName
      } = referral;
      const { dateOfInjury, s36Reasons, s36Authority } = detail || {};
    
      const dateOfInjuryText = dayjs(dateOfInjury).format('DD MMM YYYY');
      const dateCreatedText = dayjs(created).format('DD MMM YYYY');
        
    return (
        <React.Fragment>
            <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">Determination</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="p" variant="body"><b>Determination under subsection 36(3) of the <i>SRC Act 1988</i></b></Typography>
                    <Typography component="p" variant="body2">{`As you ${employeeGivenName} ${employeeLastName} an employee, have suffered an injury on ${dateOfInjuryText}`}</Typography>
                    <Typography component="p" variant="body2">
                        resulting in an incapacity for work or an impairment, I (holding a delegation under s. 41A of the{' '}
                        <i>SRC Act 1988</i>) have arranged an assessment of your capability to undertake a rehabilitation program. I
                        have determined under subsection 36(3) of the <i>Safety, Rehabilitation and Compensation Act 1988 </i>(SRC Act){' '}
                        you are required to attend an examination for the purposes of that assessment.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="p" variant="body"><b>The assessment will be carried out by</b></Typography>
                    <Typography component="p" variant="body2">{providerName}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="p" variant="body"><b>The reasons for the determination are</b></Typography>
                    <Typography component="p" variant="body2">{s36Reasons}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="p" variant="body2">
                        If you are not satisfied with this determination, you may request a reconsideration by Comcare.
                        Please refer to the section in the Notice of rights and responsibilities below titled
                        'What if I don’t agree with a determination made by the delegate'.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="p" variant="body2"><b>Delegate authority</b></Typography>
                    <Typography component="p" variant="body2">{s36Authority}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="p" variant="body2">{dateCreatedText}</Typography>
                    <Typography component="p" variant="body2">{createdBy}</Typography>
                    <Typography component="p" variant="body2">{agency}</Typography>
                </Grid>
            </Grid>
            <Divider />
            <RCMDetails referral={referral} />
            <Divider />
            {renderEmployeeInfoDisclaimer()}
        </React.Fragment>
    );

};

export default DeterminationDetails;
