import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink, useParams, useSearchParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function ResetPasswordPage() {
    const { error, success, setSuccess, resetPassword } = useAuth();
    const { token } = useParams();
    const [ searchParams ] = useSearchParams();
    const emailAddress = searchParams.get("e");
    const { control, handleSubmit, getValues, reset } = useForm({
        reValidateMode: "onBlur"
    });

    const errorHelper = {
        password: {
            required: "Password is required",
            minLength: "Password should be at least 10 characters ",
        },
        confirmPassword: {
            required: "Password is required",
            minLength: "Password should be at least 10 characters ",
            validatePasswordMatch: "Passwords do not match.",
        },
    }

    function handleOnSubmit({ password, confirmPassword }) {
        setSuccess(undefined);
        resetPassword(token, emailAddress, { password, confirmPassword }, (response) => {
            setSuccess("Reset successful");
            reset({ password: '', confirmPassword: '' });
        });
    }

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {success &&
                <Alert severity="success" sx={{ mb: 2 }}>
                    <Typography variant="h3">You have successfully changed your password</Typography>
                    Please{' '}
                    <RouterLink to="/login">
                        login
                    </RouterLink>
                    {' '}to continue.
                </Alert>
            }
            {error && <Alert severity="error">There was a problem resetting your password</Alert>}
            <Typography variant="h2">Reset password</Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(handleOnSubmit)}
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    width: '75ch',
                    gap: 1,
                    mt: 2,
                }}
            >
                <Controller
                    control={control}
                    name="password"
                    defaultValue=""
                    rules={{
                        required: true,
                        minLength: 10,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            required
                            type="password"
                            label="Password"
                            id="password"
                            fullWidth 
                            error={error !== undefined}
                            helperText={error ? errorHelper.password[error.type] : "Your password should be at least 10 characters"}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="confirmPassword"
                    defaultValue=""
                    rules={{
                        required: true,
                        minLength: 10,
                        validate: {
                            validatePasswordMatch: (value) => !value || !getValues('password') || value === getValues('password')
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            required
                            type="password"
                            label="Confirm Password"
                            id="confirmPassword"
                            fullWidth 
                            error={error !== undefined}
                            helperText={error ? errorHelper.confirmPassword[error.type] : "Repeat password used above"}
                        />
                    )}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                        gap: 1,
                        mt: 2,
                    }}
                >
                    <Button type="submit" variant="contained">Reset password</Button>
                </Box>
            </Box>
        </Box>
    );
}