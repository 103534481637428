import redaxios from "redaxios";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function login(emailAddress, password, headers) {
    const response = await redaxios.post(`${API}/auth/login`, { username: emailAddress, password }, {
        headers,
    });
    return response.data;
}

export async function logout(headers) {
    const response = await redaxios.post(`${API}/logout`, {}, {
        headers,
    });
    return response;
}