import redaxios from "redaxios";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function supplier({ supplierCode }, headers) {
    const response = await redaxios.get(`${API}/providers/${supplierCode}`, {
        headers
    });
    return response.data;
}

export async function prices({ supplierCode, serviceId, subCategoryId }, headers) {
    const response = await redaxios.get(`${API}/prices/providers/${supplierCode}/services/${serviceId}/sub-services/${subCategoryId}`, {
        headers
    });
    return response.data;
}

export async function update({ id, data }, headers) {
    const response = await redaxios.post(`${API}/ceiling-prices/${id}`, {
            ...data,
        }, {
            headers: headers,
        }
    );
    return response.data;
}

export async function updateByCpi({ supplierCode, data }, headers) {
    const response = await redaxios.post(`${API}/providers/${supplierCode}/ceiling-prices`, {
            ...data,
        }, {
            headers: headers,
        }
    );
    return response.data;
}

export async function confimByCpi({ supplierCode, data }, headers) {
    const response = await redaxios.post(`${API}/providers/${supplierCode}/check-ceiling-prices`, {
            ...data,
        }, {
            headers: headers,
        }
    );
    return response.data;
}

export async function updatePrices({ id, data }, headers) {
    const response = await redaxios.post(`${API}/prices/${id}`, data.prices, {
            headers: headers,
        }
    );
    return response.data;
}

export async function ceilingPricesReport({ providerCode }, headers) {
    const response = await redaxios.get(`${API}/ceiling-prices/report`, {
        headers,
        params: {providerCode},
        responseType: 'blob'
    });
    return response;
}
