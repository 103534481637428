import { Alert, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import * as adminApi from "../api/admin";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import Loading from "../components/Loading/Loading";

const UserProfileActions = ({ currentUser, selectedUser, handleDeactivateUser, handleActivateUser, handleUnlockUser, handleRoleUpdate }) => {
    const { role: currentRole } = currentUser;
    const { id, active, locked, role } = selectedUser;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                gap: 1,
            }}
        >
            {active &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                    }}
                >
                    <Button
                        onClick={() => {
                            handleDeactivateUser(id)
                        }}
                        variant="contained"
                    >
                        Deactivate
                    </Button>
                </Box>
            }
            {!active &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                    }}
                >
                    <Button
                        onClick={() => {
                            handleActivateUser(id)
                        }}
                        variant="contained"
                    >
                        Activate
                    </Button>
                </Box>
            }
            {locked &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                    }}
                >
                    <Button
                        onClick={() => {
                            handleUnlockUser(id)
                        }}
                        variant="contained"
                    >
                        Unlock
                    </Button>
                </Box>
            }
            {currentRole === 'admin' && role === 'buyer' &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                    }}
                >
                    <Button
                        onClick={() => {
                            handleRoleUpdate(id, 'primary-user')
                        }}
                        variant="contained"
                    >
                        Convert to primary RCM
                    </Button>
                </Box>
            }
            {currentRole === 'admin' && role === 'primary-user' &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                    }}
                >
                    <Button
                        onClick={() => {
                            handleRoleUpdate(id, 'buyer')
                        }}
                        variant="contained"
                    >
                        Convert to RCM
                    </Button>
                </Box>
            }
        </Box>
    );
};

export default function UserProfilePage() {
    const { user, error, call } = useAuth();
    const { id } = useParams();
    const [ selectedUser, setSelectedUser ] = useState();
    const [ userSuccess, setUserSuccess ] = useState();

    useEffect(() => {
        loadUser();
    },
    // eslint-disable-next-line
    [ id ]);

    function loadUser() {
        call(adminApi.getUser, { id }, (user) => {
            setSelectedUser(user);
        });
    }

    function handleActivateUser(id) {
        setUserSuccess(undefined);
        call(adminApi.activateUser, { id }, (user) => {
            setSelectedUser(user);
            setUserSuccess('User activated successfully');
        }, 'Unable to activate user');
    }

    function handleDeactivateUser(id) {
        setUserSuccess(undefined);
        call(adminApi.deactivateUser, { id }, (user) => {
            setSelectedUser(user);
            setUserSuccess('User deactivated successfully');
        }, 'Unable to deactivate user');
    }

    function handleUnlockUser(id) {
        setUserSuccess(undefined);
        call(adminApi.unlockUser, { id }, (user) => {
            setSelectedUser(user);
            setUserSuccess('User unlocked successfully');
        }, 'Unable to unlock user');
    }

    function handleRoleUpdate(id, role) {
        setUserSuccess(undefined);
        call(adminApi.updateUserRole, { id, targetRole: role }, (user) => {
            setSelectedUser(user);
            setUserSuccess('User role updated successfully');
        }, 'Unable to update user role');
    }

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}
            <Typography variant="h2" sx={{ mb: 2 }}>{selectedUser ? selectedUser.emailAddress : ''}</Typography>
            {userSuccess && <Alert severity="success">{userSuccess}</Alert>}
            <RouterLink
                to="/admin"
                variant="body2"
            >
                Back to user search
            </RouterLink>
            {selectedUser
                ?
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell>Last login</TableCell>
                                    <TableCell>Last password</TableCell>
                                    <TableCell>Locked</TableCell>
                                    <TableCell>Change status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography component="p" variant="body2">{selectedUser.name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{selectedUser.role}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{selectedUser.providerCode}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{dayjs(selectedUser.loggedIn).format('D MMM YYYY')}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{dayjs(selectedUser.passwordChanged).format('D MMMM YYYY HH:MM')}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{selectedUser.locked ? 'Y' : 'N'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <UserProfileActions currentUser={user} selectedUser={selectedUser} handleActivateUser={handleActivateUser} handleDeactivateUser={handleDeactivateUser} handleUnlockUser={handleUnlockUser} handleRoleUpdate={handleRoleUpdate} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                : <Loading />
            }
        </Box>
    );
}
