import redaxios from "redaxios";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function suppliers({ panel }, headers) {
    let params = {};
    if (panel) {
        params = { ...params, panel, };
    }
    const response = await redaxios.get(`${API}/providers/list`, {
        headers,
        params,
    });
    return response.data;
}

export async function supplier({ supplierCode, panel }, headers) {
    let params = {};
    if (panel) {
        params = { ...params, panel, };
    }
    const response = await redaxios.get(`${API}/providers/${supplierCode}`, {
        headers,
        params,
    });
    return response.data;
}

export async function prices({ supplierCode, serviceId, categoryId, date, panel }, headers) {
    let params = {};
    if (panel) {
        params = { ...params, panel, };
    }
    const response = await redaxios.get(`${API}/prices/providers/${supplierCode}/services/${serviceId}/sub-services/${categoryId}?date=${date}`, {
        headers,
        params,
    });
    return response.data;
}
