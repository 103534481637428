import React from "react";
import { Outlet, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import HomePage from "./pages/HomePage";
import useAuth from "./hooks/useAuth";
import DashboardPage from "./pages/DashboardPage";
import SignupPage from "./pages/SignupPage";
import 'dayjs/locale/en-au';
import SellerCatalogPage from "./pages/SellerCatalogPage";
import CopyrightPage from "./pages/CopyrightPage";
import DisclaimerPage from "./pages/DisclaimerPage";
import PrivacyPage from "./pages/PrivacyPage";
import SecurityStatementPage from "./pages/SecurityStatementPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import ReferralDetailsPage from "./pages/ReferralDetailsPage";
import AssignReferralPage from "./pages/AssignReferralPage";
import ReferralBuilderPage from "./pages/ReferralBuilderPage";
import AdminPage from "./pages/AdminPage";
import SupplierProfilePage from "./pages/SupplierProfilePage";
import SellerProfilePage from "./pages/SellerProfilePage";
import PriceHistoryPage from "./pages/PriceHistoryPage";
import EditCeilingPricePage from "./pages/EditCeilingPricePage";
import UserProfilePage from "./pages/UserProfilePage";
import InvitationPage from "./pages/InvitationPage";
import CreatePasswordPage from "./pages/CreatePasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RequestResetPasswordPage from "./pages/RequestResetPasswordPage";
import AboutPage from "./pages/AboutPage";
import DocumentsPage from "./pages/DocumentsPage";

function RequireAuth({ children, redirectTo }) {
  const { user, location } = useAuth();
  return user && user.token ? children : <Navigate to={redirectTo} state={{ prev: `${location.pathname}${location.search}` }}/>;
}

export default function Router() {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={<HomePage />}
        />
        <Route
          path="/dashboard/:dashboardAction"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path="/referrals/:id"
          element={
            <RequireAuth redirectTo="/login">
              <ReferralDetailsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/assign-referral/:id"
          element={
            <RequireAuth redirectTo="/login">
              <AssignReferralPage />
            </RequireAuth>
          }
        />
        <Route
          path="/referral-builder/:id"
          element={
            <RequireAuth redirectTo="/login">
              <ReferralBuilderPage />
            </RequireAuth>
          }
        />
        <Route
          path="/seller-catalogue"
          element={
            <RequireAuth redirectTo="/login">
              <SellerCatalogPage />
            </RequireAuth>
          }
        />
        <Route
          path="/admin"
          element={
            <RequireAuth redirectTo="/login">
              <AdminPage />
            </RequireAuth>
          }
        />
        <Route
          path="/price-history"
          element={
            <RequireAuth redirectTo="/login">
              <PriceHistoryPage panel="2023" />
            </RequireAuth>
          }
        />
        <Route
          path="/price-history-2016"
          element={
            <RequireAuth redirectTo="/login">
              <PriceHistoryPage panel="2016" />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-supplier/edit-ceiling-pricing"
          element={
            <RequireAuth redirectTo="/login">
              <EditCeilingPricePage />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <RequireAuth redirectTo="/login">
              <SupplierProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="/seller-profile/:id"
          element={
            <RequireAuth redirectTo="/login">
              <SellerProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="/user-profile/:id"
          element={
            <RequireAuth redirectTo="/login">
              <UserProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="/send-invite/:token"
          element={
            <RequireAuth redirectTo="/login">
              <InvitationPage />
            </RequireAuth>
          }
        />
        <Route
          path="/documents"
          element={
            <RequireAuth redirectTo="/login">
              <DocumentsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={<LoginPage />}
        />
        <Route
          path="/logout"
          element={<LogoutPage />}
        />
        <Route
          path="/signup"
          element={<SignupPage />}
        />
        <Route
          path="/copyright"
          element={<CopyrightPage />}
        />
        <Route
          path="/disclaimer"
          element={<DisclaimerPage/>}
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyPage/>}
        />
        <Route
          path="/security"
          element={<SecurityStatementPage/>}
        />
        <Route
          path="/terms-of-use"
          element={<TermsOfUsePage/>}
        />
        <Route
          path="/about"
          element={<AboutPage/>}
        />
        <Route
          path="/create-user/:token"
          element={<CreatePasswordPage />}
        />
        <Route
          path="/reset-password"
          element={<RequestResetPasswordPage />}
        />
        <Route
          path="/reset-password/:token"
          element={<ResetPasswordPage />}
        />
      </Route>
    </Routes>
  );
}
