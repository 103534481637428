import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import useAuth from "../../hooks/useAuth";

const DashboardTable = (props) => {
    const { referrals, statusFilter, sort, renderStatus, renderActions, refreshReferrals } = props;
    const auth = useAuth();

    let filteredReferrals = referrals;
    if (referrals) {
        if (statusFilter) {
            filteredReferrals = referrals.filter((referral) => statusFilter.indexOf(referral.status) >= 0);
        }
        filteredReferrals = filteredReferrals.sort(sort);
    }
    return (
        <TableContainer component={Paper}>
        <Table sx={{ }}>
            <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Date created</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {filteredReferrals.map((row) => (
                <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                    <Typography component="p" variant="body2">{row.id}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <RouterLink to={`/referrals/${row.id}`}>{`${row.employeeGivenName} ${row.employeeLastName}` || "Name is not specified"}</RouterLink>
                    </Typography>
                    <Typography component="p" variant="body2">{row.region.state} {row.region.name}</Typography>
                    <Typography component="p" variant="body2">{row.subServiceTypeName ? row.serviceTypeName + " - " + row.subServiceTypeName : row.serviceTypeName}</Typography>
                </TableCell>
                <TableCell>
                    <Typography component="p" variant="body2">{dayjs(row.created).format('HH:mm DD/MM/YYYY')}</Typography>
                </TableCell>
                <TableCell>{renderStatus(row.status, row.created, row.isFeeHourly)}</TableCell>
                <TableCell>{renderActions(row, auth, refreshReferrals)}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
};

export default DashboardTable;