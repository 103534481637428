export const STATES = [
    {
        value: 'ACT',
        label: 'ACT'
    },
    {
        value: 'NSW',
        label: 'NSW'
    },
    {
        value: 'NT',
        label: 'NT'
    },
    {
        value: 'QLD',
        label: 'QLD'
    },
    {
        value: 'SA',
        label: 'SA'
    },
    {
        value: 'TAS',
        label: 'TAS'
    },
    {
        value: 'VIC',
        label: 'VIC'
    },
    {
        value: 'WA',
        label: 'WA'
    },
  ]

export const limitWords = limit => (val = '') => val === undefined || ((val && val.match(/\S+/g)) || []).length <= limit

export const VALID_CURRENCY_PATTERN = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
export const validateCurrency = (value) => !value || parseFloat(value.replace(/,/g, '').replace(/\$/g, '')) > 0
export const parseCurrency = (value) => parseFloat(value.replace(/,/g, '').replace(/\$/g, ''))

export const VALID_PERCENTAGE_PATTERN = /^ *100(\.0{0,2})? *%?$|^ *\d{1,2}(\.\d{1,2})? *%?$/
export const validatePercentage = (value) => !value || (parseFloat(value.replace(/,/g, '').replace(/%/g, '')) >= 0 && parseFloat(value.replace(/,/g, '').replace(/%/g, '')) <= 100)
export const parsePercentage = (value) => parseFloat(value.replace(/,/g, '').replace(/%/g, ''))

export const validateLink = (value) => {
    if (!value) {
      return true
    }
    return value.match(/^http/)
}
  
export const validateLinks = (value) => {
    if (!value) {
      return true
    }
  
    if (!Array.isArray(value)) {
        value = [value]
    }
  
    if (value.length === 0) {
      return true
    }
  
    if (typeof value[0] === 'object') {
        value = value.map(v => v.url)
    }
  
    return value.filter(v => validateLink(v)).length === value.length
}

export const validateWhitespaceForRequired = (value) => !!value.trim()

export const VALID_EMAIL_ADDRESS_PATTERN = /^[^@^\s]+@[\d\w-]+(\.[\d\w-]+)+$/
export const VALID_BUSINESS_ABN_PATTERN = /^(\d *?){11}$/
export const VALID_PHONE_NUMBER_PATTERN = /^(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$/

export function delay(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

export function serviceAndSubServiceId(serviceId, subServiceId) {
    return serviceId * 1000 + subServiceId;
}
export function serviceId(serviceAndSubServiceId) {
    return Math.floor(serviceAndSubServiceId / 1000);
}
export function subServiceId(serviceAndSubServiceId) {
    return serviceAndSubServiceId % 1000;
}
