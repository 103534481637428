import { Alert, Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Controller } from "react-hook-form";
import { STATES, validateLinks, validateWhitespaceForRequired } from "../../constants/constants";
import { VALID_BUSINESS_ABN_PATTERN } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";

const SupplierBusinessDetailsForm = ({ control, success }) => {
    const { error } = useAuth();

    const errorHelper = {
        supplierBusinessName: {
            required: "Business name is required",
            validateWhitespaceForRequired: "Business name is required",
        },
        supplierAbn: {
            required: "ABN is required",
            pattern: "Invalid ABN format"
        },
        supplierUrl: {
            required: "You must provide a website link beginning with http",
            validateLink: "Links provided must begin with http"
        },
        supplierLinkedinUrl: {
            validateLink: "Links provided must begin with http"
        },
        supplierPrimaryAddress: {
            required: "You must provide an address",
            validateWhitespaceForRequired: "You must provide an address",
        },
        supplierPrimaryAddressSuburb: {
            required: "You must provide a suburb",
            validateWhitespaceForRequired: "You must provide a suburb",
        },
        supplierPrimaryAddressState: {
            required: "You must provide a state",
        },
        supplierPrimaryAddressPostcode: {
            required: "You must provide a postcode",
            pattern: "Postcodes must be 3 or 4 digits long and only numbers",
            validateWhitespaceForRequired: "You must provide a postcode",
        },
      };
    
    return (
        <Box
            sx={{
                ml: 8,
                mr: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '75ch',
                gap: 2,
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}

            <Typography variant="h1">
                Your profile
            </Typography>
            <Typography variant="h2" sx={{ mb: 2 }}>
                Edit your business details
            </Typography>

            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            <Controller
                control={control}
                name="supplierBusinessName"
                defaultValue=""
                rules={{
                    required: true,
                    validate: {
                        validateWhitespaceForRequired: validateWhitespaceForRequired,
                    }
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Business name"
                        id="supplierBusinessName"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierBusinessName[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierAbn"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: VALID_BUSINESS_ABN_PATTERN
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="ABN"
                        id="supplierAbn"
                        fullWidth 
                        inputProps={{ maxLength: 14 }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierAbn[error.type] : "You need an Australian Business Number to do business in Australia."}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierBusinessSummary"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Summary"
                        id="supplierBusinessSummary"
                        fullWidth
                        multiline = {true}
                        minRows={4}
                        maxRows={4}
                        helperText="3-4 sentences that describe your business. This can be seen by all government buyers."
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierUrl"
                defaultValue=""
                rules={{
                    required: true,
                    validate: {
                        validateLink: validateLinks,
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Website URL"
                        id="supplierUrl"
                        fullWidth
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierUrl[error.type] : "Provide a link to your website beginning with http"}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierLinkedinUrl"
                defaultValue=""
                rules={{
                    validate: {
                        validateLink: validateLinks,
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="LinkedIn URL (optional)"
                        id="supplierLinkedinUrl"
                        fullWidth
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierLinkedinUrl[error.type] : "Provide a LinkedIn URL beginning with http"}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierPrimaryAddress"
                defaultValue=""
                rules={{
                    required: true,
                    validate: {
                        validateWhitespaceForRequired: validateWhitespaceForRequired,
                    }
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Primary address"
                        id="supplierPrimaryAddress"
                        fullWidth 
                        inputProps={{ maxLength: 500 }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierPrimaryAddress[error.type] : "Principal place of business."}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierPrimaryAddressSuburb"
                defaultValue=""
                rules={{
                    required: true,
                    validate: {
                        validateWhitespaceForRequired: validateWhitespaceForRequired,
                    }
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Suburb"
                        id="supplierPrimaryAddressSuburb"
                        fullWidth 
                        inputProps={{ maxLength: 30 }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierPrimaryAddressSuburb[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierPrimaryAddressState"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        id="supplierPrimaryAddressState"
                        options={STATES}
                        autoHighlight
                        getOptionLabel={option => option && option.label ? option.label : ""}
                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                required
                                label="State"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled" // disable autocomplete and autofill
                                }}
                                error={error !== undefined}
                                helperText={error ? errorHelper.supplierPrimaryAddressState[error.type] : ""}
                            />
                        )}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierPrimaryAddressPostcode"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Postcode"
                        id="supplierPrimaryAddressPostcode"
                        fullWidth 
                        inputProps={{ maxLength: 4 }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierPrimaryAddressPostcode[error.type] : ""}
                    />
                )}
            />
        </Box>
    );
}

export default SupplierBusinessDetailsForm;