import React from "react";
import { examineeServiceTypeIds } from "../../constants/referralConstants";
import dayjs from "dayjs";
import { Grid, Typography } from "@mui/material";

export function getAddressText(address) {
    if (address) {
        const {
            addressLine = "",
            country = "",
            postcode: postalCode = "",
            state = "",
            suburb = ""
        } = address;
      
        return `${addressLine} ${suburb} ${state} ${postalCode} ${country}`;
    }
    return "";
}
  
const InjuredEmployeeDetails = ({ referral }) => {
    const {
        detail,
        employeeGivenName = "",
        employeeLastName = "",
        restricted = false,
        serviceTypeId
    } = referral;
    const {
        employeeSalutation = "",
        employeeAgsContractorNumber: employeeAgsNumber,
        employeeDateOfBirth,
        employeeGender,
        employeeHomeAddress,
        employeeHomeAddressSuburb: employeeHomeSuburb,
        employeeHomeAddressState: employeeHomeState,
        employeeHomeAddressPostcode: employeeHomePostcode,
        employeeEmail,
        employeePhone,
        employeeInterpreterLanguage,
        requiresInterpreter
    } = detail || {};
    
    const addressText = getAddressText({
        addressLine: employeeHomeAddress,
        suburb: employeeHomeSuburb,
        state: employeeHomeState,
        postcode: employeeHomePostcode
    });

    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{serviceTypeId && examineeServiceTypeIds.includes(serviceTypeId) ? 'Examinee details' : 'Injured employee'}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">AGS number or Contractor number</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeAgsNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{`${employeeSalutation} ${employeeGivenName} ${employeeLastName}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Restricted person?</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{restricted ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Date of birth</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeDateOfBirth ? dayjs(employeeDateOfBirth).format('DD MMM YYYY') : ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Gender Identity</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeGender}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Email</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeEmail}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Home address</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{addressText}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Home phone</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeePhone}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Interpreter required?</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{requiresInterpreter ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Interpreter language</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeInterpreterLanguage}</Typography>
            </Grid>
        </Grid>
    );
}

export default InjuredEmployeeDetails;
