import redaxios from "redaxios";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function list({ status, wholeOrg, startDate, endDate, keyword }, headers) {
    let params = { status };
    if (wholeOrg) {
        params = { ...params, wholeOrg, };
    }

    if (startDate && endDate) {
        params = {
            ...params,
            startDate,
            endDate,
        }
    }

    if (keyword) {
        params = { ...params, keyword: keyword, };
    }

    const response = await redaxios.get(`${API}/referrals`, {
        headers: headers,
        params,
    });
    return response.data;
}

export async function get({ id }, headers) {
    const response = await redaxios.get(`${API}/referrals/${id}`, {
        headers: headers,
    });
    return response.data;
}

export async function accept({ id }, headers) {
    const response = await redaxios.post(`${API}/referrals/${id}/accept`, {}, {
        headers: headers,
    });
    return response.data;
}

export async function reject({ id, rejectReasonId, rejectReasonText }, headers) {
    const response = await redaxios.post(`${API}/referrals/${id}/reject`, {}, {
        headers: headers,
        params: {
            reasonId: parseInt(rejectReasonId, 10),
            reasonText: rejectReasonText,
        }
    });
    return response.data;
}

export async function cancel({ id, cancelReasonId, cancelReasonText }, headers) {
    let params = { reasonText: cancelReasonText };
    if (cancelReasonId) {
        params = { ...params, reasonId: cancelReasonId, };
    }
    const response = await redaxios.post(`${API}/referrals/${id}/cancel`, {}, {
        headers: headers,
        params: params
    });
    return response.data;
}

export async function complete({ id }, headers) {
    const response = await redaxios.post(`${API}/referrals/${id}/complete`, {}, {
        headers: headers,
    });
    return response.data;
}

export async function sentForPayment({ id }, headers) {
    const response = await redaxios.post(`${API}/referrals/${id}/sentForPayment`, {}, {
        headers: headers,
    });
    return response.data;
}

export async function rejectionReasons(_, headers) {
    const response = await redaxios.get(`${API}/referrals/rejection-reasons`, {
        headers
    });
    return response.data.map(element => ({
        value: element.id.toString(),
        text: element.text,
        reasonTextRequired: element.reasonTextRequired
    }));
}

export async function cancellationReasons(_, headers) {
    const response = await redaxios.get(`${API}/referrals/cancellation-reasons`, {
        headers
    });
    const options = response.data.map(element => ({
        value: element.id.toString(),
        text: element.text,
    }));
    options.push({
        value: 'other',
        text: 'Other',
    });
    return options;
}

export async function agencyUsers({ id }, headers) {
    const response = await redaxios.get(`${API}/referrals/agency-users?referralId=${id}`, {
        headers
    });
    return response.data;
}

export async function assign({ id, userId }, headers) {
    const response = await redaxios.post(`${API}/referrals/${id}/assign`, {}, {
        headers: headers,
        params: {
            userId,
        }
    });
    return response.data;
}

export async function infoByPrice({ id }, headers) {
    const response = await redaxios.get(`${API}/referrals/info/${id}`, {
        headers
    });
    return response.data;
}

export async function delegateByUser({ userId }, headers) {
    const response = await redaxios.get(`${API}/referrals/delegate-details/${userId}`, {
        headers
    });
    return response.data;
}

export async function create({ payload }, headers) {
    const referralData = {
        ...payload,
        comcareClaimNumber:
            payload.detail.referralType === 'Non-Compensation' ? '' : payload.detail.comcareClaimNumber,
        liabilityDetermined:
            payload.detail.referralType === 'Non-Compensation' ? '' : payload.detail.liabilityDetermined,
        referralType: payload.detail.referralType,
        employeeGivenName: payload.detail.employeeGivenName,
        employeeLastName: payload.detail.employeeSurname,
        isRestricted: payload.detail.restrictedPerson
    };
    const response = await redaxios.post(`${API}/referrals`, {
            ...referralData,
        }, {
            headers: headers,
        }
    );
    return response.data;
}
