import {  AppBar, Toolbar, Typography, Button, Tooltip } from "@mui/material";
import {  Container } from "@mui/material";

import React from "react";
import { Link } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { ReactComponent as ComcareLogo } from "../../images/Comcare-inline-blue.svg"

export default function Header() {
    const { userTypeHomeUrl, userTypeNavLinks } = useUser();
    const commonStyles = {borderColor: '#a4c8e1',};
   
    const navigationLinks = () => {
        const navLinks = userTypeNavLinks || [];
        if (navLinks && navLinks.length) {
          return navLinks.map(navLink => {
            const { key, url, text } = navLink
            return (
              <Button color="inherit" component={Link} to={url} key={key} sx={{fontWeight:600,fontSize:{xs:"0.75rem",sm:"0.75rem",md:"0.75rem",lg:"1rem",xl:"1.25rem"},whiteSpace:"nowrap"}}>
                  {text}
              </Button>
            )
          });
        }
  
        return '';
    }
  
    return (

        <AppBar  sx={{ borderTop:1,borderBottom:1, ...commonStyles, pl: { xs: 0,sm: 0, md: 2, lg: 4, xl: 4}, pr: {xs: 0,sm: 0, md: 2, lg: 4, xl: 4}, }} position="static">
            <Container maxWidth="xl" sx={{ pl: { xs: 0,sm: 0,},pr: { xs: 0,sm: 0,},}}>
            <Toolbar sx={{ pl: { xs: 0,sm: 0,md: 2,},pr: { xs: 0,sm: 0,md: 2,},flexWrap:"wrap"}}>
            
                <Container sx={{flexBasis:{xs:"40%",sm:"30%",md:"25%",lg:"20%",xl:"20%"},marginLeft:0,marginRight:0,paddingRight:"0.5em!important",display:"inline-block"}}> <ComcareLogo /></Container>
                <Container sx={{flexBasis:{xs:"content",sm:"content",md:"content",lg:"content",xl:"content"},marginLeft:0,marginRight:0,paddingRight:"0em!important",paddingLeft:"0em!important",display:"inline-block"}}>
                    <Tooltip title="Go to the ORAMS homepage">
                        <Button color="inherit" component={Link} to={userTypeHomeUrl} sx={{display:"block",borderTop:"none!important",paddingBottom:"0em"}}>
                            <Typography variant="h4" component="div" sx={{paddingRight:"0em",paddingLeft:"0em",textTransform:"none",display:{ xs: "inline-block",sm: "inline-block", md: "inline-block", lg: "inline-block", xl: "inline-block"},  }}>
                                ORAMS Portal
                            </Typography>
                        </Button>
                    </Tooltip>
                    <Typography variant="h6" component="span" sx={{textTransform:"normal",display:{ xs: "inline-block",sm: "inline-block", md: "inline-block", lg: "inline-block", xl: "inline-block"}, paddingLeft:"0.5rem",flexGrow: 1, fontSize:{xs:"0.5rem",sm:"0.65rem",md:"0.75rem",lg:"1rem",xl:"1rem"},lineHeight: 'normal' }}>
                        Occupational Rehabilitation and Associated Medical Services
                    </Typography>  
                </Container>
                
            
                <Container sx={{paddingRight:"0em!important",paddingLeft:"0em!important",textAlign:"right",flexBasis:{xs:"100%",sm:"100%",md:"100%",lg:"100%",xl:"100%"}}}>
                <Tooltip title="Email ORAMS">
                <Button sx={{paddingLeft:{xs:"1.50em!important"},fontWeight:600, fontSize:{xs:"0.75rem",sm:"0.75rem",md:"0.75rem",lg:"1rem",xl:"1.25rem"},}} color="inherit" href="mailto:orams@comcare.gov.au">
                    Contact
                </Button>
                </Tooltip>
                {navigationLinks()}   
                </Container>
            </Toolbar>
            
            </Container>
            
        </AppBar>
            
    );
};