import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Link, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import * as editPriceApi from "../../api/editPrice";
import useAuth from "../../hooks/useAuth";
import { Controller, useForm } from "react-hook-form";
import ServiceEditList from "./ServiceEditList";
import PricingList from "./PricingList";
import { parseCurrency, validateCurrency, VALID_CURRENCY_PATTERN } from "../../constants/constants";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import Loading from "../Loading/Loading";

const EditPriceForm = ({ price, handleOnSubmit, handleOnSaveAndEdit }) => {
    const { control, handleSubmit, getValues } = useForm({
        reValidateMode: "onBlur"
    });
    const [ priceValidity, setPriceValidity ] = useState();

    const errorHelper = {
        newPrice: {
            required: `Price must be valid and less than $${price.capPrice}`,
            pattern: `Price must be valid and less than $${price.capPrice}`,
            validateValidPrice: `Price must be valid and less than $${price.capPrice}`,
            validateMaxPrice: `Price must be valid and less than $${price.capPrice}`,
        },
        date: {
            required: "You must select how long is the price valid for",
        },
        startDate: {
            required: "Start date is required",
            validateDate: "Start date is not a valid date",
            validateMinimumDate: "Start date must be in the future",
            validateBeforeEndDate: "The start date must be prior to the end date.",
        },
        endDate: {
            required: "End date is required",
            validateDate: "End date is not a valid date",
            validateMinimumDate: "End date must be in the future",
        },
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSaveAndEdit)}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 4,
            }}
        >
            <Alert severity="info" sx={{ mb: 2 }}>
                <Typography variant="body">
                    <b>{`Region: ${price.regionState} ${price.regionName}`}</b>
                </Typography>
                <Typography component="div" variant="body2">
                    Pricing must be a fixed fee and all inclusive of GST, travel, assessment and report. The negotiated cap
                    for each region is specified inline, you are not able to exceed.
                </Typography>
            </Alert>

            <Controller
                control={control}
                name="newPrice"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: VALID_CURRENCY_PATTERN,
                    validate: {
                        validateValidPrice: validateCurrency,
                        validateMaxPrice: (value) => !value || parseCurrency(value) <= price.capPrice,
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Enter a new price including GST"
                        id="newPrice"
                        error={error !== undefined}
                        helperText={error ? errorHelper.newPrice[error.type] : `The new price must not exceed $${price.capPrice}`}
                    />
                )}
            />

            <Controller
                render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ m: 3 }} error={error !== undefined} variant="standard">
                        <FormLabel>How long is this price valid for?</FormLabel>
                        <RadioGroup
                            {...field}
                            onChange={(e) => {
                                field.onChange(e);
                                setPriceValidity(e.target.value);
                            }}
                        >
                            <FormControlLabel
                                value='tomorrow'
                                control={
                                    <Radio
                                        size="small"
                                    />
                                }
                                label='From tomorrow onwards'
                            />
                            <FormControlLabel
                                value='custom'
                                control={
                                    <Radio
                                        size="small"
                                    />
                                }
                                label='Select a date range'
                            />
                        </RadioGroup>
                        {error !== undefined &&
                            <FormHelperText>{error ? errorHelper.date[error.type] : ""}</FormHelperText>
                        }   
                    </FormControl>
                )}
                name="date"
                control={control}
                defaultValue=""
                rules={{
                    required: true,
                }}
            />

            {priceValidity && priceValidity === 'custom' &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                        gap: 1,
                    }}
                >
                    <Controller
                        control={control}
                        name="startDate"
                        defaultValue=""
                        rules={{
                            required: true,
                            validate: {
                                validateDate: (value) => !value || dayjs(value).isValid(),
                                validateMinimumDate: (value) => dayjs(value).isAfter(dayjs()),
                                validateBeforeEndDate: (value) => !value || !getValues('endDate') || dayjs(value).isBefore(getValues('endDate'))
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker
                                {...field}
                                label="Start date"
                                name="startDate"
                                required
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        sx={{ mr: 2 }}
                                        error={error !== undefined} 
                                        helperText={error ? errorHelper.startDate[error.type] : ""}
                                    />
                                }
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="endDate"
                        defaultValue=""
                        rules={{
                            required: true,
                            validate: {
                                validateDate: (value) => !value || dayjs(value).isValid(),
                                validateMinimumDate: (value) => dayjs(value).isAfter(dayjs()),
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker
                                {...field}
                                label="End date"
                                name="endDate"
                                required
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        sx={{ mr: 4 }}
                                        error={error !== undefined} 
                                        helperText={error ? errorHelper.endDate[error.type] : ""}
                                    />
                                }
                            />
                        )}
                    />
                </Box>
            }

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    mt: 2,
                    gap: 1,
                }}
            >
                <Button
                    variant="contained"
                    type="submit"
                >
                    Save and edit another
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit(handleOnSubmit)}
                >
                    Continue to contract variation
                </Button>
            </Box>
        </Box>
    );
};

const ContractVariationForm = ({ supplier, updatedPrices, handleOnSubmit, handleOnPriceEdit }) => {
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const errorHelper = {
        agree: {
            validateValue: 'Accept Terms of Use'
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 4,
            }}
        >
            <Alert severity="info" sx={{ mb: 2 }}>
                <Typography variant="body">
                    <b>Contract variation</b>
                </Typography>
                <Typography component="div" variant="body2">
                    Check the pricing changes listed in this section before agreeing to the terms and conditions. After your
                    changes are submitted a verification document will be emailed to <strong>{supplier.email}</strong> and
                    your profile updated.
                </Typography>
            </Alert>
                {updatedPrices &&
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell>Region</TableCell>
                                <TableCell>New Price inc GST</TableCell>
                                <TableCell>Start date</TableCell>
                                <TableCell>End date</TableCell>
                                <TableCell>Reverts to</TableCell>
                                <TableCell />
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {updatedPrices.map((price) => (
                                <TableRow
                                key={price.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography component="p" variant="body2">{price.regionState + ' ' + price.regionName}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{'$' + price.price}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{dayjs(price.dateFrom).format('DD/MM/YYYY')}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{price.dateTo ? dayjs(price.dateTo).format('DD/MM/YYYY') : 'Ongoing'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="p" variant="body2">{'$' + price.capPrice}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                handleOnPriceEdit(price.id);
                                            }}
                                        >
                                            Edit
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            <Box
                component="form"
                onSubmit={handleSubmit(handleOnSubmit)}
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 4,
                }}
            >

                <Controller
                    control={control}
                    name="agree"
                    defaultValue={false}
                    rules={{
                        validate: {
                            validateValue: (value) => value
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <FormControl error={error !== undefined} variant="standard">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        size="small"
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value ? field.value : false}
                                    />
                                }
                                label={
                                    <span>
                                        I am <strong>{supplier.representative}</strong> of <strong>{supplier.name}</strong> ({supplier.abn})
                                        and I agree to the terms set out in the ORAMS agreement
                                    </span>
                                }
                            />
                            {error !== undefined &&
                                <FormHelperText>{error ? errorHelper.agree[error.type] : ""}</FormHelperText>
                            }   
                        </FormControl>
                    )}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                        mt: 2,
                    }}
                >
                    <Button type="submit" variant="contained">Update Profile</Button>
                </Box>

            </Box>
        </Box>
    );
}

export default function PricingDetails() {
    const { user, error, loading, call } = useAuth();
    const [ prices, setPrices ] = useState([]);
    const [ updatedPrices, setUpdatedPrices ] = useState([]);
    const [ step, setStep ] = useState(1);
    const [ supplier, setSupplier ] = useState();
    const [ selectedService, setSelectedService ] = useState();
    const [ selectedPrice, setSelectedPrice ] = useState();
    const [ success, setSuccess ] = useState();

    useEffect(() => {
        if (user && user.providerCode) {
            call(editPriceApi.supplier, { supplierCode: user.providerCode }, (response) => {
                setSupplier(response);
            });
        }
    },
    // eslint-disable-next-line
    [user]);

    function handleServiceSelect(serviceId, subCategoryId, serviceName, subCategoryName) {
        setSelectedService({ serviceId, subCategoryId, serviceName, subCategoryName });
        call(editPriceApi.prices, { supplierCode: user.providerCode, serviceId, subCategoryId }, (response) => {
            setPrices(response);
        });
        setStep(2);
        window.scrollTo(0, 0);
    };

    function handlePriceSelect(price) {
        setSelectedPrice(price);
        setStep(3);
        window.scrollTo(0, 0);
    };

    function handleUpdatedPrice({ newPrice, date, startDate, endDate }) {
        const updatedPrice = {
            capPrice: selectedPrice.capPrice,
            regionState: selectedPrice.regionState,
            regionName: selectedPrice.regionName,
            id: selectedPrice.id,
            price: parseFloat(newPrice),
            dateFrom: date === 'custom' ? dayjs(startDate).format('YYYY-MM-DD') : dayjs().add(1, 'd').format('YYYY-MM-DD'),
            dateTo: endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
        };
        if (updatedPrices.find(item => item.id === updatedPrice.id)) {
            setUpdatedPrices(updatedPrices.map(item => (item.id === updatedPrice.id ? { ...item, ...updatedPrice } : item)));
        } else {
            setUpdatedPrices([...updatedPrices, updatedPrice]);
        }
    }

    function handleOnContractVariation(newPrice) {
        handleUpdatedPrice(newPrice);
        setStep(4);
        window.scrollTo(0, 0);
    };

    function handleOnSaveAndEdit(newPrice) {
        handleUpdatedPrice(newPrice);
        setStep(2);
        window.scrollTo(0, 0);
    };

    function handleOnPriceEdit(priceId) {
        const newSelectedPrice = prices.find((price) => price.id === priceId);
        if (newSelectedPrice) {
            setSelectedPrice(newSelectedPrice);
        }
        setStep(3);
        window.scrollTo(0, 0);
    };

    function handleOnSubmitPrice() {
        setSuccess(undefined);
        call(editPriceApi.updatePrices, { id: supplier.code, data: { prices: updatedPrices }}, (response) => {
            setSuccess('Prices updated');
            setUpdatedPrices([]);
            setStep(1);
            window.scrollTo(0, 0);
        })
    }

    function downloadCeilingPricesReport(providerCode) {
        call(editPriceApi.ceilingPricesReport, {providerCode, noloading: true}, (response) => {
            let filename = dayjs().format('YYYY-MM-DD') + "-ceiling-prices.csv";
            const { 'content-disposition': content_disposition } = response.headers;
            if (content_disposition) {
                filename = content_disposition.split(' ')[1].split('=')[1];
            }
            const blob = new Blob([response.data], { type: 'text/csv' }) // eslint-disable-line no-undef
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // for IE
              window.navigator.msSaveOrOpenBlob(blob, filename)
            } else {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = filename
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
        });
    }

    if (loading) {
        return (
            <Loading />
        );
    }
    
    return (
        <Box
            sx={{
                ml: 8,
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            {supplier &&
                <React.Fragment>
                    {step === 1 &&
                        <React.Fragment>
                            <Typography variant="h1">Pricing</Typography>
                            <Typography variant="body">{`Step ${step} of 4`}</Typography>
                            <ServiceEditList services={supplier.currentServices} handleServiceSelect={handleServiceSelect} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                    mt: 2,
                                    gap: 1,
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        downloadCeilingPricesReport(supplier.code);
                                    }}
                                >
                                    Download ceiling prices
                                </Button>
                            </Box>
                        </React.Fragment>
                    }
                    {step === 2 &&
                        <React.Fragment>
                            <Typography variant="h1">{`Pricing for ${selectedService.serviceName}${selectedService.subCategoryName ? ` (${selectedService.subCategoryName})` : ''}`}</Typography>
                            <Typography variant="body">{`Step ${step} of 4`}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setStep(1);
                                    }}
                                >
                                    Back to services list
                                </Link>
                            </Box>
                            <PricingList prices={prices} handlePriceSelect={handlePriceSelect} />
                        </React.Fragment>
                     }
                    {step === 3 &&
                        <React.Fragment>
                            <Typography variant="h1">{`Pricing for ${selectedService.serviceName}${selectedService.subCategoryName ? ` (${selectedService.subCategoryName})` : ''}`}</Typography>
                            <Typography variant="body">{`Step ${step} of 4`}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setStep(2);
                                    }}
                                >
                                    Back to pricing information
                                </Link>
                            </Box>
                            <EditPriceForm price={selectedPrice} handleOnSubmit={handleOnContractVariation} handleOnSaveAndEdit={handleOnSaveAndEdit} />
                        </React.Fragment>
                    }
                    {step === 4 &&
                        <React.Fragment>
                            <Typography variant="h1">{`Pricing for ${selectedService.serviceName}${selectedService.subCategoryName ? ` (${selectedService.subCategoryName})` : ''}`}</Typography>
                            <Typography variant="body">{`Step ${step} of 4`}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setStep(3);
                                    }}
                                >
                                    Back to pricing information
                                </Link>
                            </Box>
                            <ContractVariationForm supplier={supplier} updatedPrices={updatedPrices} handleOnSubmit={handleOnSubmitPrice} handleOnPriceEdit={handleOnPriceEdit} />
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </Box>
    );
}
