import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Alert,Container } from '@mui/material';
import useAuth from "../hooks/useAuth";
import { Link as RouterLink } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { VALID_EMAIL_ADDRESS_PATTERN } from '../constants/constants';

function SignupPage() {
  const { error, signup } = useAuth();
  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });
  const [signupResponse, setSignupResponse] = useState();

  const errorHelper = {
    email: {
      required: "Email is required",
      pattern: "Invalid email address",
      validateValidDomain:
        <React.Fragment>
          Only Australian Government employees can request access via this link. Providers requiring access please email{' '}
          <Link href="mailto:orams@comcare.gov.au">
            orams@comcare.gov.au
          </Link>
        </React.Fragment>
    },
    name: {
      required: "Full name is required",
    },
    primaryUserEmail: {
      required:
        <React.Fragment>
          You must specify a Primary RCM. If you don't know your Primary RCM please email{' '}
          <Link href="mailto:orams@comcare.gov.au">
            orams@comcare.gov.au
          </Link>
        </React.Fragment>
      ,
      pattern: "Invalid email address",
      validateValidDomain: "Only Australian Government employees can act as Primary RCM",
    },
  };

  const handleOnSubmit = ({ name, email, primaryUserEmail }) => {
    signup(
      email,
      name,
      primaryUserEmail,
      setSignupResponse,
      <React.Fragment>
        Only Australian Government employees can request access via this link. Providers requiring access please email{' '}
        <Link href="mailto:orams@comcare.gov.au">
          orams@comcare.gov.au
        </Link>
      </React.Fragment>,
      <React.Fragment>
        A valid Primary RCM email address needs to be entered, please discuss within your agency to confirm.<br/>
        If your agency has not nominated a Primary RCM to the ORAMS Management Team or if you require further assistance, please contact{' '}
        <Link href="mailto:orams@comcare.gov.au">
          orams@comcare.gov.au
        </Link>
    </React.Fragment>,
  );
  };

  if (signupResponse) {
    return (
      <Box
        sx={{
          margin: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Alert severity="success">
          Thanks, this request has been sent for approval
        </Alert>
        <Typography variant="h2" sx={{ mt: 2 }}>
          What happens next?
        </Typography>
        <Typography component="p" variant="body2" sx={{ mb: 2 }}>
          An email has been sent to the nominated Primary RCM to approve your access.
        </Typography>
        <Typography variant="h2">
          If you are approved
        </Typography>
        <Typography component="p" variant="body2" sx={{ mb: 2 }}>
          You will receive an email to activate your account.
        </Typography>
        <Typography variant="h2">
          If you don’t receive an email within 5 working days
        </Typography>
        <Typography component="p" variant="body2" sx={{ mb: 2 }}>
          Check to see if it’s been classified as spam or contact{' '}
          <Link href="mailto:orams@comcare.gov.au">
            orams@comcare.gov.au
          </Link>
          {' '}for assistance.
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{paddingLeft:{ xs: "0px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},paddingRight:{ xs: "0px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},}}>
    <Box
      sx={{
        margin:{ xs: 3,sm: 6, md: 9, lg: 9, xl: 9},
       
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <Typography component="p" variant="body2">
        Step 1 of 3
      </Typography>
      <Typography variant="h1" sx={{ fontSize:{ xs: '1.5rem',sm: '1.5rem', md:'1.5rem', lg: '3rem', xl: '3rem'},mb: 2 }}>
        Create an account
      </Typography>
      <Typography variant="h2" sx={{fontSize:{ xs: '1.25rem',sm: '1.25rem', md:'1.35rem', lg: '2.25rem', xl: '2.25rem'}, mb: 2 }}>
        Information Security Access
      </Typography>
      <Typography component="p" variant="body" sx={{ mb: 2 }}>
        You confirm that access to this system is only for government use, and you are authorised to act on behalf of your agency.
      </Typography>
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate sx={{ mt: 1 }}>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              margin="normal"
              required
              autoFocus
              label="Full name"
              id="name"
              sx={{ width: '100%' }}
              error={error !== undefined}
              helperText={error ? errorHelper.name[error.type] : ""}
            />
          )}
        />
        <br/>
        <Controller
          control={control}
          name="email"
          defaultValue=""
          rules={{
            required: true,
            pattern: VALID_EMAIL_ADDRESS_PATTERN,
            validate: {
              validateValidDomain: (value) => !value || value.endsWith(".gov.au"),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              margin="normal"
              required
              id="email"
              label="Email Address"
              sx={{ width: '100%' }}
              error={error !== undefined}
              helperText={error ? errorHelper.email[error.type] : ""}
            />
          )}
        />
        <br/>
        <Controller
          control={control}
          name="primaryUserEmail"
          defaultValue=""
          rules={{
            required: true,
            pattern: VALID_EMAIL_ADDRESS_PATTERN,
            validate: {
              validateValidDomain: (value) => !value || value.endsWith(".gov.au"),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              margin="normal"
              required
              id="primaryUserEmail"
              label="Primary RCM Email Address"
              sx={{ width: '100%' }}
              error={error !== undefined}
              helperText={error ? errorHelper.primaryUserEmail[error.type] : ""}
            />
          )}
        />
        <br/>
        <Typography component="p" variant="body2" sx={{ mt: 2 }}>
          By creating an account you confirm your acceptance of our{' '}
          <RouterLink to="/terms-of-use">
            Terms of Use
          </RouterLink>
        </Typography>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Create your account
        </Button>
      </Box>
    </Box>
    </Container>
  );
}

export default SignupPage;