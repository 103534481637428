import { Grid, Typography } from "@mui/material";
import React from "react";
import { getAddressText } from "./InjuredEmployeeDetails";

const TreatingDoctorDetails = ({ referral }) => {
    const { detail } = referral;
    const {
        treatingDoctorName: doctorName,
        treatingDoctorAddress: doctorAddress,
        treatingDoctorAddressSuburb: doctorSuburb,
        treatingDoctorAddressState: doctorState,
        treatingDoctorAddressPostcode: doctorPostcode,
        treatingDoctorPhone: doctorPhone,
        treatingDoctorFax: doctorFax
    } =  detail || {};
  
    const addressText = getAddressText({
      addressLine: doctorAddress,
      suburb: doctorSuburb,
      state: doctorState,
      postcode: doctorPostcode
    });
  
    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">Treating doctor details</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{doctorName}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Address</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{addressText}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Phone</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{doctorPhone}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Fax</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{doctorFax}</Typography>
            </Grid>
        </Grid>
    );

};

export default TreatingDoctorDetails;
