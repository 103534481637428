import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useAuth from "../hooks/useAuth";
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Alert, AlertTitle, Container } from '@mui/material';
import { VALID_EMAIL_ADDRESS_PATTERN } from '../constants/constants';

function LoginPage() {
  const { error, login, sessionExpired } = useAuth();
  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });

  const errorHelper = {
    email: {
      required: "Email is required",
      pattern: "Invalid email address"
    },
    password: {
      required: "Password is required",
    },
  };

  const handleOnSubmit = ({ email, password }) => {
    login(
      email,
      password
    );
  };

  return (
    <Container maxWidth="xl" sx={{display:"flex",flexDirection:{xs:"column",sm:"column",md:"column",lg:"row",xl:"row"},paddingLeft:{ xs: "16px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},paddingRight:{ xs: "16px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},}}>
      <Container maxWidth="xl" sx={{paddingLeft:{ xs: "16px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},paddingRight:{ xs: "16px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},}}>
      <Box
        sx={{
          margin:{ xs: 3,sm: 6, md: 9, lg: 9, xl: 9},
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        
        }}
      >
        {sessionExpired &&
          <Alert severity="error">
            <AlertTitle>Session has expired</AlertTitle>
            Please sign in again.
          </Alert>
        }
        {error &&
          <Alert severity="error">
            <AlertTitle>There was a problem signing in</AlertTitle>
            Make sure you've entered the right email address and password. Accounts are locked after 5 failed attempts.
          </Alert>
        }
        <Typography component="h1" variant="h1" sx={{ fontSize:{ xs: '1.5rem',sm: '1.5rem', md:'1.5rem', lg: '3rem', xl: '3rem'},mb: 2 }}>
          Sign in to ORAMS
        </Typography>
        <Typography component="p" variant="body2">
          New to ORAMS?{` `}
          <Link to="/signup">
            Create your account
          </Link>
        </Typography>
        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate sx={{ mt: 1 }}>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            rules={{
              required: true,
              pattern: VALID_EMAIL_ADDRESS_PATTERN
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                required
                id="email"
                label="Email address"
                autoFocus
                sx={{ width: '100%' }}
                error={error !== undefined}
                helperText={error ? errorHelper.email[error.type] : ""}
              />
            )}
          />
          <br/>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                required
                label="Password"
                type="password"
                id="password"
                sx={{ width: '100%' }}
                error={error !== undefined}
                helperText={error ? errorHelper.password[error.type] : ""}
              />
            )}
          />
          <br/>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Typography component="p" variant="body2" sx={{ mb: 2 }}>
            <Link to="/reset-password">
              Forgot password?
            </Link>
          </Typography>
        </Box>
      </Box>
      </Container>
      <Box component="div" sx={{flexGrow:1, margin:{ xs: 3,sm: 6, md: 9, lg: 9, xl: 9} ,marginTop:{ xs: "0px",sm: "0px", md: "0px", lg: "72px", xl: "72px"},}}>
          <Container sx={{paddingLeft:{ xs: "0px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},paddingRight:{ xs: "0px",sm: "0px", md: "0px", lg: "1rem", xl: "1rem"},}}>
            <Typography component="h2" variant="h2" sx={{fontSize:{ xs: '1.25rem',sm: '1.25rem', md:'1.35rem', lg: '2.25rem', xl: '2.25rem'},}}>
              About ORAMS
            </Typography>
            <Typography component="p" variant="body" sx={{padding:1,}}>
              The Occupational Rehabilitation and Medical Services (ORAMS) Provider Panel is comprised of qualified and specialist medical and rehabilitation service providers.
            </Typography>
            <Typography component="p" variant="body" sx={{padding:1,}}>
              The Panel is supported by the ORAMS Portal which allows Rehabilitation Case Managers (RCMs) to quickly find a qualified medical or rehabilitation provider in a particular location.
            </Typography>
            <Typography component="p" variant="body" sx={{padding:1,}}>
              RCMs can search the ORAMS Portal for providers by service type, price and location. RCMs can compare pricing between service providers, and create referrals for Commonwealth employees.
            </Typography>
            <Typography component="p" variant="body" sx={{padding:1,}}>
              ORAMS providers can also use the portal to manage their business details and adjust their daily pricing (to any amount under the relevant price cap), as well as respond to referral requests.
            </Typography>
            <Typography component="p" variant="body" sx={{padding:1,}}>
              The Portal and the Panel arrangements are managed by the Comcare ORAMS team. For help, email{' '}
              <Link href="mailto:orams@comcare.gov.au" variant="body">
                orams@comcare.gov.au
              </Link>
            </Typography>
          </Container>
        </Box>
    </Container>
  );
}

export default LoginPage;