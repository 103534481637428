import { Button, Chip, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import { REFERRAL_STATUSES } from "../../constants/referralConstants";

const STATUS_MAP = {
    accepted: { chipLabel: "Accepted", chipColour: "info", chipVariant: "filled" },
    cancelled: { chipLabel: "Cancelled", chipColour: "primary", chipVariant: "outlined" },
    rejected: { chipLabel: "Rejected", chipColour: "error", chipVariant: "filled" },
    completed: { chipLabel: "Completed", chipColour: "primary", chipVariant: "filled" },
    sentForPayment: { chipLabel: "Completed", chipColour: "primary", chipVariant: "filled" }
}

function renderStatus(status, dateCreated, isFeeHourly) {
    let chipLabel = "";
    let chipColour = "primary";
    let chipVariant = "filled";

    if (status === REFERRAL_STATUSES.CREATED) {
        if (dayjs().diff(dayjs(dateCreated), 'h') >= 24) {
            chipLabel = "Overdue";
            chipColour = "error";
        } else {
            chipLabel = "Pending";
            chipColour = "warning";
        }
    } else {
        chipLabel = STATUS_MAP[status].chipLabel
        chipColour = STATUS_MAP[status].chipColour
        chipVariant = STATUS_MAP[status].chipVariant
    }

    return (
        <Chip label={chipLabel} color={chipColour} variant={chipVariant} />
    )
};

const VIEW_ACTION = { name: "view", render: (referralId) => {
    return (
        <Button component={RouterLink} to={`/referrals/${referralId}`}>View</Button>
    );
}};
const RESPOND_ACTION = { name: "respond", render: (referralId) => {
    return (
        <Button component={RouterLink} to={`/referrals/${referralId}`}>Respond</Button>
    );
}};

const ACTIONS_MAP = {
    created: [RESPOND_ACTION],
    accepted: [VIEW_ACTION],
    cancelled: [VIEW_ACTION],
    rejected: [VIEW_ACTION],
    completed: [VIEW_ACTION],
    completedForHourlyFee: [VIEW_ACTION],
    sentForPayment: [VIEW_ACTION]
}

function renderActions(referral) {
    const { status, id: referralId, isFeeHourly } = referral
    const statusKey = status === REFERRAL_STATUSES.COMPLETED && isFeeHourly ? "completedForHourlyFee" : status

    return ACTIONS_MAP[statusKey].map(item => {
        return (
            <Typography key={item.name}>
                {item.render(referralId)}
            </Typography>
        );
    })
}

const SupplierDashboard = (props) => {
    const { referrals, statusFilter, sort } = props;
    return (
        <DashboardTable referrals={referrals} statusFilter={statusFilter} sort={sort} renderStatus={renderStatus} renderActions={renderActions} />
    );
};

export default SupplierDashboard;