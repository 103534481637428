import { Alert, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import useAuth from "../hooks/useAuth";

export default function InvitationPage() {
    const { invite, error } = useAuth();
    const { token } = useParams();
    const [ searchParams ] = useSearchParams();
    const emailAddress = searchParams.get("e");
    const [ invitation, setInvitation ] = useState();

    useEffect(() => {
        invite(token, emailAddress, (response) => {
            setInvitation(response);
        });
    },
    // eslint-disable-next-line
    [ token, emailAddress ]);

    return (
        <React.Fragment>
            {error
                ? <Box
                    sx={{
                        margin: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <Alert severity="error">{error}</Alert>
                </Box>
                : invitation
                    ?
                        <Box
                            sx={{
                                margin: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            <Alert severity="success">ORAMS access has been given to {invitation.email}</Alert>
                            <Typography variant="body">What happens next?</Typography>
                            <Typography variant="body2">We have sent an email to {invitation.email} to set a password and activate their account.</Typography>
                        </Box>
                    : <Loading />
            }
        </React.Fragment>
    );
}