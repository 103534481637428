import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Container,FormControl, FormControlLabel, Grid, Link, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import * as sellerCatalogApi from "../api/sellerCatalog";
import { Link as RouterLink } from 'react-router-dom';
import * as referralApi from "../api/referrals";
import Loading from "../components/Loading/Loading";
import { serviceAndSubServiceId, serviceId, subServiceId } from "../constants/constants";

function CatalogPriceTable(props) {
    const { prices } = props;

    return (
        <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }}>
                <TableBody>
                {prices && prices.map((price) => (
                    <TableRow
                        key={price.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>
                            <Typography><RouterLink to={`/seller-profile/${price.provider.code}`}>{price.provider.name}</RouterLink></Typography>
                            <Typography component="p" variant="body2">{price.provider.contactPhone}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography component="p" variant="body2">
                                <Link href={`mailto:${price.provider.contactEmail}`}>{price.provider.contactEmail}</Link>
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            {price.serviceType
                            && price.serviceType.feeType
                            && price.serviceType.feeType !== 'Negotiated'
                            &&
                                <React.Fragment>
                                    <Typography variant="subtitle1" sx={{fontWeight:700}}>${price.price}</Typography>
                                    <Typography component="p" variant="body2">inc GST</Typography>
                                </React.Fragment>
                            }
                            <Button variant="contained" component={RouterLink} to={`/referral-builder/${price.id}`}>Send Referral</Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default function SellerCatalogPage() {
    const { error, success, setSuccess, loading, call, contextMap, clearContext } = useAuth();
    const [ regions, setRegions ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ selectedRegion, setSelectedRegion ] = useState();
    const [ selectedCategory, setSelectedCategory ] = useState();
    const [ suppliers, setSuppliers ] = useState([]);
  
    function clearReferralContext() {
        clearContext('personalDetails');
        clearContext('serviceDetails');
        clearContext('delegateInfo');
    }

    useEffect(() => {
        clearReferralContext();
        call(sellerCatalogApi.regions, {}, setRegions);
        call(sellerCatalogApi.services, {}, setCategories);
    },
    // eslint-disable-next-line
    []);

    useEffect(() => {
        if (contextMap && contextMap.get('priceId')) {
            call(referralApi.infoByPrice, { id: contextMap.get('priceId') }, (response) => {
                setSelectedRegion(response.regionId);
                setSelectedCategory(response.serviceId);
                loadSuppliers(response.regionId, response.serviceId);
            });
        }
    },
    // eslint-disable-next-line
    [contextMap]);

    function loadSuppliers(region, serviceAndSubService) {
        if (region && serviceAndSubService) {
            const service = serviceId(serviceAndSubService);
            const subService = subServiceId(serviceAndSubService);
            call(sellerCatalogApi.suppliers, { region, service, subService }, setSuppliers);
        }
    };

    function handleRegionChange(event) {
        setSuccess(undefined);
        setSelectedRegion(event.target.value);
        loadSuppliers(event.target.value, selectedCategory);
    };

    function handleCategoryChange(event) {
        setSuccess(undefined);
        setSelectedCategory(event.target.value);
        loadSuppliers(selectedRegion, event.target.value);
    };
    
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h1" sx={{ mb: 2 }}>
                Service Matrix
            </Typography>
            <Typography component="p"  sx={{ mb: 2 }}>
                Select a location and service category to view pricing.
            </Typography>
            <Typography variant="h2" sx={{ mb: 2 }}>
                Regions and Locations
                </Typography>
                <Typography component="p" sx={{ mb: 2 }}>
                 Download{' '}
                <Link rel="external" target="_blank" href={process.env.PUBLIC_URL + '/media/documents/orams-locations.csv'}>
                ORAMS locations
                </Link>
                {' '} [CSV 17 KB]
            </Typography>
            <Typography variant="h2" sx={{ mb: 2 }}>
            Services
                </Typography>
            <Typography component="p" variant="body1" sx={{ mb: 2 }}>
                Select the most appropriate service for the injured worker every time. 
                To review each service description: the activities, service standards and performance expectations download the{' '}
                <Link rel="external" target="_blank" href={process.env.PUBLIC_URL + '/media/documents/Service Requirements .pdf'}>
                    ORAMS Service Requirements
                </Link>{' '}[PDF 509 KB]
            </Typography>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        1. Select region
                    </Typography>
                    <FormControl>
                    <RadioGroup
                        name="region"
                        value={selectedRegion ? selectedRegion : ''}
                        onChange={handleRegionChange}
                    >
                        {regions && regions.map((region) =>
                            <Accordion key={region.name}>
                                <AccordionSummary>
                                    <Typography>{region.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {region.regions.map((subRegion) =>
                                        <FormControlLabel key={subRegion.id} value={subRegion.id} control={<Radio size="small"/>} label={<Typography variant="body2">{subRegion.name}</Typography>} />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </RadioGroup>
                    </FormControl>
                    <Typography variant="h3" sx={{ mb: 2, mt: 2 }}>
                        2. Select a category
                    </Typography>
                    <FormControl>
                    <RadioGroup
                        name="service"
                        value={selectedCategory ? selectedCategory : ''}
                        onChange={handleCategoryChange}
                    >
                        {categories && categories.map((category) =>
                            <Accordion key={category.name}>
                                <AccordionSummary>
                                    <Typography>{category.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {category.services.map((service) => {
                                        if (service.currentSubTypes && service.currentSubTypes.length > 1) {
                                            return (
                                                <React.Fragment>
                                                    <FormControlLabel disabled key={service.id} value={service.id} control={<Radio size="small"/>} label={<Typography variant="body2">{service.name}</Typography>} />
                                                    {service.currentSubTypes.map((subService) => {
                                                        if (subService.name) {
                                                            return (<FormControlLabel key={serviceAndSubServiceId(service.id, subService.id)} value={serviceAndSubServiceId(service.id, subService.id)} control={<Radio size="small"/>} label={<Typography variant="body2">{subService.name}</Typography>} sx={{ ml: 5 }} />);
                                                        }
                                                        return null;
                                                    })}
                                                </React.Fragment>
                                            );
                                        }
                                        else {
                                            return (
                                                <FormControlLabel key={serviceAndSubServiceId(service.id, service.currentSubTypes[0].id)} value={serviceAndSubServiceId(service.id, service.currentSubTypes[0].id)} control={<Radio size="small"/>} label={<Typography variant="body2">{service.name}</Typography>} />
                                            );
                                        }
                                    }

                                    )}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    {loading
                        ? <Loading />
                        :
                            <React.Fragment>
                                {error && <Alert severity="error">{error}</Alert>}
                                {success && <Alert severity="success">{success}</Alert>}
                                {suppliers.alert &&
                                    <Alert severity={suppliers.alert.type} sx={{ maxWidth: 650}}>{suppliers.alert.message}</Alert>
                                }
                                {suppliers.items && suppliers.items.map((category, index) => (
                                    <CatalogPriceTable key={index} prices={category.prices} />
                                ))
                                }
                            </React.Fragment>
                    }
                </Grid>
            </Grid>
        </Box>
        </Container>

    );
};