import { Box, Link, Typography, Container } from "@mui/material";
import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { HashLink } from 'react-router-hash-link';

export default function SecurityStatementPage() {
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
                Security statement
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                The Occupational Rehabilitation and Associated Medical Services (ORAMS) Portal is being 
                delivered to make it easier for government and ORAMS panel providers to work together. We
                value transparency while also taking security seriously. This document describes the practices
                we employ to help ensure the security of our users’ data.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                We apply a range of security controls to protect our websites from unauthorised access.
                However, you should be aware that:
            </Typography>
            <List sx = {{listStyleType: 'disc', pl: 4, '& .MuiListItem-root': {display: 'list-item',},}}>
                <Typography component="p" variant="body">
                    <ListItem>the World Wide Web is an insecure public network</ListItem>
                </Typography>
                <Typography component="p" variant="body">
                    <ListItem>there is a risk that your transactions may be seen, intercepted or modified by third parties</ListItem>
                </Typography>
                <Typography component="p" variant="body">
                    <ListItem>downloadable files may contain computer viruses, disabling codes, worms or other devices or
                        defects.</ListItem>
                </Typography>
            </List>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                The Commonwealth accepts no liability for any interference with or damage to your computer
                system, software or data occurring in connection with, or relating to, this website or its use.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                You should take appropriate and adequate precautions to prevent damage to your computer
                system, software or data. See how by visiting <Link href="https://www.staysmartonline.gov.au/protect-yourself" target="_blank" rel="noreferrer">
                Stay Smart Online 
                </Link>.
            </Typography>
            <Typography component="h2" variant="h2" sx={{ mb: 0 , mt: 2}}>
                Raising Issues
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Whether it be a bug, feature request or security concern our team is ready to respond in a
                timely manner to all issues. You can email us at <a href="mailto:orams@comcare.gov.au">orams@comcare.gov.au</a>.
            </Typography>
            <Typography component="h2" variant="h2" sx={{ mb: 0 , mt: 2 }}>
                Monitoring
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <Link to="#">Back to top of page ↑</Link>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                The ORAMS Portal is continually monitored by a range of tools that immediately notify the
                team of any errors or changes to performance that could affect the ORAMS Portal.
            </Typography>
            <Typography component="h2" variant="h2" sx={{ mb: 0 , mt: 2 }}>
                Feedback
            </Typography>
            <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                <HashLink to="#">Back to top of page ↑</HashLink>
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                The ORAMS Portal is an agile development, which means it evolves in response to the needs of
                its users. If you have any feedback or questions relating to this document (or any other ORAMS
                matters) email <a href="mailto:orams@comcare.gov.au">orams@comcare.gov.au</a>.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mt: 2 }}>
                Last updated: 14/01/2021
            </Typography>
        </Box>
        </Container>
    );
}