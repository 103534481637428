import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Link as RouterLink } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

export default function CreatePasswordPage() {
    const { error, success, setSuccess, createUser } = useAuth();
    const { token } = useParams();
    const [ searchParams ] = useSearchParams();
    const emailAddress = searchParams.get("e");
    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const errorHelper = {
        password: {
            required: "Password is required",
            minLength: "Password should be at least 10 characters ",
        },
        agree: {
            validateValue: "Accept Terms of Use",
        }
    }

    function handleOnSubmit({ password }) {
        setSuccess(undefined);
        createUser(token, emailAddress, password, (response) => {
            setSuccess("Account activated");
        });
    }

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {success &&
                <Alert severity="success">
                    Success, your account has been activated. Please{' '}
                    <RouterLink to="/login">
                        Login
                    </RouterLink>
                </Alert>
            }
            {error && <Alert severity="error">There was a problem activating your account</Alert>}
            <Typography variant="body">{`Step 3 of 3`}</Typography>
            <Typography variant="h2">Create a password</Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(handleOnSubmit)}
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    width: '75ch',
                    gap: 1,
                    mt: 2,
                }}
            >
                <Controller
                    control={control}
                    name="password"
                    defaultValue=""
                    rules={{
                        required: true,
                        minLength: 10,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            required
                            type="password"
                            label="Password"
                            id="password"
                            fullWidth 
                            error={error !== undefined}
                            helperText={error ? errorHelper.password[error.type] : "At least 10 characters"}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="agree"
                    defaultValue={false}
                    rules={{
                        validate: {
                            validateValue: (value) => value
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <FormControl error={error !== undefined} variant="standard">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        size="small"
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value ? field.value : false}
                                    />
                                }
                                label={
                                    <Typography>
                                        I accept the{' '}
                                        <RouterLink to="/terms-of-use">
                                            Terms of Use
                                        </RouterLink>
                                    </Typography>
                                }
                            />
                            {error !== undefined &&
                                <FormHelperText>{error ? errorHelper.agree[error.type] : "The terms of use"}</FormHelperText>
                            }   
                        </FormControl>
                    )}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'left',
                        gap: 1,
                        mt: 2,
                    }}
                >
                    <Button type="submit" variant="contained">Activate your account</Button>
                </Box>
            </Box>
        </Box>
    );
}