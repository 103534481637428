import { Grid, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { REFERRAL_TYPE_COMPENSATION } from "../../constants/referralConstants";

const ComcareClaimDetails = ({ referral }) => {
    const { detail, type, serviceTypeName = "", serviceSubTypeName = "" } = referral;
    const { dateOfInjury, injuryDetails } = detail || {};
    const { comcareClaimNumber, liabilityDetermined: isLiabilityDetermined } = detail || {};

    const isCompensation = type === REFERRAL_TYPE_COMPENSATION;
    const sectionHeading = isCompensation ? 'Comcare claim details' : 'Injury details';
    const assessmentServices = serviceSubTypeName ? `${serviceTypeName}\n${serviceSubTypeName}` : serviceTypeName;
    const dateOfInjuryText = dateOfInjury ? dayjs(dateOfInjury).format('DD MMM YYYY') : '';
    
    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{sectionHeading}</Typography>
            </Grid>
            {isCompensation &&
                <React.Fragment>
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Claim number</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography component="p" variant="body2">{comcareClaimNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Liability for compensation determined?</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography component="p" variant="body2">{isLiabilityDetermined}</Typography>
                    </Grid>
                </React.Fragment>
            }
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Date of injury</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{dateOfInjuryText}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Nature of injury</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{injuryDetails}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Assessment services requested</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{assessmentServices}</Typography>
            </Grid>
        </Grid>
    );

};

export default ComcareClaimDetails;
