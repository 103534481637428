import { Grid, Typography } from "@mui/material";
import React from "react";
import { REFERRAL_TYPE_COMPENSATION } from "../../constants/referralConstants";

const RCMDetails = ({ referral }) => {
    const { detail, type } = referral;
    const {
      delegateSalutation = "",
      delegateGivenName = "",
      delegateSurname: delegateLastName = "",
      delegatePosition,
      rcmAgsNumber,
      rcmPhone,
      rcmFax,
      rcmEmail,
      employerAgency,
      agencyReferralId,
      caseNotes
    } = detail || {};
  
    const delegateName = `${delegateSalutation} ${delegateGivenName} ${delegateLastName}`;
  
    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">Rehabilitation case manager</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">AGS number</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{rcmAgsNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{delegateName}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Organisation</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employerAgency}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Agency referral ID</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{agencyReferralId}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Position</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{delegatePosition}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Phone</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{rcmPhone}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Fax</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{rcmFax}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Email</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{rcmEmail}</Typography>
            </Grid>
            {type !== REFERRAL_TYPE_COMPENSATION &&
                <React.Fragment>
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Case notes</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography component="p" variant="body2">{caseNotes}</Typography>
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    );

};

export default RCMDetails;
