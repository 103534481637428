import useAuth from "./useAuth";

const API = `${process.env.REACT_APP_DOCUMENTS_URL}/files`;
const QUERY = `${process.env.REACT_APP_DOCUMENTS_QUERY}`.replaceAll("\"", "");

const documentsMap = {
  buyer: [
    {
      groupName: 'Operational documents',
      documents: [
        {
          key: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)',
          name: 'EXECUTION VERSION - Head Agreement for the provision of ORAMS.pdf',
          text: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)'
        },
        {
          key: 'Application of the Head Agreement - Questions and Answers for providers and agencies',
          name: 'Questions and Answers on the ORAMS Head Agreement.docx',
          text: 'Application of the Head Agreement - Questions and Answers for providers and agencies'
        },
        {
          key: 'ORAMS Service Requirements',
          name: 'Service Requirements .pdf',
          text: 'ORAMS Service Requirements'
        },
        {
          key: 'Universal Operations Manual',
          name: 'UOM Manual WORD FINAL.docx',
          text: 'Universal Operations Manual'
        },
        {
          key: 'Work Order for Rehabilitation services',
          name: 'Work Order - Rehabilitation Services.docx',
          text: 'Work Order for Rehabilitation services'
        },
        {
          key: 'Work Order for Medical services',
          name: 'Work Order - Medical Services.docx',
          text: 'Work Order for Medical services'
        },
        {
          key: 'Model Agency Services Manual',
          name: 'Model Agency Services Manual - 28 Nov 23.docx',
          text: 'Model Agency Services Manual'
        },
        {
          key: 'Provider Region Matrix',
          name: 'Provider Region Matrix.pdf',
          text: 'Provider Region Matrix'
        },
      ]
    },
    {
      groupName: 'Portal guidance and procedures',
      documents: [
        {
          key: 'Privacy and Consent guidance',
          name: 'ORAMS Privacy and Consent guidance.pdf',
          text: 'Privacy and Consent guidance'
        },
        {
          key: 'Employee consent form (for the ORAMS Portal)',
          name: 'Employee Consent request form.pdf',
          text: 'Employee consent form (for the ORAMS Portal)'
        },
        {
          key: 'ORAMS Portal - Agency (RCM and Primary RCM) user functions and requirements',
          name: 'ORAMS - Agency (Primary RCM and RCM) user functions and requirements_Feb2024.pdf',
          text: 'ORAMS Portal - Agency (RCM and Primary RCM) user functions and requirements'
        },
        {
          key: 'Invoice Datasheet template',
          name: 'Invoice Datasheet Template.xlsx',
          text: 'Invoice Datasheet template'
        },
      ]
    },
    {
      groupName: 'Portal Reporting Templates',
      subGroups: [
        {
          subGroupName: 'Reporting Templates',
          documents: [
            {
              key: '1. Offsite Assessment Report Template',
              name: 'ORAMS - Offsite Assessment Report template - 24 Nov 2023.docx',
              text: '1. Offsite Assessment Report Template'
            },
            {
              key: '2. Rehabilitation Assessment Report template - compensable',
              name: 'ORAMS - Rehabilitation Assessment (compensable) template - 24 Nov 2023.docx',
              text: '2. Rehabilitation Assessment Report template - compensable'
            },
            {
              key: '3. Rehabilitation Assessment Report template - non compensable',
              name: 'ORAMS - Rehabilitation Assessment (non-compensable) Report template - 24 Nov 2023.docx',
              text: '3. Rehabilitation Assessment Report template - non compensable'
            },
            {
              key: '4. Rehabilitation Program Closure Report Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (Non-Compensable) Closure Report Template - 24 Nov 2023 .docx',
              text: '4. Rehabilitation Program Closure Report Template - non compensable'
            },
            {
              key: '5. Rehabilitation Program Progress Report Template',
              name: 'ORAMS - Rehabilitation Program Progress Report template - 24 Nov 2023.docx',
              text: '5. Rehabilitation Program Progress Report Template'
            },
            {
              key: '6. Rehabilitation Program Funding Request Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (non-compensable) Funding Request template - 24 Nov 2023.docx',
              text: '6. Rehabilitation Program Funding Request Template - non compensable'
            },
          ]
        },
        {
          subGroupName: 'Off Portal Referral Forms',
          documents: [
            {
              key: '1. Combined rehab assessment and fitness for duty examination',
              name: '1. ORAMS off portal referral form - combined rehab assessment and FFD .docx',
              text: '1. Combined rehab assessment and fitness for duty examination'
            },
            {
              key: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination',
              name: '3. ORAMS off portal referral form - rehab assessment, pre employment, pre-deployment.docx',
              text: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination'
            },
            {
              key: '3. Health Advisory Services and Specialist Audiometry Testing',
              name: '5. ORAMS off-portal form - Health Advisory Services and Specialist Audiometry Testing.docx',
              text: '3. Health Advisory Services and Specialist Audiometry Testing'
            },
            {
              key: '4. Neuropsychological assessment',
              name: '6. ORAMS off portal referral form - Neuropsychological assessment.docx',
              text: '4. Neuropsychological assessment'
            },
            {
              key: '5. Rehabilitation assessments',
              name: '7. ORAMS off portal referral form - compensable and non-compensable rehabilitation assessment.docx',
              text: '5. Rehabilitation assessments'
            },
            {
              key: '6. Rehabilitation program',
              name: '8. ORAMS off portal referral form - rehabilitation program.docx',
              text: '6. Rehabilitation program'
            },
            {
              key: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment',
              name: '9. ORAMS off portal referral form - ergonomic and functional evaluation, vocational, work capacity, offsite assessment.docx',
              text: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment'
            },
            {
              key: '8. Supplementary report or review (medical and rehabilitation)',
              name: '4. ORAMS off portal referral form - Supplementary reports.docx',
              text: '8. Supplementary report or review (medical and rehabilitation)'
            },
          ]
        }

      ]
    },
    {
      groupName: 'Meeting minutes and ORAMS calendar',
      documents: [
        {
          key: 'Record of meeting log - Agency Quarterly Forum',
          name: 'Record of meeting log - Agency Quarterly Forum.docx',
          text: 'Record of meeting log - Agency Quarterly Forum'
        },
        {
          key: 'ORAMS Calendar',
          name: 'ORAMS Calendar.xlsx',
          text: 'ORAMS Calendar'
        },
      ]
    },
  ],
  'primary-user': [
    {
      groupName: 'Operational documents',
      documents: [
        {
          key: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)',
          name: 'EXECUTION VERSION - Head Agreement for the provision of ORAMS.pdf',
          text: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)'
        },
        {
          key: 'Application of the Head Agreement - Questions and Answers for providers and agencies',
          name: 'Questions and Answers on the ORAMS Head Agreement.docx',
          text: 'Application of the Head Agreement - Questions and Answers for providers and agencies'
        },
        {
          key: 'ORAMS Service Requirements',
          name: 'Service Requirements .pdf',
          text: 'ORAMS Service Requirements'
        },
        {
          key: 'Universal Operations Manual',
          name: 'UOM Manual WORD FINAL.docx',
          text: 'Universal Operations Manual'
        },
        {
          key: 'Work Order for Rehabilitation services',
          name: 'Work Order - Rehabilitation Services.docx',
          text: 'Work Order for Rehabilitation services'
        },
        {
          key: 'Work Order for Medical services',
          name: 'Work Order - Medical Services.docx',
          text: 'Work Order for Medical services'
        },
        {
          key: 'Model Agency Services Manual',
          name: 'Model Agency Services Manual - 28 Nov 23.docx',
          text: 'Model Agency Services Manual'
        },
        {
          key: 'Provider Region Matrix',
          name: 'Provider Region Matrix.pdf',
          text: 'Provider Region Matrix'
        },
      ]
    },
    {
      groupName: 'Portal guidance and procedures',
      documents: [
        {
          key: 'Privacy and Consent guidance',
          name: 'ORAMS Privacy and Consent guidance.pdf',
          text: 'Privacy and Consent guidance'
        },
        {
          key: 'Employee consent form (for the ORAMS Portal)',
          name: 'Employee Consent request form.pdf',
          text: 'Employee consent form (for the ORAMS Portal)'
        },
        {
          key: 'ORAMS Portal - Agency (RCM and Primary RCM) user functions and requirements',
          name: 'ORAMS - Agency (Primary RCM and RCM) user functions and requirements_Feb2024.pdf',
          text: 'ORAMS Portal - Agency (RCM and Primary RCM) user functions and requirements'
        },
        {
          key: 'Invoice Datasheet template',
          name: 'Invoice Datasheet Template.xlsx',
          text: 'Invoice Datasheet template'
        },
      ]
    },
    {
      groupName: 'Portal Reporting Templates',
      subGroups: [
        {
          subGroupName: 'Reporting Templates',
          documents: [
            {
              key: '1. Offsite Assessment Report Template',
              name: 'ORAMS - Offsite Assessment Report template - 24 Nov 2023.docx',
              text: '1. Offsite Assessment Report Template'
            },
            {
              key: '2. Rehabilitation Assessment Report template - compensable',
              name: 'ORAMS - Rehabilitation Assessment (compensable) template - 24 Nov 2023.docx',
              text: '2. Rehabilitation Assessment Report template - compensable'
            },
            {
              key: '3. Rehabilitation Assessment Report template - non compensable',
              name: 'ORAMS - Rehabilitation Assessment (non-compensable) Report template - 24 Nov 2023.docx',
              text: '3. Rehabilitation Assessment Report template - non compensable'
            },
            {
              key: '4. Rehabilitation Program Closure Report Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (Non-Compensable) Closure Report Template - 24 Nov 2023 .docx',
              text: '4. Rehabilitation Program Closure Report Template - non compensable'
            },
            {
              key: '5. Rehabilitation Program Progress Report Template',
              name: 'ORAMS - Rehabilitation Program Progress Report template - 24 Nov 2023.docx',
              text: '5. Rehabilitation Program Progress Report Template'
            },
            {
              key: '6. Rehabilitation Program Funding Request Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (non-compensable) Funding Request template - 24 Nov 2023.docx',
              text: '6. Rehabilitation Program Funding Request Template - non compensable'
            },
          ]
        },
        {
          subGroupName: 'Off Portal Referral Forms',
          documents: [
            {
              key: '1. Combined rehab assessment and fitness for duty examination',
              name: '1. ORAMS off portal referral form - combined rehab assessment and FFD .docx',
              text: '1. Combined rehab assessment and fitness for duty examination'
            },
            {
              key: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination',
              name: '3. ORAMS off portal referral form - rehab assessment, pre employment, pre-deployment.docx',
              text: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination'
            },
            {
              key: '3. Health Advisory Services and Specialist Audiometry Testing',
              name: '5. ORAMS off-portal form - Health Advisory Services and Specialist Audiometry Testing.docx',
              text: '3. Health Advisory Services and Specialist Audiometry Testing'
            },
            {
              key: '4. Neuropsychological assessment',
              name: '6. ORAMS off portal referral form - Neuropsychological assessment.docx',
              text: '4. Neuropsychological assessment'
            },
            {
              key: '5. Rehabilitation assessments',
              name: '7. ORAMS off portal referral form - compensable and non-compensable rehabilitation assessment.docx',
              text: '5. Rehabilitation assessments'
            },
            {
              key: '6. Rehabilitation program',
              name: '8. ORAMS off portal referral form - rehabilitation program.docx',
              text: '6. Rehabilitation program'
            },
            {
              key: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment',
              name: '9. ORAMS off portal referral form - ergonomic and functional evaluation, vocational, work capacity, offsite assessment.docx',
              text: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment'
            },
            {
              key: '8. Supplementary report or review (medical and rehabilitation)',
              name: '4. ORAMS off portal referral form - Supplementary reports.docx',
              text: '8. Supplementary report or review (medical and rehabilitation)'
            },
          ]
        }
      ]
    },
    {
      groupName: 'Meeting minutes and ORAMS calendar',
      documents: [
        {
          key: 'Record of meeting log - Agency Quarterly Forum',
          name: 'Record of meeting log - Agency Quarterly Forum.docx',
          text: 'Record of meeting log - Agency Quarterly Forum'
        },
        {
          key: 'ORAMS Calendar',
          name: 'ORAMS Calendar.xlsx',
          text: 'ORAMS Calendar'
        },
      ]
    },
  ],
  supplier: [
    {
      groupName: 'Operational documents',
      documents: [
        {
          key: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)',
          name: 'EXECUTION VERSION - Head Agreement for the provision of ORAMS.pdf',
          text: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)'
        },
        {
          key: 'Application of the Head Agreement - Questions and Answers for providers and agencies',
          name: 'Questions and Answers on the ORAMS Head Agreement.docx',
          text: 'Application of the Head Agreement - Questions and Answers for providers and agencies'
        },
        {
          key: 'ORAMS Service Requirements',
          name: 'Service Requirements .pdf',
          text: 'ORAMS Service Requirements'
        },
        {
          key: 'Universal Operations Manual',
          name: 'UOM Manual WORD FINAL.docx',
          text: 'Universal Operations Manual'
        },
        {
          key: 'Work Order for Rehabilitation services',
          name: 'Work Order - Rehabilitation Services.docx',
          text: 'Work Order for Rehabilitation services'
        },
        {
          key: 'Work Order for Medical services',
          name: 'Work Order - Medical Services.docx',
          text: 'Work Order for Medical services'
        },
      ]
    },
    {
      groupName: 'Portal guidance and procedures',
      documents: [
        {
          key: 'ORAMS Portal - Provider user functions and requirements',
          name: 'ORAMS - Provider user functions and requirements_Jan2024.pdf',
          text: 'ORAMS Portal - Provider user functions and requirements'
        },
        {
          key: 'Invoice Datasheet template',
          name: 'Invoice Datasheet Template.xlsx',
          text: 'Invoice Datasheet template'
        },
        {
          key: 'Annual Contract Price Review Template - Rehabilitation',
          name: 'Annual Contract Price Review Template - Rehabilitation.xlsx',
          text: 'Annual Contract Price Review Template - Rehabilitation'
        },
        {
          key: 'Annual Contract Price Review Template - Medical',
          name: 'Annual Contract Price Review Template - Medical.xlsx',
          text: 'Annual Contract Price Review Template - Medical'
        },
        {
          key: 'Deed of Variation template',
          name: 'Deed of Variation template.docx',
          text: 'Deed of Variation template'
        },
      ]
    },
    {
      groupName: 'Portal Reporting Templates',
      subGroups: [
        {
          subGroupName: 'Reporting Templates',
          documents: [
            {
              key: '1. Offsite Assessment Report Template',
              name: 'ORAMS - Offsite Assessment Report template - 24 Nov 2023.docx',
              text: '1. Offsite Assessment Report Template'
            },
            {
              key: '2. Rehabilitation Assessment Report template - compensable',
              name: 'ORAMS - Rehabilitation Assessment (compensable) template - 24 Nov 2023.docx',
              text: '2. Rehabilitation Assessment Report template - compensable'
            },
            {
              key: '3. Rehabilitation Assessment Report template - non compensable',
              name: 'ORAMS - Rehabilitation Assessment (non-compensable) Report template - 24 Nov 2023.docx',
              text: '3. Rehabilitation Assessment Report template - non compensable'
            },
            {
              key: '4. Rehabilitation Program Closure Report Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (Non-Compensable) Closure Report Template - 24 Nov 2023 .docx',
              text: '4. Rehabilitation Program Closure Report Template - non compensable'
            },
            {
              key: '5. Rehabilitation Program Progress Report Template',
              name: 'ORAMS - Rehabilitation Program Progress Report template - 24 Nov 2023.docx',
              text: '5. Rehabilitation Program Progress Report Template'
            },
            {
              key: '6. Rehabilitation Program Funding Request Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (non-compensable) Funding Request template - 24 Nov 2023.docx',
              text: '6. Rehabilitation Program Funding Request Template - non compensable'
            },
          ]
        },
        {
          subGroupName: 'Off Portal Referral Forms',
          documents: [
            {
              key: '1. Combined rehab assessment and fitness for duty examination',
              name: '1. ORAMS off portal referral form - combined rehab assessment and FFD .docx',
              text: '1. Combined rehab assessment and fitness for duty examination'
            },
            {
              key: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination',
              name: '3. ORAMS off portal referral form - rehab assessment, pre employment, pre-deployment.docx',
              text: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination'
            },
            {
              key: '3. Health Advisory Services and Specialist Audiometry Testing',
              name: '5. ORAMS off-portal form - Health Advisory Services and Specialist Audiometry Testing.docx',
              text: '3. Health Advisory Services and Specialist Audiometry Testing'
            },
            {
              key: '4. Neuropsychological assessment',
              name: '6. ORAMS off portal referral form - Neuropsychological assessment.docx',
              text: '4. Neuropsychological assessment'
            },
            {
              key: '5. Rehabilitation assessments',
              name: '7. ORAMS off portal referral form - compensable and non-compensable rehabilitation assessment.docx',
              text: '5. Rehabilitation assessments'
            },
            {
              key: '6. Rehabilitation program',
              name: '8. ORAMS off portal referral form - rehabilitation program.docx',
              text: '6. Rehabilitation program'
            },
            {
              key: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment',
              name: '9. ORAMS off portal referral form - ergonomic and functional evaluation, vocational, work capacity, offsite assessment.docx',
              text: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment'
            },
            {
              key: '8. Supplementary report or review (medical and rehabilitation)',
              name: '4. ORAMS off portal referral form - Supplementary reports.docx',
              text: '8. Supplementary report or review (medical and rehabilitation)'
            },
          ]
        }
      ],
    },
    {
      groupName: 'Quarterly data reporting templates',
      documents: [
        {
          key: 'ORAMS Provider activity statement_Rehabiliation Services',
          name: 'ORAMS Provider Activity Statement_Rehab.xlsx',
          text: 'ORAMS Provider activity statement_Rehabiliation Services'
        },
        {
          key: 'ORAMS Provider activity statement_Medical Services',
          name: 'ORAMS Provider Activity Statement_Medical.xlsx',
          text: 'ORAMS Provider activity statement_Medical Services'
        },
        {
          key: 'ORAMS panel activity statement_Rehabilitation Services',
          name: 'ORAMS Panel Activity Statement_Rehabilitation services.xlsx',
          text: 'ORAMS panel activity statement_Rehabilitation Services'
        },
        {
          key: 'ORAMS panel activity statement_Medical Services',
          name: 'ORAMS Panel Activity Statement_Medical services.xlsx',
          text: 'ORAMS panel activity statement_Medical Services'
        },
      ]
    },
    {
      groupName: 'Meeting minutes and ORAMS calendar',
      documents: [
        {
          key: 'Record of meeting log - Provider Quarterly Forum',
          name: 'Record of meeting log - Provider Quarterly Forum.docx',
          text: 'Record of meeting log - Provider Quarterly Forum'
        },
        {
          key: 'ORAMS Calendar',
          name: 'ORAMS Calendar.xlsx',
          text: 'ORAMS Calendar'
        },
      ]
    },
  ],
  admin: [
    {
      groupName: 'Operational documents',
      documents: [
        {
          key: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)',
          name: 'EXECUTION VERSION - Head Agreement for the provision of ORAMS.pdf',
          text: 'Head Agreement for the provision of ORAMS (EXECUTED VERSION)'
        },
        {
          key: 'Application of the Head Agreement - Questions and Answers for providers and agencies',
          name: 'Questions and Answers on the ORAMS Head Agreement.docx',
          text: 'Application of the Head Agreement - Questions and Answers for providers and agencies'
        },
        {
          key: 'ORAMS Service Requirements',
          name: 'Service Requirements .pdf',
          text: 'ORAMS Service Requirements'
        },
        {
          key: 'Universal Operations Manual',
          name: 'UOM Manual WORD FINAL.docx',
          text: 'Universal Operations Manual'
        },
        {
          key: 'Work Order for Rehabilitation services',
          name: 'Work Order - Rehabilitation Services.docx',
          text: 'Work Order for Rehabilitation services'
        },
        {
          key: 'Work Order for Medical services',
          name: 'Work Order - Medical Services.docx',
          text: 'Work Order for Medical services'
        },
        {
          key: 'Model Agency Services Manual',
          name: 'Model Agency Services Manual - 28 Nov 23.docx',
          text: 'Model Agency Services Manual'
        },
        {
          key: 'Provider Region Matrix',
          name: 'Provider Region Matrix.pdf',
          text: 'Provider Region Matrix'
        },
        {
          key: 'Notice of Inclusion',
          name: 'Notice of Inclusion.pdf',
          text: 'Notice of Inclusion'
        },
      ]
    },
    {
      groupName: 'Portal guidance and procedures',
      documents: [
        {
          key: 'Privacy and Consent guidance',
          name: 'ORAMS Privacy and Consent guidance.pdf',
          text: 'Privacy and Consent guidance'
        },
        {
          key: 'Employee consent form (for the ORAMS Portal)',
          name: 'Employee Consent request form.pdf',
          text: 'Employee consent form (for the ORAMS Portal)'
        },
        {
          key: 'ORAMS Portal - Agency (RCM and Primary RCM) user functions and requirements',
          name: 'ORAMS - Agency (Primary RCM and RCM) user functions and requirements_Feb2024.pdf',
          text: 'ORAMS Portal - Agency (RCM and Primary RCM) user functions and requirements'
        },
        {
          key: 'ORAMS Portal - Provider user functions and requirements',
          name: 'ORAMS - Provider user functions and requirements_Jan2024.pdf',
          text: 'ORAMS Portal - Provider user functions and requirements'
        },
        {
          key: 'Invoice Datasheet template',
          name: 'Invoice Datasheet Template.xlsx',
          text: 'Invoice Datasheet template'
        },
        {
          key: 'Annual Contract Price Review Template - Rehabilitation',
          name: 'Annual Contract Price Review Template - Rehabilitation.xlsx',
          text: 'Annual Contract Price Review Template - Rehabilitation'
        },
        {
          key: 'Annual Contract Price Review Template - Medical',
          name: 'Annual Contract Price Review Template - Medical.xlsx',
          text: 'Annual Contract Price Review Template - Medical'
        },
        {
          key: 'Deed of Variation template',
          name: 'Deed of Variation template.docx',
          text: 'Deed of Variation template'
        },
        {
          key: 'CPI - Deed of Variation template',
          name: 'CPI Variation to Deed template_FINAL.pdf',
          text: 'CPI - Deed of Variation template'
        },
      ]
    },
    {
      groupName: 'Portal Reporting Templates',
      subGroups: [
        {
          subGroupName: 'Reporting Templates',
          documents: [
            {
              key: '1. Offsite Assessment Report Template',
              name: 'ORAMS - Offsite Assessment Report template - 24 Nov 2023.docx',
              text: '1. Offsite Assessment Report Template'
            },
            {
              key: '2. Rehabilitation Assessment Report template - compensable',
              name: 'ORAMS - Rehabilitation Assessment (compensable) template - 24 Nov 2023.docx',
              text: '2. Rehabilitation Assessment Report template - compensable'
            },
            {
              key: '3. Rehabilitation Assessment Report template - non compensable',
              name: 'ORAMS - Rehabilitation Assessment (non-compensable) Report template - 24 Nov 2023.docx',
              text: '3. Rehabilitation Assessment Report template - non compensable'
            },
            {
              key: '4. Rehabilitation Program Closure Report Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (Non-Compensable) Closure Report Template - 24 Nov 2023 .docx',
              text: '4. Rehabilitation Program Closure Report Template - non compensable'
            },
            {
              key: '5. Rehabilitation Program Progress Report Template',
              name: 'ORAMS - Rehabilitation Program Progress Report template - 24 Nov 2023.docx',
              text: '5. Rehabilitation Program Progress Report Template'
            },
            {
              key: '6. Rehabilitation Program Funding Request Template - non compensable',
              name: 'ORAMS - Rehabilitation Program (non-compensable) Funding Request template - 24 Nov 2023.docx',
              text: '6. Rehabilitation Program Funding Request Template - non compensable'
            },
          ]
        },
        {
          subGroupName: 'Off Portal Referral Forms',
          documents: [
            {
              key: '1. Combined rehab assessment and fitness for duty examination',
              name: '1. ORAMS off portal referral form - combined rehab assessment and FFD .docx',
              text: '1. Combined rehab assessment and fitness for duty examination'
            },
            {
              key: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination',
              name: '3. ORAMS off portal referral form - rehab assessment, pre employment, pre-deployment.docx',
              text: '2. Rehab assessment (medical) and overseas or remote pre-deployment, preemployment and fitness for duty examination'
            },
            {
              key: '3. Health Advisory Services and Specialist Audiometry Testing',
              name: '5. ORAMS off-portal form - Health Advisory Services and Specialist Audiometry Testing.docx',
              text: '3. Health Advisory Services and Specialist Audiometry Testing'
            },
            {
              key: '4. Neuropsychological assessment',
              name: '6. ORAMS off portal referral form - Neuropsychological assessment.docx',
              text: '4. Neuropsychological assessment'
            },
            {
              key: '5. Rehabilitation assessments',
              name: '7. ORAMS off portal referral form - compensable and non-compensable rehabilitation assessment.docx',
              text: '5. Rehabilitation assessments'
            },
            {
              key: '6. Rehabilitation program',
              name: '8. ORAMS off portal referral form - rehabilitation program.docx',
              text: '6. Rehabilitation program'
            },
            {
              key: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment',
              name: '9. ORAMS off portal referral form - ergonomic and functional evaluation, vocational, work capacity, offsite assessment.docx',
              text: '7. Ergonomic and functional evaluation, vocational, work capacity, off-site assessment'
            },
            {
              key: '8. Supplementary report or review (medical and rehabilitation)',
              name: '4. ORAMS off portal referral form - Supplementary reports.docx',
              text: '8. Supplementary report or review (medical and rehabilitation)'
            },
          ]
        }
      ]
    },
    {
      groupName: 'Quarterly data reporting templates',
      documents: [
        {
          key: 'ORAMS Provider activity statement_Rehabiliation Services',
          name: 'ORAMS Provider Activity Statement_Rehab.xlsx',
          text: 'ORAMS Provider activity statement_Rehabiliation Services'
        },
        {
          key: 'ORAMS Provider activity statement_Medical Services',
          name: 'ORAMS Provider Activity Statement_Medical.xlsx',
          text: 'ORAMS Provider activity statement_Medical Services'
        },
        {
          key: 'ORAMS panel activity statement_Rehabilitation Services',
          name: 'ORAMS Panel Activity Statement_Rehabilitation services.xlsx',
          text: 'ORAMS panel activity statement_Rehabilitation Services'
        },
        {
          key: 'ORAMS panel activity statement_Medical Services',
          name: 'ORAMS Panel Activity Statement_Medical services.xlsx',
          text: 'ORAMS panel activity statement_Medical Services'
        },
        {
          key: 'Data prep for Rehab Providers_Mastercopy',
          name: 'Data prep for REHAB PROVIDERS - Mastercopy.xlsx',
          text: 'Data prep for Rehab Providers_Mastercopy'
        },
        {
          key: 'Data prep for Medical Providers_Mastercopy',
          name: 'Data prep for MEDICAL PROVIDERS - Mastercopy.xlsx',
          text: 'Data prep for Medical Providers_Mastercopy'
        },
      ]
    },
    {
      groupName: 'Meeting minutes and ORAMS calendar',
      documents: [
        {
          key: 'Record of meeting log - Provider Quarterly Forum',
          name: 'Record of meeting log - Provider Quarterly Forum.docx',
          text: 'Record of meeting log - Provider Quarterly Forum'
        },
        {
          key: 'Record of meeting log - Agency Quarterly Forum',
          name: 'Record of meeting log - Agency Quarterly Forum.docx',
          text: 'Record of meeting log - Agency Quarterly Forum'
        },
        {
          key: 'ORAMS Calendar',
          name: 'ORAMS Calendar.xlsx',
          text: 'ORAMS Calendar'
        },
        {
          key: 'Agenda Template - Provider Quarterly Forum',
          name: 'Agenda Template - Quarterly Provider Forum.docx',
          text: 'Agenda Template - Provider Quarterly Forum'
        },
        {
          key: 'Agenda Template - Agency Quarterly Forum',
          name: 'Agenda Template - Quarterly Agency Forum.docx',
          text: 'Agenda Template - Agency Quarterly Forum'
        },
      ]
    },
  ]
}

export function getDocumentUrl(documentName) {
  return `${API}/${documentName}?${QUERY}`;
}

export default function useDocument() {
    const { user } = useAuth();

    let userDocuments = [];

    if (user && user.token) {
        if (user.role) {
            userDocuments = documentsMap[user.role];
        }
    }

    return { userDocuments, getDocumentUrl };
};