import { Box, Typography,Container } from "@mui/material";
import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

export default function DisclaimerPage() {
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
                Disclaimer
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                We provide this website to inform the public, government agencies and panel providers about
                the Occupational Rehabilitation and Associated Medical Services (ORAMS) Portal.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                While we make every effort to ensure that material is accurate and current, the material is
                general in nature and is not provided as professional advice. You should carefully evaluate the
                accuracy, completeness and relevance of material for your purposes, and seek appropriate
                professional advice for your circumstances.
            </Typography>
            <Typography component="h2" variant="h2" sx={{ mb: 2 , mt: 2}}>
                Website Content
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                We frequently update our content - please return to the site as required to ensure you have
                the latest advice.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 0 }}>
                The material on this website may include or summarise the views or advice of third parties.
                These do not necessarily reflect:
            </Typography>
            <List sx = {{listStyleType: 'disc', pl: 4, '& .MuiListItem-root': {display: 'list-item',},}}>
                <Typography component="p" variant="body">
                    <ListItem>views of Comcare, or those of the Commonwealth</ListItem>
                </Typography>
                <Typography component="p" variant="body">
                    <ListItem>our commitment to a particular course of action.</ListItem>
                </Typography>
            </List>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                We are careful when choosing external website links. However, we are not responsible for the<br/>
                information on them. We do not necessarily endorse the information. Please make your own<br/>
                decision on how accurate, current and reliable the information is.
            </Typography>
            <Typography component="p" variant="body2" sx={{ mt: 2 }}>
                Last updated: 14/01/2021
            </Typography>
        </Box>
        </Container>
    );
}