import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { getAddressText } from "./InjuredEmployeeDetails";
import { REFERRAL_TYPE_COMPENSATION } from "../../constants/referralConstants";
  
function renderCallCentre(referralType, isCallCentreEmployee = false) {
    if (referralType === REFERRAL_TYPE_COMPENSATION) {
      return null;
    }
  
    return (
        <FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox disabled checked={isCallCentreEmployee} size="small"/>}
                    label="Is this person a call centre employee?"
                />
            </FormGroup>
        </FormControl>
    );
}

const WorkplaceDetails = ({ referral }) => {
    const { detail, type } = referral;
    const {
        employeeCurrentEmploymentType: currentEmploymentType,
        employeeCurrentJobStatus: currentJobStatus = "",
        employeeJobTitle,
        callCentreEmployee: isCallCentreEmployee = false,
        employeeCurrentlyAtWork: isCurrentlyAtWork,
        employeeSectionAndBranch: employeeBranch,
        employeeLocationInPremises: employeeLocation,
        employeeWorkAddress,
        employeeWorkAddressSuburb: employeeWorkSuburb,
        employeeWorkAddressState: employeeWorkState,
        employeeWorkAddressPostcode: employeeWorkPostcode,
        employeeWorkPhone,
        employerAgency,
        postInjuryHours: postInjuryWorkHours,
        postInjuryDuties: postInjuryWorkDuties
    } = detail || {};
  
    const addressText = getAddressText({
      addressLine: employeeWorkAddress,
      suburb: employeeWorkSuburb,
      state: employeeWorkState,
      postcode: employeeWorkPostcode
    });
  
    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">Workplace details</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Injured employee's current status with this employer</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{currentJobStatus}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Current employment type</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{currentEmploymentType}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Employee at work?</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{isCurrentlyAtWork}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Organisation</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employerAgency}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Work address</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{addressText}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Work phone</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeWorkPhone}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Section/Branch</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeBranch}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Physical location</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeLocation}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Post injury work hours</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{postInjuryWorkHours}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Post injury work duties</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{postInjuryWorkDuties}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Employee job title</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{employeeJobTitle}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {renderCallCentre(type, isCallCentreEmployee)}
            </Grid>
        </Grid>
    );
}

export default WorkplaceDetails;
