import { Alert, Box, Button, Link, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { isExamineeServiceType } from "../../constants/referralConstants";
import { VALID_EMAIL_ADDRESS_PATTERN } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";

const DelegateInfoForm = ({ referralInfo, referralType, handleOnCancel, handleOnSubmit, handleOnBack, delegateInfo }) => {
    const { error } = useAuth();
    const { control, handleSubmit, reset, getValues } = useForm({
        reValidateMode: "onBlur"
    });
    const { serviceTypeId: serviceId } = referralInfo || {};

    useEffect(() => {
        reset(delegateInfo);
    },
    // eslint-disable-next-line
    [ delegateInfo ]);

    const errorHelper = {
        delegateGivenName: {
            required: "Delegate Given Name is required",
        },
        delegateSurname: {
            required: "Delegate Family Name is required",
        },
        delegatePosition: {
            required: "Delegate Position is required",
        },
        employerAgency: {
            required: "Employer Agency is required",
        },
        rcmPhone: {
            required: "Phone is required",
        },
        rcmEmail: {
            required: "Email address is required",
            pattern: "Invalid email address"
        },
        s36Reasons: {
            required: "s36(3) reasons are required",
            validateWordLimit: "s36(3) reasons must be 200 words or fewer",
        },
        caseNotes: {
            validateWordLimit: "Case notes must be 200 words or fewer",
        },
      };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                ml: 8,
                mr: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '75ch',
                gap: 1,
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}
            <Typography variant="h2">{isExamineeServiceType(serviceId) ? `Details of Referrer` : `Delegate / Rehabilitation Case Manager Information`}</Typography>

            <Controller
                control={control}
                name="rcmAgsNumber"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label={isExamineeServiceType(serviceId) ? `Referrer's  AGS number (optional)` : `Rehabilitation case manager's  AGS number (optional)`}
                        id="rcmAgsNumber"
                        fullWidth
                        autoFocus
                    />
                )}
            />

            <Controller
                control={control}
                name="delegateSalutation"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Salutation (optional)"
                        id="delegateSalutation"
                        fullWidth 
                    />
                )}
            />

            <Controller
                control={control}
                name="delegateGivenName"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={true}
                        label={`Given name`}
                        id="delegateGivenName"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.delegateGivenName[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="delegateSurname"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={true}
                        label={`Family name`}
                        id="delegateSurname"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.delegateSurname[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="delegatePosition"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={true}
                        label={`Position`}
                        id="delegatePosition"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.delegatePosition[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="employerAgency"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={true}
                        label={`Organisation name`}
                        id="employerAgency"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.employerAgency[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="agencyReferralId"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Agency referral ID (optional)"
                        id="agencyReferralId"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="rcmPhone"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={true}
                        label={`Phone`}
                        id="rcmPhone"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.rcmPhone[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="rcmFax"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Fax (optional)"
                        id="rcmFax"
                        fullWidth 
                    />
                )}
            />

            <Controller
                control={control}
                name="rcmEmail"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: VALID_EMAIL_ADDRESS_PATTERN
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={true}
                        label={`Email`}
                        id="rcmEmail"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.rcmEmail[error.type] : ""}
                    />
                )}
            />

            {!isExamineeServiceType(serviceId) && referralType && referralType === 'Compensation' &&
                <React.Fragment>
                    <Typography variant="h3">Compensation case information</Typography>
                    <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                        If this referral relates to a service that requires a determination under the <i>Safety, Rehabilitation and Compensation Act 1988</i>, you <b>must</b> also complete the relevant{' '}
                        <Link href="https://www.comcare.gov.au/about/forms-pubs/forms" target="_blank" rel="noreferrer">determination form</Link>
                    </Typography>
                </React.Fragment>
            }

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    gap: 1,
                }}
            >
                <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                        handleOnCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    type="button"
                    variant="contained"
                    onClick={() => {
                        handleOnBack(getValues());
                    }}
                >
                    Back
                </Button>
                <Button type="submit" variant="contained">Continue</Button>
            </Box>
        </Box>
    );
}

export default DelegateInfoForm;