import { Box, Link, Typography,Container } from "@mui/material";
import React from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function CopyrightPage() {
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
                Copyright
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                With the exception of the Commonwealth Coat of Arms and where otherwise noted, all
                material presented on the Occupation Rehabilitation and Associated Medical Services (ORAMS)
                Portal is provided under Creative Commons Attribution 4.0 International licence.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                The details of the relevant licence conditions and the full legal code for the licence are available
                on the{' '}
                <Link href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer">
                    Creative Commons website <OpenInNewIcon fontSize="small"/>
                </Link>
                .
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                <Link href="https://www.comcare.gov.au/about/contact/contact-us" target="_blank" rel="noreferrer">
                    Contact Comcare
                </Link>
                {' '}if you have an enquiry about this licence or use of any material on this website.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Use of all or part of any material on this website must include the attribution:
                &#169; Commonwealth of Australia {new Date().getFullYear()}
            </Typography>
            <Typography component="h2" variant="h2" sx={{ mb: 2 }}>
                Use of the Coat of Arms
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                <Link href="https://www.pmc.gov.au/government/commonwealth-coat-arms" target="_blank" rel="noreferrer">
                    Guidelines for use of the Commonwealth Coat of Arms <OpenInNewIcon fontSize="small"/>
                </Link>
                {' '}are published by the Department of Prime Minister and Cabinet.
            </Typography>
            <Typography component="p" variant="body2">
                Last updated: 24/01/2023
            </Typography>
        </Box>
        </Container>
    );
}