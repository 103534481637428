import { Alert, Autocomplete, Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import * as referralApi from "../api/referrals";
import { REFERRAL_STATUSES, REFERRAL_TYPE_COMPENSATION } from "../constants/referralConstants";
import CompensationDetails from "../components/ReferralDetails/CompensationDetails";
import NonCompensationDetails from "../components/ReferralDetails/NonCompensationDetails";
import { ROLE_SUPPLIER } from "../constants/userConstants";
import useUser from "../hooks/useUser";
import { Controller, useForm } from "react-hook-form";
import { limitWords } from "../constants/constants";
import Loading from "../components/Loading/Loading";

function renderDetails(newReferral) {
    if (newReferral) {
        if (newReferral.type === REFERRAL_TYPE_COMPENSATION) {
            return (<CompensationDetails referral={newReferral} />);
        }
        return (<NonCompensationDetails referral={newReferral} />);
    }

    return null;
};

const AssignButton = ({ referral }) => {
    const { user } = useAuth();
    const { role: userType } = user || {};
    const { id, status, restricted } = referral || {};

    if (userType !== ROLE_SUPPLIER && status !== REFERRAL_STATUSES.CANCELLED && status !== REFERRAL_STATUSES.REJECTED && !restricted) {
        return (
            <Box
                sx={{
                    margin: 2,
                }}
            >
                <Button
                    variant="contained"
                    component={RouterLink}
                    to={`/assign-referral/${id}`}
                >
                    Assign RCM
                </Button>
            </Box>
        );
    }
    return null;
}

const AcceptButton = ({ referral }) => {
    const { user, setSuccess, call } = useAuth();
    const { role: userType } = user || {};
    const { id, status } = referral || {};
    const navigate = useNavigate();
    const { userTypeNavLinks } = useUser();
    const dashboardUrl = userTypeNavLinks.filter((link) => link.key === 'dashboard')[0].url;

    if (userType === ROLE_SUPPLIER && status === REFERRAL_STATUSES.CREATED) {
        return (
            <Box
                sx={{
                    margin: 2,
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        setSuccess(undefined);
                        call(referralApi.accept, { id }, (response) => {
                            setSuccess(`Referral (ID: ${id}) accepted. The buyer will now be notified of this action.`);
                            navigate(dashboardUrl);
                        });
                    }}
                >
                    Accept
                </Button>
            </Box>
        );
    }
    return null;
}

const RejectForm = ({ referral }) => {
    const { user, setSuccess, call } = useAuth();
    const { role: userType } = user || {};
    const { id, status } = referral || {};
    const { control, handleSubmit, reset } = useForm({
        reValidateMode: "onBlur"
    });
    const navigate = useNavigate();
    const { userTypeNavLinks } = useUser();
    const dashboardUrl = userTypeNavLinks.filter((link) => link.key === 'dashboard')[0].url;
    const [ rejectionReasons, setRejectionReasons ] = useState([]);
    const [ textReasonRequired, setTextReasonRequired ] = useState(false);
    const errorHelper = {
        rejectReason: {
          required: "Rejection reason is required",
        },
        rejectTextReason: {
          required: "Reason text is required",
          validateWordLimit: "Reason text must be 200 words or fewer",
        },
    };
    
    useEffect(() => {
        call(referralApi.rejectionReasons, {noloading: true}, setRejectionReasons);
    },
    // eslint-disable-next-line
    []);

    const handleOnSubmit = ({ rejectReason, rejectTextReason }) => {
        setSuccess(undefined);
        call(referralApi.reject, { id, rejectReasonId: rejectReason.value, rejectReasonText: rejectTextReason }, (response) => {
            setSuccess(
                `Referral (ID: ${id}) rejected. Reason: ${rejectReason.text} ${rejectTextReason
                    ? ` - ${rejectTextReason}`
                    : ''}. The delegate will now be notified of this action.`
            );
            reset({
                rejectReason: null,
                rejectTextReason: undefined,
            });
            navigate(dashboardUrl);
            window.scrollTo(0, 0);
        });
    };

    if (userType === ROLE_SUPPLIER && status === REFERRAL_STATUSES.CREATED) {
        return (
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                >
                    Reject
                </Button>
                <Controller
                    render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                            id="rejectReason"
                            style={{ width: '50ch' }}
                            options={rejectionReasons}
                            autoHighlight
                            getOptionLabel={option => option.text}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Rejection reason"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "disabled" // disable autocomplete and autofill
                                    }}
                                    error={error !== undefined}
                                    helperText={error ? errorHelper.rejectReason[error.type] : ""}
                                />
                            )}
                            onChange={(_, data) => {setTextReasonRequired(data && data.reasonTextRequired); field.onChange(data);}}
                        />
                    )}
                    rules={{
                        required: true,
                    }}
                    name="rejectReason"
                    control={control}
                />
                {textReasonRequired &&
                    <Controller
                        control={control}
                        name="rejectTextReason"
                        defaultValue=""
                        rules={{
                            required: true,
                            validate: {
                                validateWordLimit: limitWords(200),
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                multiline={true}
                                margin="normal"
                                label="Reason Text"
                                id="rejectTextReason"
                                sx={{ width: '50ch' }}
                                minRows={4}
                                maxRows={4}
                                error={error !== undefined}
                                helperText={error ? errorHelper.rejectTextReason[error.type] : ""}
                            />
                        )}
                    />
                }
            </Box>
        );
    }
    return null;
}

const CancelForm = ({ referral }) => {
    const { user, setSuccess, call } = useAuth();
    const { role: userType } = user || {};
    const { id, status } = referral || {};
    const { control, handleSubmit, reset } = useForm({
        reValidateMode: "onBlur"
    });
    const navigate = useNavigate();
    const { userTypeNavLinks } = useUser();
    const dashboardUrl = userTypeNavLinks.filter((link) => link.key === 'dashboard')[0].url;
    const [ cancellationReasons, setCancellationReasons ] = useState([]);
    const [ textReasonRequired, setTextReasonRequired ] = useState(false);
    const errorHelper = {
        cancelReason: {
          required: "Cancellation reason is required",
        },
        cancelTextReason: {
          required: "Reason text is required",
          validateWordLimit: "Reason text must be 200 words or fewer",
        },
    };
    
    useEffect(() => {
        call(referralApi.cancellationReasons, {noloading: true}, setCancellationReasons);
    },
    // eslint-disable-next-line
    []);

    const handleOnSubmit = ({ cancelReason, cancelTextReason }) => {
        const cancelReasonId =  cancelReason.value === 'other' ? null : parseInt(cancelReason.value, 10);
        const cancelReasonText =  cancelReason.value === 'other' ? cancelTextReason : "";
        setSuccess(undefined);
        call(referralApi.cancel, { id, cancelReasonId, cancelReasonText }, (response) => {
            setSuccess(`Referral (ID: ${id}) has now been cancelled.`);
            reset({
                cancelReason: null,
                cancelTextReason: undefined,
            });
            navigate(dashboardUrl);
            window.scrollTo(0, 0);
        });
    };

    if (userType !== ROLE_SUPPLIER && (status === REFERRAL_STATUSES.CREATED || status === REFERRAL_STATUSES.ACCEPTED)) {
        return (
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate sx={{ margin: 2, }} >
                <Controller
                    render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                            id="cancelReason"
                            style={{ width: '50ch' }}
                            options={cancellationReasons}
                            autoHighlight
                            getOptionLabel={option => option.text}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Cancellation reason"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "disabled" // disable autocomplete and autofill
                                    }}
                                    error={error !== undefined}
                                    helperText={error ? errorHelper.cancelReason[error.type] : ""}
                                />
                            )}
                            onChange={(_, data) => {setTextReasonRequired(data && data.value === 'other'); field.onChange(data);}}
                        />
                    )}
                    rules={{
                        required: true,
                    }}
                    name="cancelReason"
                    control={control}
                />
                {textReasonRequired &&
                    <Controller
                        control={control}
                        name="cancelTextReason"
                        defaultValue=""
                        rules={{
                            required: true,
                            validate: {
                                validateWordLimit: limitWords(200),
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                multiline={true}
                                margin="normal"
                                label="Reason Text"
                                id="cancelTextReason"
                                sx={{ width: '50ch' }}
                                minRows={4}
                                maxRows={4}
                                error={error !== undefined}
                                helperText={error ? errorHelper.cancelTextReason[error.type] : ""}
                            />
                        )}
                    />
                }
                <br/>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                >
                    Cancel
                </Button>
            </Box>
        );
    }
    return null;
}

export default function ReferralDetailsPage() {
    const { error, call } = useAuth();
    const { id } = useParams();
    const [ referral, setReferral ] = useState();

    const handleReferral = (newReferral) => {
        setReferral(newReferral);
    };

    useEffect(() => {
        call(referralApi.get, { id }, handleReferral, 'Unable to load referral');
    },
    // eslint-disable-next-line
    [ id ]);

    if (!referral && !error) {
        return (
            <Loading />
        );
    }

    console.log(referral);

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}
            {!error &&
                <React.Fragment>
                    {(referral && referral.status === REFERRAL_STATUSES.REJECTED && referral.rejectionReason) &&
                        <Alert severity="error">
                            {`Rejection reason: ${referral.rejectionReason}`}
                            {referral.rejectionReasonText && referral.rejectionReasonText !== "undefined" ? ` - ${referral.rejectionReasonText}` : ''}
                        </Alert>
                    }
                    {(referral && referral.cancellationReason) &&
                        <Alert severity="error">
                            {`Cancellation reason: ${referral.cancellationReason}`}
                        </Alert>
                    }
                    {renderDetails(referral)}
                    <AssignButton referral={referral} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'left',
                        }}
                    >
                        <AcceptButton referral={referral} />
                        <RejectForm referral={referral} />
                    </Box>
                    <CancelForm referral={referral} />
                </React.Fragment>
            }
        </Box>
    );
};