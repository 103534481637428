import { Box, Link, Typography,Container } from "@mui/material";
import React from "react";

export default function AboutPage() {
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
                About ORAMS
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
              The Occupational Rehabilitation and Medical Services (ORAMS) Provider Panel is comprised of qualified and specialist medical and rehabilitation service providers.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
              The Panel is supported by the ORAMS Portal which allows Rehabilitation Case Managers (RCMs) to quickly find a qualified medical or rehabilitation provider in a particular location.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
              RCMs can search the ORAMS Portal for providers by service type, price and location. RCMs can compare pricing between service providers, and create referrals for Commonwealth employees.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
              ORAMS providers can also use the portal to manage their business details and adjust their daily pricing (to any amount under the relevant price cap), as well as respond to referral requests.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
              The Portal and the Panel arrangements are managed by the Comcare ORAMS team. For help, email{' '}
              <Link href="mailto:orams@comcare.gov.au" variant="body">
                orams@comcare.gov.au
              </Link>
            </Typography>
        </Box>
        </Container>
    );
}