import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import BuyerDashboard from "../components/Dashboard/BuyerDashboard";
import SupplierDashboard from "../components/Dashboard/SupplierDashboard";
import { Alert, Box, Typography,Container } from "@mui/material";
import DashboardFilter from "../components/Dashboard/DashboardFilter";
import DashboardHeader from "../components/Dashboard/DashboardHeader";
import * as referralApi from "../api/referrals";
import useUser from "../hooks/useUser";
import dayjs from "dayjs";
import { ROLE_ADMIN, ROLE_PRIMARY_USER, ROLE_SUPPLIER } from "../constants/userConstants";

function adminDashboard(dashboardAction, referrals, refreshReferrals) {
    switch(dashboardAction) {
        case "team-created":
            return (
                <BuyerDashboard statusFilter={['created']} referrals={referrals} sort={oldestFirstSort('dateCreated')} refreshReferrals={refreshReferrals}/>
            );
        case "team-accepted":
            return (
                <BuyerDashboard statusFilter={['accepted']} referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
        case "team-past":
            return (
                <BuyerDashboard statusFilter={['rejected', 'completed', 'cancelled', 'sentForPayment']} referrals={referrals} sort={newestFirstSort}  refreshReferrals={refreshReferrals}/>
            );
        default:
            return (
                <BuyerDashboard referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
    }
}

function supplierDashboard(dashboardAction, referrals, refreshReferrals) {
    switch(dashboardAction) {
        case "new":
            return (
                <SupplierDashboard statusFilter={['created']} referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
        case "accepted":
            return (
                <SupplierDashboard statusFilter={['accepted']} referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
        case "past":
            return (
                <SupplierDashboard statusFilter={['rejected', 'completed', 'cancelled', 'sentForPayment']} referrals={referrals} sort={newestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
        default:
            return (
                <SupplierDashboard referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
    }
}

function buyerDashboard(dashboardAction, referrals, refreshReferrals) {
    switch(dashboardAction) {
        case "created":
            return (
                <BuyerDashboard statusFilter={['created']} referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
        case "accepted":
            return (
                <BuyerDashboard statusFilter={['accepted']} referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
        case "past":
            return (
                <BuyerDashboard statusFilter={['rejected', 'completed', 'cancelled', 'sentForPayment']} referrals={referrals} sort={newestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
        default:
            return (
                <BuyerDashboard referrals={referrals} sort={oldestFirstSort('dateCreated')}  refreshReferrals={refreshReferrals}/>
            );
    }
}

function dashboardByUserType(userType, dashboardAction, referrals, refreshReferrals) {
    switch(userType) {
        case ROLE_ADMIN: 
        case ROLE_PRIMARY_USER: 
            return adminDashboard(dashboardAction, referrals, refreshReferrals);
        case ROLE_SUPPLIER:
            return supplierDashboard(dashboardAction, referrals, refreshReferrals);
        default:
            return buyerDashboard(dashboardAction, referrals, refreshReferrals);
    }
}

const newestFirstSort = (fieldName) => (r1, r2) => {
    if (dayjs(r1[fieldName]).isBefore(dayjs(r2[fieldName]))) {
        return 1;
    } else if (dayjs(r1[fieldName]).isAfter(dayjs(r2[fieldName]))) {
        return -1;
    }
    return 0;
};

const oldestFirstSort = (fieldName) => (r1, r2) => {
    if (dayjs(r1[fieldName]).isBefore(dayjs(r2[fieldName]))) {
        return -1;
    } else if (dayjs(r1[fieldName]).isAfter(dayjs(r2[fieldName]))) {
        return 1;
    }
    return 0;
};

export default function DashboardPage() {
    const { user, error, success, call } = useAuth();
    const { dashboardAction } = useParams();
    const [ referrals, setReferrals ] = useState([]);
    const [ filter, setFilter ] = useState([]);
    const { getDashboardStatus } = useUser();

    function loadReferrals(filter) {
        call(referralApi.list, filter, setReferrals);
    };

    function handleFilter(filter) {
        setFilter(filter);
        loadReferrals(filter);
    }

    function refreshReferrals(newDashboardAction) {
        let newFilter = getDashboardStatus(newDashboardAction);
        if (filter) {
            newFilter = {...filter, ...newFilter};
        }
        handleFilter(newFilter);
    }

    useEffect(() => {
        refreshReferrals(dashboardAction);
    },
    // eslint-disable-next-line
    [ dashboardAction ]);

    if (user) {
        return (
            <Container maxWidth="xl">
            <Box
                sx={{
                margin:{ xs: 3,sm: 6, md: 9, lg: 9, xl: 9},
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                }}
            >
                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">{success}</Alert>}
                <Typography variant="h1">
                    Dashboard
                </Typography>
                <DashboardFilter handleFilter={handleFilter} />
                <DashboardHeader filter={filter} />
                {dashboardByUserType(user.role, dashboardAction, referrals, refreshReferrals)}
            </Box>
            </Container>
        );
    }
  
    return <Navigate to="/login" />;
};