import { Box, Button, ButtonGroup, Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ROLE_BUYER } from "../../constants/userConstants";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import * as adminApi from "../../api/admin";
import dayjs from "dayjs";

const DashboardHeader = ({ filter }) => {
    const { dashboardUserTypeNavLinks } = useUser();
    const { user, call, location } = useAuth();
    const { startDate, endDate } = filter || {};

    function downloadReferralsReport() {
        call(adminApi.referralsReport, { startDate, endDate, user }, (response) => {
            let filename = dayjs().format('YYYY-MM-DD') + "-referrals.csv";
            const { 'content-disposition': content_disposition } = response.headers;
            if (content_disposition) {
                filename = content_disposition.split(' ')[1].split('=')[1];
            }
            const blob = new Blob([response.data], { type: 'text/csv' }) // eslint-disable-line no-undef
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // for IE
              window.navigator.msSaveOrOpenBlob(blob, filename)
            } else {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = filename
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
        });
    }
      
    const navigationLinks = () => {
        const navLinks = dashboardUserTypeNavLinks || [];
        if (navLinks && navLinks.length) {
          return navLinks.map(navLink => {
            const { key, url, text } = navLink
            return (
                <Button component={RouterLink} to={url} key={key} variant={url === location.pathname ? "contained" : "outlined"}>
                    {text}
                </Button>
            )
          });
        }
  
        return '';
    }

    return (
        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                p: 2
            }}
        >
            <ButtonGroup variant="outlined"  sx={{ flexGrow: 2 }}>
                {navigationLinks()}
            </ButtonGroup>
            {user && user.role !== ROLE_BUYER &&
                <Button component={Link} onClick={downloadReferralsReport}>
                    Download All Referrals
                </Button>
            }
        </Box>
    );

}

export default DashboardHeader;