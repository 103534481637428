import React from "react";
import { AuthContextProvider } from "./hooks/useAuth";
import Header from "./components/Header/Header";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";
import Footer from "./components/Footer/Footer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-au';
import { theme } from "./theme";
import Router from "./Router";
import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react";


const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN || 'notoken',
  captureUncaught: true,
  captureUnhandledRejections: true,
  verbose: true,
  payload: {
      environment: process.env.REACT_APP_ROLLBAR_ENV || 'development',
  }
};

export default function App() {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <AuthContextProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Header />
              <Router />
              <Footer />
            </ThemeProvider>
          </LocalizationProvider>
        </AuthContextProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}

