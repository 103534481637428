import { Button, Chip, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import DashboardTable from "./DashboardTable";
import { REFERRAL_STATUSES } from "../../constants/referralConstants";
import * as referralApi from "../../api/referrals";

const STATUS_MAP = {
    accepted: { chipLabel: "Accepted", chipColour: "info", chipVariant: "filled" },
    cancelled: { chipLabel: "Cancelled", chipColour: "primary", chipVariant: "outlined" },
    rejected: { chipLabel: "Rejected", chipColour: "error", chipVariant: "filled" },
    completed: { chipLabel: "Completed", chipColour: "primary", chipVariant: "filled" },
    sentForPayment: { chipLabel: "Sent for payment", chipColour: "success", chipVariant: "filled" }
}

function renderStatus(status, dateCreated, isFeeHourly) {
    let chipLabel = "";
    let chipColour = "primary";
    let chipVariant = "filled";

    if (status === REFERRAL_STATUSES.CREATED) {
        if (dayjs().diff(dayjs(dateCreated), 'h') >= 24) {
            chipLabel = "Overdue";
            chipColour = "error";
        } else {
            chipLabel = "Pending";
            chipColour = "warning";
        }
    } else if (status === REFERRAL_STATUSES.SENT_FOR_PAYMENT) {
        if (isFeeHourly) {
            chipLabel = "Rehabilitation commenced";
        } else {
            chipLabel = "Sent for payment";
        }
        chipColour = "success";
    } else {
        chipLabel = STATUS_MAP[status].chipLabel
        chipColour = STATUS_MAP[status].chipColour
        chipVariant = STATUS_MAP[status].chipVariant
    }

    return (
        <Chip label={chipLabel} color={chipColour} variant={chipVariant} />
    )
};

function handleComplete(referralId, successMessage, errorMessage, auth, refreshReferrals) {
    const { setSuccess, call, location } = auth;
    const dashboardAction = location.pathname.split('/').pop();
    setSuccess(undefined);
    call(referralApi.complete, { id: referralId }, (response) => {
        setSuccess(successMessage);
        refreshReferrals(dashboardAction);
    }, errorMessage);
}

function handleSentForPayment(referralId, successMessage, errorMessage, auth, refreshReferrals) {
    const { setSuccess, call, location } = auth;
    const dashboardAction = location.pathname.split('/').pop();
    setSuccess(undefined);
    call(referralApi.sentForPayment, { id: referralId }, (response) => {
        setSuccess(successMessage);
        refreshReferrals(dashboardAction);
    }, errorMessage);
}

const CANCEL_ACTION = { name: "cancel", render: (referralId) => {
    return (
        <Button component={RouterLink} to={`/referrals/${referralId}#referral-actions`}>Cancel</Button>
    );
}};
const MARK_AS_COMPLETE_ACTION = { name: "complete", render:  (referralId, auth, refreshReferrals) => {
    return (
        <Button
            onClick={() => {
                handleComplete(
                    referralId,
                    `Referral (ID: ${referralId}) has been successfully marked as Completed.`,
                    `Failed to mark referral (ID: ${referralId}) as Completed.`,
                    auth,
                    refreshReferrals,
                );
            }}
        >
            Mark as complete
        </Button>
    );
}};
const VIEW_ACTION = { name: "view", render: (referralId) => {
    return (
        <Button component={RouterLink} to={`/referrals/${referralId}`}>View</Button>
    );
}};
const MARK_AS_SENT_ACTION = { name: "markAsSent", render: (referralId, auth, refreshReferrals) => {
    return (
        <Button
            onClick={() => {
                handleSentForPayment(
                    referralId,
                    `Referral (ID: ${referralId}) has been successfully marked as Sent for payment.`,
                    `Failed to mark referral (ID: ${referralId}) as Sent for payment.`,
                    auth,
                    refreshReferrals,
                );
            }}
        >
            Mark as sent for payment
        </Button>
    );
}};
const MARK_AS_REHAB_COMMENCED_ACTION = { name: "markAsCommenced", render: (referralId, auth, refreshReferrals) => {
    return (
        <Button
            onClick={() => {
                handleSentForPayment(
                    referralId,
                    `Referral (ID: ${referralId}) has been successfully marked as Rehabilitation commenced.`,
                    `Failed to mark referral (ID: ${referralId}) as Rehabilitation commenced.`,
                    auth,
                    refreshReferrals,
                );
            }}
        >
            Mark as rehabilitation commenced
        </Button>
    );
}};
const REVERT_TO_COMPLETE_ACTION = { name: "revert", render: (referralId, auth, refreshReferrals) => {
    return (
        <Button
            onClick={() => {
                handleComplete(
                    referralId,
                    `Referral (ID: ${referralId}) has been successfully reverted to Complete status.`,
                    `Failed to revert referral (ID: ${referralId}) to Complete status.`,
                    auth,
                    refreshReferrals,
                );
            }}
        >
            Revert to complete
        </Button>
    );
}};
const ASSIGN_ACTION = { name: "assign", render: (referralId) => {
    return (
        <Button component={RouterLink} to={`/assign-referral/${referralId}`}>Assign RCM</Button>
    );
}};

const ACTIONS_MAP = {
    created: [CANCEL_ACTION, ASSIGN_ACTION],
    accepted: [MARK_AS_COMPLETE_ACTION, CANCEL_ACTION, ASSIGN_ACTION],
    cancelled: [VIEW_ACTION],
    rejected: [VIEW_ACTION],
    completed: [MARK_AS_SENT_ACTION, ASSIGN_ACTION],
    completedForHourlyFee: [MARK_AS_REHAB_COMMENCED_ACTION, ASSIGN_ACTION],
    sentForPayment: [REVERT_TO_COMPLETE_ACTION, ASSIGN_ACTION]
};

function renderActions(referral, auth, refreshReferrals) {
    const assignActionName = "assign"
    const { status, id: referralId, restricted, isFeeHourly } = referral
    const statusKey = status === REFERRAL_STATUSES.COMPLETED && isFeeHourly ? "completedForHourlyFee" : status

    return ACTIONS_MAP[statusKey].map(item => {
        const isAssignAction = item.name === assignActionName
        const displayAction = !isAssignAction || (isAssignAction && !restricted)

        if (displayAction) {
            return (
                <Typography key={item.name}>
                    {item.render(referralId, auth, refreshReferrals)}
                </Typography>
            );
        }

        return null
    })
}

const BuyerDashboard = (props) => {
    const { referrals, statusFilter, sort, refreshReferrals } = props;
    return (
        <DashboardTable referrals={referrals} statusFilter={statusFilter} sort={sort} renderStatus={renderStatus} renderActions={renderActions} refreshReferrals={refreshReferrals} />
    );
};

export default BuyerDashboard;