import { Grid, Typography } from "@mui/material";
import React from "react";
import { getAddressText } from "./InjuredEmployeeDetails";

const ManagerDetails = ({ referral }) => {
    const { detail } = referral;
    const {
      managerSalutation = "",
      managerGivenName = "",
      managerSurname: managerLastName = "",
      managerAgsNumber: managerAgs,
      managerEmail,
      managerWorkPhone: managerPhone,
      managerWorkAddress,
      managerWorkAddressSuburb: managerWorkSuburb,
      managerWorkAddressState: managerWorkState,
      managerWorkAddressPostcode: managerWorkPostcode
    } = detail || {};
  
    const addressText = getAddressText({
      addressLine: managerWorkAddress,
      suburb: managerWorkSuburb,
      state: managerWorkState,
      postcode: managerWorkPostcode
    });

    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">Manager details</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">AGS number</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{managerAgs}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{`${managerSalutation} ${managerGivenName} ${managerLastName}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Work address</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{addressText}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Email</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{managerEmail}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Phone</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{managerPhone}</Typography>
            </Grid>
        </Grid>
    );

};

export default ManagerDetails;
