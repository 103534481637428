import { Box, Link, Typography,Container } from "@mui/material";
import React from "react";

export default function PrivacyPage() {
    return (
        <Container maxWidth="xl">
        <Box
            sx={{
                margin: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
                Privacy statement
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Your privacy is important to Comcare. We will only collect, use or disclose your personal
                information in accordance with the Privacy Act 1988 (Privacy Act). Comcare is authorised to
                collect personal information where relevant to the exercise of its functions and powers under
                the Safety, Rehabilitation and Compensation Act 1988 (SRC Act), associated regulations and
                other legislation.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Comcare is collecting your personal information so we can perform our role as the Lead
                Agency and administrator of the Occupational Rehabilitation and Associated Medical Services
                (ORAMS) Panel and Portal. Collecting your personal information allows Comcare to verify your
                identity and add you to the ORAMS Portal as a user, which enables you to use the Portal to
                perform your role as a representative of the SRC Act rehabilitation authority or an approved
                Panel provider. If Comcare cannot collect your personal information, Comcare cannot give you
                permission to access the ORAMS Portal.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Comcare may use your personal information to monitor the security of the ORAMS Portal,
                understand how users are interacting with the ORAMS Panel and Portal, and to assess and
                improve the services we provide as ORAMS Lead Agency and Portal administrator. Comcare
                may contact you from time to time to invite you to provide feedback about your user
                experience of the ORAMS Panel and Portal.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                Comcare will only disclose your personal information with your consent, when authorised by
                law, when a permitted general situation applies or when otherwise authorised under the
                Privacy Act. Comcare will not disclose your personal information to any overseas recipients.
            </Typography>
            <Typography component="p" variant="body" sx={{ mb: 2 }}>
                For a copy of Comcare's Privacy Policy, to request access to or a change of your personal
                information or to make a privacy complaint please refer to <Link href="https://www.comcare.gov.au/site-information/privacy" target="_blank" rel="noreferrer">
                    comcare.gov.au/privacy 
                </Link>
                . You can
                also contact us on 1300 366 979 or email us at <a href="mailto:privacy@comcare.gov.au">privacy@comcare.gov.au</a>
                . If you have any other
                questions about the Panel or Portal, please contact <a href="mailto:orams@comcare.gov.au">orams@comcare.gov.au</a>
                .
            </Typography>
            <Typography component="p" variant="body2" sx={{ mt: 2 }}>
                Last updated: 14/01/2021
            </Typography>
        </Box>
        </Container>
    );
}