import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { limitWords, STATES } from "../../constants/constants";
import { hideCompensationQuestions, includeOriginalReferralFields, isCode92ServiceType, isCompensationServiceType, isEarlyInterventionType, isExamineeServiceType, isOptionalFieldServiceType, isRehabilitationServiceType } from "../../constants/referralConstants";
import useAuth from "../../hooks/useAuth";

const ServiceDetailsForm = ({ referralInfo, handleOnCancel, handleOnSubmit, handleOnBack, serviceDetails }) => {
    const { error } = useAuth();
    const { control, handleSubmit, reset, getValues } = useForm({
        reValidateMode: "onBlur"
    });
    const { serviceTypeId: serviceId } = referralInfo || {};
    const [ referralType, setReferralType ] = useState();

    useEffect(() => {
        reset(serviceDetails);
    },
    // eslint-disable-next-line
    [ serviceDetails ]);

    const errorHelper = {
        referralType: {
            required: "Is this a compensation (Comcare) referral is required",
        },
        liabilityDetermined: {
            required: "Liability determined is required for compensation case",
        },
        dateOfInjury: {
            required: "Date of injury is required",
            validateDate: "Date of injury is not a valid date",
        },
        injuryDetails: {
            required: "Injury details are required",
            validateWordLimit: "Injury details must be 200 words or fewer"
        },
        employeeCurrentlyAtWork: {
            required: "Person currently at work is required",
        },
        postInjuryHours: {
            required: "Post injury work hours are required",
        },
        postInjuryDuties: {
            required: "Post injury work duties are required",
        },
        rehabAssessmentDate: {
            validateDate: "Rehabilitation assessment date is not a valid date",
        },
        rehabAssessmentTime: {
            required: "Total approved hours are required",
            validateNumberInRange: "Please enter a value between 1 and 35 for number of hours"
        },
        rehabAuthorisedAssessmentCost: {
            required: "Rehabilitation assessment cost is required",
            validateWordLimit: "Rehabilitation assessment cost must be 200 words or fewer"
        },
      };

      return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                ml: 8,
                mr: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '75ch',
                gap: 1,
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}
            {(isEarlyInterventionType(serviceId) || !(isExamineeServiceType(serviceId) || isCompensationServiceType(serviceId))) &&
                <React.Fragment>
                    <Typography variant="h2">Service details</Typography>

                    {!isEarlyInterventionType(serviceId) && !hideCompensationQuestions(serviceId) &&
                        <Controller
                            control={control}
                            name="referralType"
                            defaultValue=""
                            rules={{
                                required: isOptionalFieldServiceType(serviceId) ? false : true
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Autocomplete
                                    id="referralType"
                                    options={[
                                        {
                                            value: `Compensation`,
                                            label: 'Yes'
                                        },
                                        {
                                            value: `Non-Compensation`,
                                            label: 'No'
                                        }
                                    ]}
                                    autoHighlight
                                    getOptionLabel={option => option && option.label ? option.label : ""}
                                    isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={`Is this a compensation (Comcare) referral?${isOptionalFieldServiceType(serviceId) ? ' (optional)' : ''}`}
                                            variant="outlined"
                                            fullWidth
                                            autoFocus
                                            required={!isOptionalFieldServiceType(serviceId)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "disabled" // disable autocomplete and autofill
                                            }}
                                            error={error !== undefined}
                                            helperText={error ? errorHelper.referralType[error.type] : ""}
                                        />
                                    )}
                                    value={field.value ? { value: field.value, label: field.value === "Compensation" ? "Yes" : "No" } : null}
                                    onChange={(_, data) => {setReferralType(data ? data.value : undefined); field.onChange(data ? data.value : undefined);}}
                                />
                            )}
                        />
                    }

                    {referralType && referralType === 'Compensation' &&
                        <React.Fragment>
                            <Controller
                                control={control}
                                name="comcareClaimNumber"
                                defaultValue=""
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        label="Comcare claim number (optional)"
                                        id="comcareClaimNumber"
                                        fullWidth 
                                        autoComplete='nope'
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="liabilityDetermined"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        id="liabilityDetermined"
                                        options={[
                                            {
                                                value: 'Yes',
                                                label: 'Yes'
                                            },
                                            {
                                                value: 'No',
                                                label: 'No'
                                            },
                                            {
                                                value: 'Pending',
                                                label: 'Pending'
                                            }
                                        ]}
                                        autoHighlight
                                        getOptionLabel={option => option && option.label ? option.label : ""}
                                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Liability determined?"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "disabled" // disable autocomplete and autofill
                                                }}
                                                error={error !== undefined}
                                                helperText={error ? errorHelper.liabilityDetermined[error.type] : ""}
                                            />
                                        )}
                                        value={field.value ? { value: field.value, label: field.value } : null}
                                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                                    />
                                )}
                            />
                        </React.Fragment>
                    }

                    <Controller
                        control={control}
                        name="dateOfInjury"
                        defaultValue=""
                        rules={{
                            required: !isEarlyInterventionType(serviceId) && isOptionalFieldServiceType(serviceId) ? false : true,
                            validate: {
                                validateDate: (value) => !value || dayjs(value).isValid(),
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker
                                {...field}
                                label={`Date of injury${!isEarlyInterventionType(serviceId) && isOptionalFieldServiceType(serviceId) ? ' (optional)' : ''}`}
                                name="dateOfInjury"
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        autoFocus={isEarlyInterventionType(serviceId) || hideCompensationQuestions(serviceId)}
                                        fullWidth
                                        required={isEarlyInterventionType(serviceId) || !isOptionalFieldServiceType(serviceId)}
                                        error={error !== undefined} 
                                        helperText={error ? errorHelper.dateOfInjury[error.type] : ""}
                                        autoComplete='nope'
                                    />
                                }
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="injuryDetails"
                        defaultValue=""
                        rules={{
                            required: !isEarlyInterventionType(serviceId) && isOptionalFieldServiceType(serviceId) ? false : true,
                            validate: {
                                validateWordLimit: limitWords(200)
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                required={isEarlyInterventionType(serviceId) || !isOptionalFieldServiceType(serviceId)}
                                label={`Injury details${!isEarlyInterventionType(serviceId) && isOptionalFieldServiceType(serviceId) ? ' (optional)' : ''}`}
                                id="injuryDetails"
                                multiline={true}
                                minRows={4}
                                maxRows={4}
                                fullWidth 
                                error={error !== undefined}
                                helperText={error ? errorHelper.injuryDetails[error.type] : ""}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="employeeCurrentlyAtWork"
                        defaultValue=""
                        rules={{
                            required: isOptionalFieldServiceType(serviceId) ? false : true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                                id="employeeCurrentlyAtWork"
                                options={[
                                    {
                                        value: 'Yes',
                                        label: 'Yes'
                                    },
                                    {
                                        value: 'No',
                                        label: 'No'
                                    },
                                ]}
                                autoHighlight
                                getOptionLabel={option => option && option.label ? option.label : ""}
                                isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={`Person currently at work?${isOptionalFieldServiceType(serviceId) ? ' (optional)' : ''}`}
                                        variant="outlined"
                                        fullWidth
                                        required={!isOptionalFieldServiceType(serviceId)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled" // disable autocomplete and autofill
                                        }}
                                        error={error !== undefined}
                                        helperText={error ? errorHelper.employeeCurrentlyAtWork[error.type] : ""}
                                    />
                                )}
                                value={field.value ? { value: field.value, label: field.value } : null}
                                onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="postInjuryHours"
                        defaultValue=""
                        rules={{
                            required: isOptionalFieldServiceType(serviceId) ? false : true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                                id="postInjuryHours"
                                options={[
                                    {
                                        value: 'Same',
                                        label: 'Same'
                                    },
                                    {
                                        value: 'Reduced',
                                        label: 'Reduced'
                                    },
                                    {
                                        value: 'Altered Hours',
                                        label: 'Altered Hours'
                                    },
                                    {
                                        value: 'Not At Work',
                                        label: 'Not At Work'
                                    }
                                ]}
                                autoHighlight
                                getOptionLabel={option => option && option.label ? option.label : ""}
                                isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={`Post-injury work hours?${isOptionalFieldServiceType(serviceId) ? ' (optional)' : ''}`}
                                        variant="outlined"
                                        fullWidth
                                        required={!isOptionalFieldServiceType(serviceId)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled" // disable autocomplete and autofill
                                        }}
                                        error={error !== undefined}
                                        helperText={error ? errorHelper.postInjuryHours[error.type] : ""}
                                    />
                                )}
                                value={field.value ? { value: field.value, label: field.value } : null}
                                onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="postInjuryDuties"
                        defaultValue=""
                        rules={{
                            required: isOptionalFieldServiceType(serviceId) ? false : true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                                id="postInjuryDuties"
                                options={[
                                    {
                                        value: 'Same',
                                        label: 'Same'
                                    },
                                    {
                                        value: 'Modified Duties',
                                        label: 'Modified Duties'
                                    },
                                    {
                                        value: 'Not At Work',
                                        label: 'Not At Work'
                                    }
                                ]}
                                autoHighlight
                                getOptionLabel={option => option && option.label ? option.label : ""}
                                isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={`Post-injury work duties?${isOptionalFieldServiceType(serviceId) ? ' (optional)' : ''}`}
                                        variant="outlined"
                                        fullWidth
                                        required={!isOptionalFieldServiceType(serviceId)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled" // disable autocomplete and autofill
                                        }}
                                        error={error !== undefined}
                                        helperText={error ? errorHelper.postInjuryDuties[error.type] : ""}
                                    />
                                )}
                                value={field.value ? { value: field.value, label: field.value } : null}
                                onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                            />
                        )}
                    />

                </React.Fragment>
            }

            {(isExamineeServiceType(serviceId) && includeOriginalReferralFields(serviceId)) &&
                <React.Fragment>
                    <Typography variant="h3">Original Referral</Typography>
                    <Controller
                        control={control}
                        name="originalReferralId"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Original referral ID (optional)"
                                id="originalReferralId"
                                fullWidth 
                                autoComplete='nope'
                            />
                        )}
                    />
                </React.Fragment>
            }


            <Typography variant="h3">Assessor's details</Typography>

            <Controller
                control={control}
                name="medicalSpecialistName"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Medical specialist type (optional)"
                        id="medicalSpecialistName"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Typography variant="body">Specialist is a:</Typography>

            <Controller
                control={control}
                name="legallyQualifiedMedicalPractitioner"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Legally qualified medical practitioner"
                    />
                )}
            />

            <Controller
                control={control}
                name="suitablyQualifiedPerson"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Suitably qualified person"
                    />
                )}
            />

            <Controller
                control={control}
                name="panel"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Panel"
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAssessmentVenueAddress"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Rehabilitation venue address (optional)"
                        id="rehabAssessmentVenueAddress"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAssessmentVenueAddressSuburb"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Suburb (optional)"
                        id="rehabAssessmentVenueAddressSuburb"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAssessmentVenueAddressState"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        id="rehabAssessmentVenueAddressState"
                        options={STATES}
                        autoHighlight
                        getOptionLabel={option => option && option.label ? option.label : ""}
                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="State (optional)"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled" // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAssessmentVenueAddressPostcode"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Postcode (optional)"
                        id="rehabAssessmentVenueAddressPostcode"
                        fullWidth 
                        inputProps={{ maxLength: 4 }}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAssessmentVenuePhone"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Rehabilitation venue phone (optional)"
                        id="rehabAssessmentVenuePhone"
                        fullWidth 
                        inputProps={{ maxLength: 50 }}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAssessmentDate"
                defaultValue=""
                rules={{
                    validate: {
                        validateDate: (value) => !value || dayjs(value).isValid(),
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        {...field}
                        label="Rehabilitation assessment date (optional)"
                        name="rehabAssessmentDate"
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                fullWidth
                                error={error !== undefined} 
                                helperText={error ? errorHelper.rehabAssessmentDate[error.type] : ""}
                                autoComplete='nope'
                            />
                        }
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAssessmentTime"
                defaultValue=""
                rules={{
                    required: isCode92ServiceType(serviceId),
                    validate: {
                        validateNumberInRange: (value) => {
                            return !value
                                || !isCode92ServiceType(serviceId)
                                || (Number.isInteger(parseInt(value, 10)) && value > 0 && value < 36)
                        },
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={isCode92ServiceType(serviceId)}
                        label={isCode92ServiceType(serviceId) ? 'Total approved hours' : 'Rehabilitation assessment time (optional)'}
                        id="rehabAssessmentTime"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.rehabAssessmentTime[error.type] : ""}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabProviderToContact"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Rehabilitation provider to contact (optional)"
                    />
                )}
            />

            <Controller
                control={control}
                name="rehabAuthorisedAssessmentCost"
                defaultValue=""
                rules={{
                    required: isRehabilitationServiceType(serviceId) ? true : false,
                    validate: {
                        validateWordLimit: limitWords(200)
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        readOnly={isCode92ServiceType(serviceId)}
                        label={isCode92ServiceType(serviceId) ? 'Total approved Code 92 costs (incl GST)' : `Rehabilitation assessment cost${!isRehabilitationServiceType(serviceId) ? ' (optional)' : ''}`}
                        id="rehabAuthorisedAssessmentCost"
                        multiline={true}
                        minRows={4}
                        maxRows={4}
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.rehabAuthorisedAssessmentCost[error.type] : ""}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="assessor1Name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Assessor 1 name (optional)"
                        id="assessor1Name"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="assessor2Name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Assessor 2 name (optional)"
                        id="assessor2Name"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="assessor3Name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Assessor 3 name (optional)"
                        id="assessor3Name"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="assessorPhone"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Assessor phone or fax (optional)"
                        id="assessorPhone"
                        inputProps={{ maxLength: 50 }}
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    gap: 1,
                }}
            >
                <Button 
                    type="button"
                    variant="contained"
                    onClick={() => {
                        handleOnCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    type="button"
                    variant="contained"
                    onClick={() => {
                        handleOnBack(getValues());
                    }}
                >
                    Back
                </Button>
                <Button type="submit" variant="contained">Continue</Button>
            </Box>
        </Box>
    );
}

export default ServiceDetailsForm;