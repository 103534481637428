import { createContext, useState, useEffect, useMemo, useContext } from "react";
import * as sessionApi from "../api/sessions";
import * as userApi from "../api/users";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext({
  user: null,
  error: null,
  setError: (error) => {},
  success: null,
  setSuccess: (success) => {},
  loading: false,
  loadingInitial: false,
  login: (emailAddress, password) => {},
  logout: () => {},
  signup: (emailAddress, name, handleResponse, forbiddenErrorMessage, notFoundErrorMessage) => {},
  invite: (token, emailAddress, handleResponse) => {},
  createUser: (token, emailAddress, password, handleResponse) => {},
  resetPassword: (token, emailAddress, data, handleResponse) => {},
  requestResetPassword: (emailAddress, handleResponse) => {},
  call: (api, params, handleResponse, errorMessage) => {},
  navigate: (arg) => {},
  location: null,
  contextMap: null,
  setContextMap: (arg) => {},
  clearContext: (arg) => {},
  sessionExpired: null,
  setRequestedLocation: (arg) => {},
});

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [contextMap, setContextMap] = useState(new Map());
  const [requestedLocation, setRequestedLocation] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (error) setError(null);
  },
  // eslint-disable-next-line
  [location.pathname]);

  async function logout() {
    if (user && user.token) {
      const headers = {
        "Authorization": "Bearer " + user.token,
        "Content-Type": "application/json"
      };
  
      sessionApi.logout(headers)
        .then(() => setUser(undefined))
        .catch((_error) => {});
    }
  }

  async function login(emailAddress, password) {
    setLoading(true);

    const navigateTo = requestedLocation || location.state?.prev || "/";

    const headers = {
      "Content-Type": "application/json"
    };

    sessionApi.login(emailAddress, password, headers)
      .then((user) => {
        setUser(user);
        setSuccess(undefined);
        setContextMap(contextMap || new Map());
        setRequestedLocation(undefined);
        navigate(navigateTo);
      })
      .catch((error) => setError(error.data && error.data.message ? error.data.message : 'Failed to login'))
      .finally(() => setLoading(false));
  }

  async function signup(emailAddress, name, primaryUserEmailAddress, handleResponse, forbiddenErrorMessage, notFoundErrorMessage) {
    setLoading(true);

    const headers = {
      "Content-Type": "application/json"
    };

    userApi.signup(emailAddress, name, primaryUserEmailAddress, headers)
      .then((response) => {
        setSuccess(response.message);
        handleResponse(response)
      })
      .catch((error) => {
        if (error.status === 403) {
          setError(forbiddenErrorMessage);
        }
        else if (error.status === 404) {
          setError(notFoundErrorMessage);
        }
        else if (error.status === 409) {
          setError("A user with these details already exists");
        }
        else {
          setError(error.data && error.data.message ? error.data.message : 'Unknown error');
        }
      })
      .finally(() => setLoading(false));
  }

  async function invite(token, emailAddress, handleResponse) {
    setLoading(true);

    const headers = {
      "Authorization": "Bearer " + user.token,
      "Content-Type": "application/json"
    };

    userApi.invite(token, emailAddress, headers)
      .then((response) => {
        handleResponse(response)
      })
      .catch((error) => setError(error.data && error.data.message ? error.data.message : "Not authorised to send invitation"))
      .finally(() => setLoading(false));
  }

  async function createUser(token, emailAddress, password, handleResponse) {
    setLoading(true);

    const headers = {
      "Content-Type": "application/json"
    };

    userApi.create(token, emailAddress, password, headers)
      .then((response) => {
        handleResponse(response)
      })
      .catch((error) => setError(error.data && error.data.message ? error.data.message : 'Unknown error'))
      .finally(() => setLoading(false));
  }

  async function resetPassword(token, emailAddress, data, handleResponse) {
    setLoading(true);

    const headers = {
      "Content-Type": "application/json"
    };

    userApi.resetPassword(token, emailAddress, data, headers)
      .then((response) => {
        handleResponse(response)
      })
      .catch((error) => setError(error.data && error.data.message ? error.data.message : 'Unknown error'))
      .finally(() => setLoading(false));
  }

  async function requestResetPassword(emailAddress, handleResponse) {
    setLoading(true);

    const headers = {
      "Content-Type": "application/json"
    };

    userApi.requestPasswordReset({ emailAddress }, headers)
      .then((response) => {
        handleResponse(response)
      })
      .catch((error) => {
        if (error.status === 404) {
          setError('No user found with these details');
        }
        else {
          setError(error.data && error.data.message ? error.data.message : 'Unknown error');
        }
      })
      .finally(() => setLoading(false));
  }

  async function call(api, params, handleResponse, errorMessage) {
    if (!params || !params['noloading']) {
      setLoading(true);
      setError(undefined);
    }

    const headers = {
      "Authorization": "Bearer " + user.token,
      "Content-Type": "application/json"
    };

    api(params, headers)
      .then((response) => {
        handleResponse(response)
      })
      .catch((error) => {
        if (error.status === 401) {
          setSessionExpired(true);
          setRequestedLocation(`${location.pathname}${location.search}`);
          setUser(undefined);
        }
        else if (error.status === 403) {
          setError(errorMessage ? errorMessage : 'Access to resource or action is forbidden');
        }
        else if (error.status === 404) {
          setError(errorMessage ? errorMessage : 'Resource is not found');
        }
        else if (error.status === 409) {
          setError(errorMessage ? errorMessage : 'Action will result in a resource conflict');
        }
        else {
          setError(errorMessage ? errorMessage : (error.data ? error.data.message : 'Unknown error'));
        }
      })
      .finally(() => setLoading(false));
  }

  function clearContext(key) {
    if (contextMap.delete(key)) {
      setContextMap(new Map(contextMap));
    }
  }

  const context = useMemo(() => ({
      user: user,
      error: error,
      setError: setError,
      success: success,
      setSuccess: setSuccess,
      loading: loading,
      loadingInitial: loadingInitial,
      login: login,
      logout: logout,
      signup: signup,
      invite: invite,
      createUser: createUser,
      resetPassword: resetPassword,
      requestResetPassword: requestResetPassword,
      call: call,
      navigate: navigate,
      location: location,
      contextMap: contextMap,
      setContextMap: setContextMap,
      clearContext: clearContext,
      sessionExpired: sessionExpired,
      setRequestedLocation: setRequestedLocation,
    }),
    // eslint-disable-next-line
    [user, error, success, loading, loadingInitial, location, contextMap, sessionExpired]
  );

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
};