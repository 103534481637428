import useAuth from "./useAuth";

const DEFAULT_URL = '/'

const userTypeHomeUrlMap = {
  admin: '/admin',
  buyer: '/seller-catalogue',
  supplier: '/dashboard/new',
  'primary-user': '/seller-catalogue'
}

const userTypeNavLinksMap = {
  admin: [
    {
      key: 'documents',
      url: '/documents',
      text: 'Documents'
    },
    {
      key: 'admin',
      url: '/admin',
      text: 'Admin'
    },
    {
      key: 'dashboard',
      url: '/dashboard/created',
      text: 'Dashboard'
    },
    {
      key: 'seller-catalogue',
      url: '/seller-catalogue',
      text: 'Service Matrix'
    },
    {
      key: 'price-history',
      url: '/price-history',
      text: 'Price history'
    },
    {
      key: 'price-history-2016',
      url: '/price-history-2016',
      text: '2016 panel prices'
    }
  ],
  buyer: [
    {
      key: 'documents',
      url: '/documents',
      text: 'Documents'
    },
    {
      key: 'dashboard',
      url: '/dashboard/created',
      text: 'Dashboard'
    },
    {
      key: 'seller-catalogue',
      url: '/seller-catalogue',
      text: 'Service Matrix'
    },
    {
      key: 'price-history',
      url: '/price-history',
      text: 'Price history'
    },
    {
      key: 'price-history-2016',
      url: '/price-history-2016',
      text: '2016 panel prices'
    }
  ],
  'primary-user': [
    {
      key: 'documents',
      url: '/documents',
      text: 'Documents'
    },
    {
      key: 'admin',
      url: '/admin',
      text: 'Admin'
    },
    {
      key: 'dashboard',
      url: '/dashboard/created',
      text: 'Dashboard'
    },
    {
      key: 'seller-catalogue',
      url: '/seller-catalogue',
      text: 'Service Matrix'
    },
    {
      key: 'price-history',
      url: '/price-history',
      text: 'Price history'
    },
    {
      key: 'price-history-2016',
      url: '/price-history-2016',
      text: '2016 panel prices'
    }
  ],
  supplier: [
    {
      key: 'documents',
      url: '/documents',
      text: 'Documents'
    },
    {
      key: 'dashboard',
      url: '/dashboard/new',
      text: 'Dashboard'
    },
    {
      key: 'edit-profile',
      url: '/edit-profile',
      text: 'Edit Profile'
    }
  ]
}

const logoutLink = {
  key: 'logout',
  url: '/logout',
  text: 'Sign out'
}

const dashboardUserTypeNavLinksMap = {
  admin: [
    {
      key: 'created',
      url: '/dashboard/created',
      text: 'Created'
    },
    {
      key: 'accepted',
      url: '/dashboard/accepted',
      text: 'Accepted'
    },
    {
      key: 'past',
      url: '/dashboard/past',
      text: 'Past'
    },
  ],
  buyer: [
    {
      key: 'created',
      url: '/dashboard/created',
      text: 'Created'
    },
    {
      key: 'accepted',
      url: '/dashboard/accepted',
      text: 'Accepted'
    },
    {
      key: 'past',
      url: '/dashboard/past',
      text: 'Past'
    },
  ],
  'primary-user': [
    {
      key: 'created',
      url: '/dashboard/created',
      text: 'Created'
    },
    {
      key: 'accepted',
      url: '/dashboard/accepted',
      text: 'Accepted'
    },
    {
      key: 'past',
      url: '/dashboard/past',
      text: 'Past'
    },
    {
      key: 'team-created',
      url: '/dashboard/team-created',
      text: 'Team Created'
    },
    {
      key: 'team-accepted',
      url: '/dashboard/team-accepted',
      text: 'Team Accepted'
    },
    {
      key: 'team-past',
      url: '/dashboard/team-past',
      text: 'Team Past'
    },
  ],
  supplier: [
    {
      key: 'new',
      url: '/dashboard/new',
      text: 'New'
    },
    {
      key: 'accepted',
      url: '/dashboard/accepted',
      text: 'Accepted'
    },
    {
      key: 'past',
      url: '/dashboard/past',
      text: 'Past'
    },
  ]
}

export function getDashboardStatus(dashboardAction) {
  let status = dashboardAction;
  let wholeOrg = false;
  switch(dashboardAction) {
      case "new":
          status = "created";
          break;
      case "team-created":
          status = "created";
          wholeOrg = true;
          break;
      case "team-accepted":
          status = "accepted";
          wholeOrg = true;
          break;
      case "team-past":
          status = "past";
          wholeOrg = true;
          break;
      default:
  }
  return { status, wholeOrg };
}

export default function useUser() {
    const { user } = useAuth();

    let userTypeHomeUrl = DEFAULT_URL;
    let userTypeNavLinks = [
        {
          key: 'signup',
          url: '/signup',
          text: 'Sign up'
        },
        {
          key: 'login',
          url: '/login',
          text: 'Sign in'
        }
    ];
    let dashboardUserTypeNavLinks = [];

    if (user && user.token) {
        userTypeHomeUrl = userTypeHomeUrlMap[user.role];
        if (user.role) {
            const navLinks = userTypeNavLinksMap[user.role];
            userTypeNavLinks =  [...navLinks, logoutLink];
            dashboardUserTypeNavLinks = dashboardUserTypeNavLinksMap[user.role];
        }
        else {
            userTypeNavLinks =  [logoutLink];
        }
    }

    return { userTypeHomeUrl, userTypeNavLinks, dashboardUserTypeNavLinks, getDashboardStatus };
};