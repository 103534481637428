import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import * as supplierApi from "../api/supplier";
import { Box, Tab, Tabs } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { useForm } from 'react-hook-form';
import SupplierBusinessDetailsForm from "../components/SupplierBuilder/SupplierBusinessDetailsForm";
import SupplierContactDetailsForm from "../components/SupplierBuilder/SupplierContactDetailsForm";
import PricingDetails from "../components/Pricing/PricingDetails";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
            <React.Fragment>
                {children}
            </React.Fragment>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function SupplierProfilePage() {
    const { user, call } = useAuth();
    const { handleSubmit, control, reset } = useForm({
        reValidateMode: "onBlur"
    });
    const [ supplierDetails, setSupplierDetails ] = useState();
    const [ tabValue, setTabValue] = useState(0);
    const [ success, setSuccess ] = useState();

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const updateSupplierDetails = (supplier) => {
        setSupplierDetails({
            supplierAbn: supplier.abn,
            supplierBusinessName: supplier.name,
            supplierBusinessSummary:  supplier.summary ? supplier.summary : '',
            supplierPrimaryAddress: supplier.address.addressLine,
            supplierPrimaryAddressSuburb: supplier.address.suburb,
            supplierPrimaryAddressState: supplier.address.state,
            supplierPrimaryAddressPostcode: supplier.address.postcode,
            supplierLinkedinUrl: supplier.linkedIn ? supplier.linkedIn : '',
            supplierUrl: supplier.website,
            supplierContactName: supplier.contactName,
            supplierContactEmail: supplier.contactEmail,
            supplierContactPhone: supplier.contactPhone,
            supplierRepresentativeName: supplier.representative ? supplier.representative : '',
            supplierRepresentativeEmail: supplier.representativeEmail ? supplier.representativeEmail : '',
            supplierRepresentativePhone: supplier.representativePhone ? supplier.representativePhone : '',
        });
    };
  
    useEffect(() => {
        call(supplierApi.get, { id: user.providerCode }, (response) => {
            if (response) {
                updateSupplierDetails(response);
            }
        });
    },
    // eslint-disable-next-line
    []);

    useEffect(() => {
        reset(supplierDetails);
    },
    // eslint-disable-next-line
    [ supplierDetails, tabValue ]);

    const handleOnSubmit = (supplier) => {
        const supplierToPost = {
            abn: supplier.supplierAbn,
            name: supplier.supplierBusinessName,
            summary: supplier.supplierBusinessSummary,
            address: {
                addressLine: supplier.supplierPrimaryAddress,
                suburb: supplier.supplierPrimaryAddressSuburb,
                state: supplier.supplierPrimaryAddressState,
                postcode: supplier.supplierPrimaryAddressPostcode,
                country: 'Australia',
            },
            website: supplier.supplierUrl,
            linkedIn: supplier.supplierLinkedinUrl,
            contactName: supplier.supplierContactName,
            contactEmail: supplier.supplierContactEmail,
            contactPhone: supplier.supplierContactPhone,
            representative: supplier.supplierRepresentativeName,
            representativeEmail: supplier.supplierRepresentativeEmail,
            representativePhone: supplier.supplierRepresentativePhone,
        };

        setSuccess(undefined);
        call(supplierApi.update, { id: user.providerCode, data: supplierToPost }, (response) => {
            updateSupplierDetails(response);
            setSuccess('Profile Updated.');
        });
    };

    return (
        <Box sx={{margin: 8, display: 'flex', flexDirection: 'column', alignItems: 'left', }} >
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    display: 'flex',
                }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        }}
                >
                    <Tab label="Business details" {...a11yProps(0)} />
                    <Tab label="Contacts" {...a11yProps(1)} />
                    <Tab label="Pricing" {...a11yProps(2)} />
                </Tabs>
                <Box
                    component="form"
                    sx={{
                        mt: 1,
                    }}
                >
                    <TabPanel value={tabValue} index={0}>
                        <SupplierBusinessDetailsForm control={control} success={success}/>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'left',
                                ml: 8,
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleSubmit(handleOnSubmit)}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Update Profile
                            </Button>
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <SupplierContactDetailsForm control={control} success={success} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'left',
                                ml: 8,
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleSubmit(handleOnSubmit)}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Update Profile
                            </Button>
                        </Box>
                    </TabPanel>
                </Box>
                <TabPanel value={tabValue} index={2}>
                    <PricingDetails />
                </TabPanel>
            </Box>
        </Box>
    );
}

export default SupplierProfilePage;