import { Box, Grid, List, ListItem, ListItemButton, Container, CssBaseline } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";



const defaultProps = {
    borderColor: '#a4c8e1',
  };
export default function Footer() {
    return (
        <Box borderTop={1} {...defaultProps}
           sx={{ 
            flexGrow: 1,
            
            width: "100%",
            height: "auto",
            backgroundColor: "#ffffff",
            color: '#4d5156',
            paddingTop: "1rem",
            paddingBottom: "1rem",
        }}><React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item sx={{
                     flexGrow: 1,
                     ml: { xs: 1,sm: 1, md: 3, lg: 4, xl: 8},   
                }}>
                    <List  >
                        <ListItem sx={{width:"auto", display:"inline-block"}}>
                            <ListItemButton  component={Link} to="/about" sx={{ paddingLeft:2 }}>
                                ORAMS
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{width:"auto", display:"inline-block"}}>
                            <ListItemButton  component={Link} to="/terms-of-use">
                                Terms of Use
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{width:"auto", display:"inline-block"}}>
                            <ListItemButton component={Link} to="/privacy-policy">
                                Privacy
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{width:"auto", display:"inline-block"}}>
                            <ListItemButton component={Link} to="/security">
                                Security
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{width:"auto", display:"inline-block"}}>
                            <ListItemButton component={Link} to="/disclaimer">
                                Disclaimer
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{width:"auto", display:"inline-block", fontFamily:"sans-serif"}}>
                            <ListItemButton component={Link} to="/copyright">
                                Copyright
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>
               
            </Grid>
            </Container>
    </React.Fragment>
        </Box>
        
    );
}