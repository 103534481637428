import { Alert, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { STATES } from "../../constants/constants";
import { hideCompensationQuestions, isCompensationServiceType, isEarlyInterventionType, isExamineeServiceType } from "../../constants/referralConstants";
import { VALID_EMAIL_ADDRESS_PATTERN } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";

const PersonalDetailsForm = ({ referralInfo, handleOnCancel, handleOnSubmit, personalDetails }) => {
    const { error } = useAuth();
    const { control, handleSubmit, reset } = useForm({
        reValidateMode: "onBlur"
    });
    const { serviceTypeId } = referralInfo || {};

    useEffect(() => {
        reset(personalDetails);
    },
    // eslint-disable-next-line
    [ personalDetails ]);

    const errorHelper = {
        employeeGivenName: {
            required: "Given name is required",
        },
        employeeSurname: {
            required: "Family name is required",
        },
        employeeGender: {
            required: "Gender is required",
        },
        employeePhone: {
            required: "Phone number is required",
        },
        employeeEmail: {
            required: "Email address is required",
            pattern: "Invalid email address"
        },
        employeeCurrentJobStatus: {
            required: "Current job status is required",
        },
        employeeCurrentEmploymentType: {
            required: "Current employment type is required",
        },
        employeeDateOfBirth: {
            required: "Date of birth is required",
            validateDate: "Date of birth is not a valid date",
        },
        employeeWorkPhone: {
            required: "Work phone is required",
        },
        managerGivenName: {
            required: "Given name is required",
        },
        managerSurname: {
            required: "Last name is required",
        },
        managerWorkAddress: {
            required: "Manager's address is required",
        },
        managerWorkAddressSuburb: {
            required: "Manager's suburb is required",
        },
        managerWorkAddressState: {
            required: "Manager's state is required",
        },
        managerWorkAddressPostcode: {
            required: "Manager's postcode is required",
        },
        managerWorkPhone: {
            required: "Manager's work phone is required",
        },
        managerEmail: {
            required: "Email address is required",
            pattern: "Invalid email address"
        },
        
      };
    
    return (
        <Box
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
            sx={{
                ml: 8,
                mr: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '75ch',
                gap: 1,
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}
            <Typography variant="subtitle1" sx={{fontWeight:700}}>{isExamineeServiceType(serviceTypeId) ? `Examinee details` : `Injured worker's details`}</Typography>

            <Controller
                control={control}
                name="employeeSalutation"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        autoFocus
                        label="Salutation (optional)"
                        id="employeeSalutation"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.employeeSalutation[error.type] : ""}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeGivenName"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Given name"
                        id="employeeGivenName"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.employeeGivenName[error.type] : ""}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeSurname"
                defaultValue=""
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Family name"
                        id="employeeSurname"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.employeeSurname[error.type] : ""}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="restrictedPerson"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Is restricted person?"
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeGender"
                defaultValue=""
                rules={{
                    required: true
                }}
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        id="employeeGender"
                        options={[
                            {value: "Female", label: "F (Female)"},
                            {value: "Male", label: "M (Male)"},
                            {value: "X", label: "X (Indeterminate/Intersex/Unspecified)"},
                        ]}
                        autoHighlight
                        getOptionLabel={option => option && option.label ? option.label : ""}
                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={`Gender`}
                                variant="outlined"
                                required
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled" // disable autocomplete and autofill
                                }}
                                error={error !== undefined}
                                helperText={error ? errorHelper.employeeGender[error.type] : ""}
                            />
                        )}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                    />
                )}
            />

            <Controller
                control={control}
                name="employeePhone"
                defaultValue=""
                rules={{
                    required: isExamineeServiceType(serviceTypeId) ? false : true
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={!isExamineeServiceType(serviceTypeId)}
                        label={`Phone number${isExamineeServiceType(serviceTypeId) ? ' (optional)' : ''}`}
                        id="employeePhone"
                        fullWidth 
                        inputProps={{ maxLength: 50 }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.employeePhone[error.type] : ""}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeEmail"
                defaultValue=""
                rules={{
                    required: isExamineeServiceType(serviceTypeId) ? false : true,
                    pattern: VALID_EMAIL_ADDRESS_PATTERN
                }}
                render={({ field, fieldState: { error } }) => (
                    <FormControl>
                        {!isEarlyInterventionType(serviceTypeId) &&
                            !isExamineeServiceType(serviceTypeId) &&
                            !isCompensationServiceType(serviceTypeId) &&
                            !hideCompensationQuestions(serviceTypeId) &&
                            <FormHelperText sx={{mb: 1}}>Confirm recipient. An email notification may be sent to listed address</FormHelperText>
                        }
                        <TextField
                            {...field}
                            required={!isExamineeServiceType(serviceTypeId)}
                            label={`Email address${isExamineeServiceType(serviceTypeId) ? ' (optional)' : ''}`}
                            id="employeeEmail"
                            fullWidth 
                            error={error !== undefined}
                            helperText={error ? errorHelper.employeeEmail[error.type] : ""}
                            autoComplete='nope'
                        />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="employeeHomeAddress"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Home address (optional)"
                        id="employeeHomeAddress"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeHomeAddressSuburb"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Suburb (optional)"
                        id="employeeHomeAddressSuburb"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeHomeAddressState"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        id="employeeHomeAddressState"
                        options={STATES}
                        autoHighlight
                        getOptionLabel={option => option && option.label ? option.label : ""}
                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="State (optional)"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled" // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeHomeAddressPostcode"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Postcode (optional)"
                        id="employeeHomeAddressPostcode"
                        fullWidth 
                        inputProps={{ maxLength: 4 }}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="requiresInterpreter"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Interpreter required?"
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeInterpreterLanguage"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Interpreter language (optional)"
                        id="employeeInterpreterLanguage"
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        autoComplete='nope'
                    />
                )}
            />

            <Typography variant="subtitle1" sx={{fontWeight:700}}>{isExamineeServiceType(serviceTypeId) ? `Examinee's job` : `Injured worker's job`}</Typography>

            <Controller
                control={control}
                name="employeeAgsContractorNumber"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="AGS number or Contractor number (optional)"
                        id="employeeAgsContractorNumber"
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeCurrentJobStatus"
                defaultValue=""
                rules={{
                    required: isExamineeServiceType(serviceTypeId) ? false : true
                }}
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        id="employeeCurrentJobStatus"
                        options={[
                            {
                                value: `Ongoing`,
                                label: 'Ongoing'
                            },
                            {
                                value: `Non Ongoing`,
                                label: 'Non Ongoing'
                            },
                            {
                                value: `Contractor`,
                                label: 'Contractor'
                            },
                            {
                                value: `Former Employee`,
                                label: 'Former Employee'
                            }
                        ]}
                        autoHighlight
                        getOptionLabel={option => option && option.label ? option.label : ""}
                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={`Current job status${isExamineeServiceType(serviceTypeId) ? ' (optional)' : ''}`}
                                variant="outlined"
                                required={!isExamineeServiceType(serviceTypeId)}
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled" // disable autocomplete and autofill
                                }}
                                error={error !== undefined}
                                helperText={error ? errorHelper.employeeCurrentJobStatus[error.type] : ""}
                            />
                        )}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeCurrentEmploymentType"
                defaultValue=""
                rules={{
                    required: isExamineeServiceType(serviceTypeId) ? false : true
                }}
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        id="employeeCurrentEmploymentType"
                        options={[
                            {
                                value: `Full Time`,
                                label: 'Full Time'
                            },
                            {
                                value: `Part Time`,
                                label: 'Part Time'
                            },
                            {
                                value: `Casual`,
                                label: 'Casual'
                            },
                            {
                                value: `Not Employed`,
                                label: 'Not Employed'
                            }
                        ]}
                        autoHighlight
                        getOptionLabel={option => option && option.label ? option.label : ""}
                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={`Current employment type${isExamineeServiceType(serviceTypeId) ? ' (optional)' : ''}`}
                                variant="outlined"
                                fullWidth
                                required={!isExamineeServiceType(serviceTypeId)}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled" // disable autocomplete and autofill
                                }}
                                error={error !== undefined}
                                helperText={error ? errorHelper.employeeCurrentEmploymentType[error.type] : ""}
                            />
                        )}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeJobTitle"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Job title (optional)"
                        id="employeeJobTitle"
                        fullWidth
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeDateOfBirth"
                defaultValue=""
                rules={{
                    required: isExamineeServiceType(serviceTypeId) ? false : true,
                    validate: {
                        validateDate: (value) => !value || dayjs(value).isValid(),
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        {...field}
                        label={`Employee date of birth${isExamineeServiceType(serviceTypeId) ? ' (optional)' : ''}`}
                        name="employeeDateOfBirth"
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required={!isExamineeServiceType(serviceTypeId)}
                                fullWidth 
                                error={error !== undefined} 
                                helperText={error ? errorHelper.employeeDateOfBirth[error.type] : ""}
                                autoComplete='nope'
                            />
                        }
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeSectionAndBranch"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Section and branch (optional)"
                        id="employeeSectionAndBranch"
                        fullWidth
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeLocationInPremises"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Location in premises (optional)"
                        id="employeeLocationInPremises"
                        fullWidth
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeWorkAddress"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Work address (optional)"
                        id="employeeWorkAddress"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeWorkAddressSuburb"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Suburb (optional)"
                        id="employeeWorkAddressSuburb"
                        fullWidth 
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeWorkAddressState"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        id="employeeWorkAddressState"
                        options={STATES}
                        autoHighlight
                        getOptionLabel={option => option && option.label ? option.label : ""}
                        isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="State (optional)"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled" // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeWorkAddressPostcode"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Postcode (optional)"
                        id="employeeWorkAddressPostcode"
                        fullWidth 
                        inputProps={{ maxLength: 4 }}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="employeeWorkPhone"
                defaultValue=""
                rules={{
                    required: isExamineeServiceType(serviceTypeId) ? false : true
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required={!isExamineeServiceType(serviceTypeId)}
                        label={`Work phone${isExamineeServiceType(serviceTypeId) ? ' (optional)' : ''}`}
                        id="employeeWorkPhone"
                        fullWidth 
                        inputProps={{ maxLength: 50 }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.employeeWorkPhone[error.type] : ""}
                        autoComplete='nope'
                    />
                )}
            />

            <Controller
                control={control}
                name="callCentreEmployee"
                defaultValue={false}
                render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value ? field.value : false}
                            />
                        }
                        label="Is call centre employee?"
                    />
                )}
            />
            {!isExamineeServiceType(serviceTypeId) &&
                <React.Fragment>
                    <Typography variant="subtitle1" sx={{fontWeight:700}}>{`Injured worker's manager`}</Typography>

                    <Controller
                        control={control}
                        name="managerAgsNumber"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="AGS number (optional)"
                                id="managerAgsNumber"
                                fullWidth 
                                inputProps={{ maxLength: 15 }}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerSalutation"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Salutation (optional)"
                                id="managerSalutation"
                                fullWidth 
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerGivenName"
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                required
                                label="Given name"
                                id="managerGivenName"
                                fullWidth 
                                error={error !== undefined}
                                helperText={error ? errorHelper.managerGivenName[error.type] : ""}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerSurname"
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                required
                                label="Last name"
                                id="managerSurname"
                                fullWidth 
                                error={error !== undefined}
                                helperText={error ? errorHelper.managerSurname[error.type] : ""}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerWorkAddress"
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                required
                                label="Address"
                                id="managerWorkAddress"
                                fullWidth 
                                error={error !== undefined}
                                helperText={error ? errorHelper.managerWorkAddress[error.type] : ""}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerWorkAddressSuburb"
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                required
                                label="Suburb"
                                id="managerWorkAddressSuburb"
                                fullWidth 
                                error={error !== undefined}
                                helperText={error ? errorHelper.managerWorkAddressSuburb[error.type] : ""}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerWorkAddressState"
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                                id="managerWorkAddressState"
                                options={STATES}
                                autoHighlight
                                getOptionLabel={option => option && option.label ? option.label : ""}
                                isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        required
                                        label="State"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled" // disable autocomplete and autofill
                                        }}
                                        error={error !== undefined}
                                        helperText={error ? errorHelper.managerWorkAddressState[error.type] : ""}
                                    />
                                )}
                                value={field.value ? { value: field.value, label: field.value } : null}
                                onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerWorkAddressPostcode"
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                required
                                label="Postcode"
                                id="managerWorkAddressPostcode"
                                fullWidth 
                                inputProps={{ maxLength: 4 }}
                                error={error !== undefined}
                                helperText={error ? errorHelper.managerWorkAddressPostcode[error.type] : ""}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerEmail"
                        defaultValue=""
                        rules={{
                            required: false,
                            pattern: VALID_EMAIL_ADDRESS_PATTERN
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                {!isEarlyInterventionType(serviceTypeId) &&
                                    !isExamineeServiceType(serviceTypeId) &&
                                    !isCompensationServiceType(serviceTypeId) &&
                                    !hideCompensationQuestions(serviceTypeId) &&
                                    <FormHelperText sx={{mb: 1}}>Confirm recipient. An email notification may be sent to listed address</FormHelperText>
                                }
                                <TextField
                                    {...field}
                                    required={false}
                                    label={`Email`}
                                    id="managerEmail"
                                    fullWidth 
                                    error={error !== undefined}
                                    helperText={error ? errorHelper.managerEmail[error.type] : ""}
                                    autoComplete='nope'
                                />
                            </FormControl>
                        )}
                    />

                    <Controller
                        control={control}
                        name="managerWorkPhone"
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                required
                                label="Work phone"
                                id="managerWorkPhone"
                                fullWidth 
                                inputProps={{ maxLength: 50 }}
                                error={error !== undefined}
                                helperText={error ? errorHelper.managerWorkPhone[error.type] : ""}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Typography variant="subtitle1" sx={{fontWeight:700}}>{`Injured worker's treating doctor`}</Typography>

                    <Controller
                        control={control}
                        name="treatingDoctorName"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Name (optional)"
                                id="treatingDoctorName"
                                fullWidth 
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="treatingDoctorPhoneNumber"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Phone (optional)"
                                id="treatingDoctorPhoneNumber"
                                fullWidth 
                                inputProps={{ maxLength: 50 }}
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="treatingDoctorFaxNumber"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Fax (optional)"
                                id="treatingDoctorFaxNumber"
                                fullWidth 
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="treatingDoctorAddress"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Address (optional)"
                                id="treatingDoctorAddress"
                                fullWidth 
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="treatingDoctorAddressSuburb"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Suburb (optional)"
                                id="treatingDoctorAddressSuburb"
                                fullWidth 
                                autoComplete='nope'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="treatingDoctorAddressState"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                                id="treatingDoctorAddressState"
                                options={STATES}
                                autoHighlight
                                getOptionLabel={option => option && option.label ? option.label : ""}
                                isOptionEqualToValue={(option, value) => option && value && option.value === value.value}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="State (optional)"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled" // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={field.value ? { value: field.value, label: field.value } : null}
                                onChange={(_, data) => {field.onChange(data ? data.value : undefined);}}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="treatingDoctorAddressPostcode"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Postcode (optional)"
                                id="treatingDoctorAddressPostcode"
                                fullWidth 
                                inputProps={{ maxLength: 4 }}
                                autoComplete='nope'
                            />
                        )}
                    />

                </React.Fragment>
            }

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    gap: 1,
                }}
            >
                <Button 
                    type="button"
                    variant="contained"
                    onClick={() => {
                        handleOnCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button type="submit" variant="contained">Continue</Button>
            </Box>
        </Box>
    );
}

export default PersonalDetailsForm;