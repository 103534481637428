import { Alert, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Controller } from "react-hook-form";
import { validateWhitespaceForRequired } from "../../constants/constants";
import { VALID_EMAIL_ADDRESS_PATTERN } from "../../constants/constants";
import { VALID_PHONE_NUMBER_PATTERN } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";

const SupplierContactDetailsForm = ({ control, success }) => {
    const { error } = useAuth();

    const errorHelper = {
        supplierContactName: {
            required: "Business contact is required",
            validateWhitespaceForRequired: "Business contact is required",
        },
        supplierContactEmail: {
            required: "Email is required",
            pattern: "Invalid email address"
        },
        supplierContactPhone: {
            required: "Phone is required",
            pattern: "Invalid phone number"
        },
        supplierRepresentativeName: {
            required: "Authorised representative name is required",
            validateWhitespaceForRequired: "Authorised representative name is required",
        },
        supplierRepresentativeEmail: {
            required: "Email is required",
            pattern: "Invalid email address"
        },
        supplierRepresentativePhone: {
            required: "Phone is required",
            pattern: "Invalid phone number"
        },
      };
    
    return (
        <Box
            sx={{
                ml: 8,
                mr: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '75ch',
                gap: 2,
            }}
        >
            <Typography variant="h1">
                Your profile
            </Typography>
            <Typography variant="h2" sx={{ mb: 2 }}>
                Edit your contact details
            </Typography>

            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            <Controller
                control={control}
                name="supplierContactName"
                defaultValue=""
                rules={{
                    required: true,
                    validate: {
                        validateWhitespaceForRequired: validateWhitespaceForRequired,
                    }
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Business contact name"
                        id="supplierContactName"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierContactName[error.type] : "The contact listed on your seller profile page."}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierContactEmail"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: VALID_EMAIL_ADDRESS_PATTERN
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Email"
                        id="supplierContactEmail"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierContactEmail[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierContactPhone"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: VALID_PHONE_NUMBER_PATTERN
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Phone"
                        id="supplierContactPhone"
                        fullWidth 
                        inputProps={{ maxLength: 50 }}
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierContactPhone[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierRepresentativeName"
                defaultValue=""
                rules={{
                    required: true,
                    validate: {
                        validateWhitespaceForRequired: validateWhitespaceForRequired,
                    }
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Authorised representative name"
                        id="supplierRepresentativeName"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierRepresentativeName[error.type] : "This is the person authorised to enter into contracts on behalf of the business."}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierRepresentativeEmail"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: VALID_EMAIL_ADDRESS_PATTERN
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Email"
                        id="supplierRepresentativeEmail"
                        fullWidth 
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierRepresentativeEmail[error.type] : ""}
                    />
                )}
            />

            <Controller
                control={control}
                name="supplierRepresentativePhone"
                defaultValue=""
                rules={{
                    required: true,
                    pattern: VALID_PHONE_NUMBER_PATTERN
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        required
                        label="Phone"
                        id="supplierRepresentativePhone"
                        fullWidth
                        error={error !== undefined}
                        helperText={error ? errorHelper.supplierRepresentativePhone[error.type] : ""}
                    />
                )}
            />
        </Box>
    );
}

export default SupplierContactDetailsForm;