import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import useAuth from "../../hooks/useAuth";
import { ROLE_ADMIN } from "../../constants/userConstants";

const ServiceEditList = ({ services, handleServiceSelect, handleUpdateByCpiSelect }) => {
    const { user } = useAuth();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 2,
            }}
        >
            <Typography variant="h2" sx={{ mb: 2 }}>Select the service you want to edit</Typography>
            {services &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    {services.map((service) =>
                        service.currentSubTypes.map((subCategory) =>
                            <Box
                                key={subCategory.id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                }}
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        handleServiceSelect(service.id, subCategory.id, service.name, subCategory.name);
                                    }}
                                >
                                    {subCategory.name ? `${service.name} (${subCategory.name})` : `${service.name}`}
                                </Link>
                            </Box>
                        )
                    )}
                    {user && user.role === ROLE_ADMIN &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'left',
                                gap: 1,
                                mt: 2,
                            }}
                        >
                            <Button
                                type="button"
                                variant="contained"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleUpdateByCpiSelect();
                                }}
                            >
                                Update all services via the nominated CPI amount
                            </Button>
                        </Box>
                    }
                </Box>
            }
        </Box>
    );
}

export default ServiceEditList;