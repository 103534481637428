import { CssBaseline } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import LoginPage from "./LoginPage";

const DEFAULT_URL = '/'

const userTypeHomeUrlMap = {
    admin: '/admin',
    buyer: '/seller-catalogue',
    supplier: '/dashboard/new',
    'primary-user': '/seller-catalogue'
  }
  
const getHomeUrlByUserType = (userType) => {
    if (userType) {
        return userTypeHomeUrlMap[userType];
    }

    return DEFAULT_URL;
};

export default function HomePage() {
  const { user } = useAuth();
  if (user && user.token) {
    const homeUrl = getHomeUrlByUserType(user.role);

    return <Navigate to={homeUrl} />
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <LoginPage  />
    </React.Fragment>
  );
}
  
