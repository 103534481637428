import redaxios from "redaxios";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function get({ id }, headers) {
    const response = await redaxios.get(`${API}/providers/${id}`, {
        headers: headers,
    });
    return response.data;
}

export async function update({ id, data }, headers) {
    const response = await redaxios.post(`${API}/providers/${id}`, {
            ...data,
        }, {
            headers: headers,
        }
    );
    return response.data;
}
