import { createTheme } from '@mui/material/styles';
import "./index.css"

export const theme = createTheme({
  palette: {
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 4.5,
  },

  components: {
    // Name of the component
    MuiAppBar: {
      styleOverrides: {
        root:  {
              backgroundColor: '#f4f7ff',
              color: '#1a304d',
              
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: '3rem',
            color: '#1a304d',
            fontWeight:600,
            fontFamily: [
              'Montserrat',
              'sans-serif',
            ].join(','),
          },
        
        h2: {
          fontSize:'2.25rem',
          color: '#1a304d',
          fontWeight:600,
          fontFamily: [
            'Montserrat',
            'sans-serif',
          ].join(','),
        },
        h3: {
          fontSize: '1.4rem',
          color: '#1a304d',
          fontWeight:700,
          fontFamily: [
            'Montserrat',
            'sans-serif',
          ].join(','),
        },
        p: {
          color: '#242629',
          fontSize: '1.02rem',
          fontFamily: [
            'Avant guard',
            'Helvetica',
            'Arial',
          ].join(','),
        },
      },
    },
  },
  
 

});

