import dayjs from "dayjs";
import redaxios from "redaxios";
import { ROLE_ADMIN, ROLE_PRIMARY_USER } from "../constants/userConstants";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function referralsReport({ startDate, endDate, user }, headers) {
    let dateFilter = ''
    if (startDate && endDate) {
      dateFilter = `startDate=${dayjs(startDate).format('YYYY-MM-DD')}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`
    }

    let params = '';    
    if (user && (user.role === ROLE_PRIMARY_USER || user.role === ROLE_ADMIN)) {
        params = `domainOnly=true&${dateFilter}`;
    } else {
        params = dateFilter;
    }

    const response = await redaxios.get(`${API}/referrals/report?${params}`, {
        headers,
        responseType: 'blob'
    });
    return response;
}

export async function usersReport(_, headers) {
    const response = await redaxios.get(`${API}/users/report`, {
        headers,
        responseType: 'blob'
    });
    return response;
}

export async function ceilingPricesReport({ providerCode }, headers) {
    const response = await redaxios.get(`${API}/ceiling-prices/report`, {
        headers,
        params: {providerCode},
        responseType: 'blob'
    });
    return response;
}

export async function supplierSearch({ supplierSearch }, headers) {
    const response = await redaxios.get(`${API}/providers`, {
        headers,
        params: { string: supplierSearch }
    });
    return response.data;
}

export async function userSearch({ userSearch }, headers) {
    const response = await redaxios.get(`${API}/users?string=${userSearch}`, {
        headers,
    });
    return response.data;
}

export async function getUser({ id }, headers) {
    const response = await redaxios.get(`${API}/users/${id}`, {
        headers,
    });
    return response.data;
}

export async function deactivateUser({ id }, headers) {
    const response = await redaxios.put(`${API}/users/${id}/deactivate`, {}, {
        headers,
    });
    return response.data;
}

export async function activateUser({ id }, headers) {
    const response = await redaxios.put(`${API}/users/${id}/activate`, {}, {
        headers: headers,
    });
    return response.data;
}

export async function unlockUser({ id }, headers) {
    const response = await redaxios.put(`${API}/users/${id}/unlock`, {}, {
        headers: headers,
    });
    return response.data;
}

export async function updateUserRole({ id, targetRole }, headers) {
    const response = await redaxios.put(`${API}/users/${id}/role`, { targetRole }, {
        headers: headers,
    });
    return response.data;
}