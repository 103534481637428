import redaxios from "redaxios";

const API = `${process.env.REACT_APP_API_URL}/api/orams`;

export async function regions(_, headers) {
    const response = await redaxios.get(`${API}/services/regions`, {
        headers,
    });
    return response.data;
}

export async function services(_, headers) {
    const response = await redaxios.get(`${API}/services`, {
        headers,
    });
    return response.data;
}

export async function suppliers({ region, service, subService }, headers) {
    const response = await redaxios.get(`${API}/services/${service}/service-sub-types/${subService}/regions/${region}`, {
        headers,
    });
    return response.data;
}
