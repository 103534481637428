import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import * as referralApi from "../api/referrals";
import { Alert, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import useUser from "../hooks/useUser";

export default function AssignReferralPage() {
    const { error, setSuccess, call } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const { userTypeNavLinks } = useUser();
    const dashboardUrl = userTypeNavLinks.filter((link) => link.key === 'dashboard')[0].url;
    const [ users, setUsers ] = useState([]);
    const [ assignedTo, setAssignedTo ] = useState([]);

    useEffect(() => {
        call(referralApi.agencyUsers, { id }, ({ assignedToId, users }) => {
            setUsers(users);
            setAssignedTo(assignedToId);
        });
    },
    // eslint-disable-next-line
    [ id ]);

    const handleAssignUser = (id, userId) => {
        setSuccess(undefined);
        call(referralApi.assign, { id, userId }, (response) => {
            setSuccess(`Referral (ID: ${id}) successfully reassigned.`);
            navigate(dashboardUrl);
        });
    };

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            {error && <Alert severity="error">{error}</Alert>}
            {(!users || users.length === 0) &&
                <Alert severity="info">There are no users found for your agency</Alert>
            }
            {users && users.length > 0 &&
                <React.Fragment>
                    <Typography variant="h2">{`Reassign referral (ID: ${id})`}</Typography>
                    <Typography component="p" variant="body2" sx={{ mt: 2, mb: 2 }}>{`Find the RCM you wish to assign this referral to in the list below, then simply click on the "Assign" button`}</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {users.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    <Typography component="p" variant="body2">{row.name}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="p" variant="body2">{row.email}</Typography>
                                </TableCell>
                                <TableCell>
                                    {row.id === assignedTo
                                        ? (<Typography component="p" variant="body2">Assigned</Typography>)
                                        : (
                                            <Button
                                                onClick={() => {
                                                    handleAssignUser(id, row.id);
                                                }}
                                            >
                                                Assign
                                            </Button>
                                        )
                                    }
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            }
        </Box>

    );
}
