import React from "react";
import useAuth from "../hooks/useAuth";
import { Navigate } from "react-router-dom";

export default function LogoutPage() {
    const { user, logout } = useAuth();

    if (user && user.token) {
        logout();
    }

    return (
        <Navigate to="/login" />
    );
};